import React from 'react'
import { IonImg } from '@ionic/react';
import IntelikartLogo from '../../images/Logo-vertical.png';

const LogoImage = () => {
    return (
        <IonImg src={IntelikartLogo}/>
    )
}

export default LogoImage
