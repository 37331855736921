import React from "react";
import { IonToolbar, IonFooter } from "@ionic/react";

const LoginFooter = () => {
    return (
        <IonFooter class=" ion-text-center">
            <IonToolbar>
                <h5
                    style={{
                        fontSize: 14 + "px",
                        paddingLeft: 10 + "%",
                        paddingRight: 10 + "%",
                    }}
                >
                    By using this application, you agree to our{" "}
                    <a href="https://www.intelikart.com/privacy-policy.html">
                        Terms of service
                    </a>{" "}
                    and{" "}
                    <a href="https://www.intelikart.com/privacy-policy.html">
                        Privacy policy
                    </a>
                </h5>
            </IonToolbar>
        </IonFooter>
    );
};

export default LoginFooter;
