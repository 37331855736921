import {
    GET_TOKENS_LOGIN_REQUEST,
    GET_TOKENS_LOGIN_SUCCESS,
    GET_TOKENS_LOGIN_FAIL,
    LOAD_CURRENT_USER_REQUEST,
    LOAD_CURRENT_USER_SUCCESS,
    LOAD_CURRENT_USER_FAIL,
    LOAD_BUSINESS_TYPE_LIST_REQUEST,
    LOAD_BUSINESS_TYPE_LIST_SUCCESS,
    LOAD_BUSINESS_TYPE_LIST_FAIL,
    USER_ONBOARDING_REQUEST,
    USER_ONBOARDING_SUCCESS,
    USER_ONBOARDING_FAIL,
    CLEAR_ERRORS,
    GET_EMAIL_OTP_FAIL,
    GET_EMAIL_OTP_REQUEST,
    GET_EMAIL_OTP_SUCCESS,
    USER_LOGGEDIN_AND_AUTHENTICATE,
    USER_LOGGED_OUT,
} from "../constants/authConstants";
import { API_ROOT } from "../constants/global";

// const API_ROOT = 'https://api.intelikart.com/';

//Load user tokens by google login
export const getUserTokens =
    (userCredentials, scope?: string) => async (dispatch: any) => {
        try {
            dispatch({ type: GET_TOKENS_LOGIN_REQUEST });
            const response = await fetch(`${API_ROOT}oauth/access-token`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(userCredentials),
            });
            const tokens = await response.json();

            if (response.status != 200) {
                dispatch({
                    type: GET_TOKENS_LOGIN_FAIL,
                    payload: tokens,
                });
            } else {
                dispatch({
                    type: GET_TOKENS_LOGIN_SUCCESS,
                    payload: tokens,
                });
            }
        } catch (error) {
            dispatch({
                type: GET_TOKENS_LOGIN_FAIL,
                payload: error,
            });
        }
    };

export const getEmailOTP = (email) => async (dispatch: any) => {
    try {
        dispatch({ type: GET_EMAIL_OTP_REQUEST });
        const response = await fetch(
            `${API_ROOT}oauth/email-login-otp?email=${email}&scope=store`
        );
        const tokens = await response.json();
        dispatch({
            type: GET_EMAIL_OTP_SUCCESS,
            payload: tokens,
        });
    } catch (error) {
        dispatch({
            type: GET_EMAIL_OTP_FAIL,
            payload: error,
        });
    }
};
//Load current user
export const getCurrentUser =
    (accessToken: string) => async (dispatch: any) => {
        // try {
        //     dispatch({ type: LOAD_CURRENT_USER_REQUEST });
        //     const response = await fetch(`${API_ROOT}api/users/current`, {
        //         method: "GET",
        //         headers: {
        //             authorization: `${accessToken}`,
        //         },
        //     });
        //     const data = await response.json();
        //     if (response.status == 200) {
        //         dispatch({
        //             type: LOAD_CURRENT_USER_SUCCESS,
        //             payload: data,
        //         });
        //     } else {
        //         dispatch({
        //             type: LOAD_CURRENT_USER_FAIL,
        //             payload: data,
        //         });
        //     }
        // } catch (error) {
        //     dispatch({
        //         type: LOAD_CURRENT_USER_FAIL,
        //         payload: error,
        //     });
        // }
    };

//Load business type list
export const getBusinessTypeList = () => async (dispatch: any) => {
    try {
        dispatch({ type: LOAD_BUSINESS_TYPE_LIST_REQUEST });
        const response = await fetch(`${API_ROOT}api/store-types`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                authorization: "list",
            },
        });
        const list = await response.json();
        dispatch({
            type: LOAD_BUSINESS_TYPE_LIST_SUCCESS,
            payload: list,
        });
    } catch (error) {
        dispatch({
            type: LOAD_BUSINESS_TYPE_LIST_FAIL,
            payload: error,
        });
    }
};

//User onboarding
export const onboardingUser =
    (accessToken: string, userOnboardingData: any) =>
    async (dispatch: any) => {
        try {
            dispatch({ type: USER_ONBOARDING_REQUEST });
            const response = await fetch(
                `${API_ROOT}profile/partner-onboarding`,
                {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${accessToken}`,
                    },
                    body: JSON.stringify(userOnboardingData),
                }
            );
            const message = await response.json();
            dispatch({
                type: USER_ONBOARDING_SUCCESS,
                payload: message,
            });
        } catch (error) {
            dispatch({
                type: USER_ONBOARDING_FAIL,
                payload: error,
            });
        }
    };

//User Logged in Authenticated
export const authenticateUserWhenLogin = () => async (dispatch: any) => {
    dispatch({ type: USER_LOGGEDIN_AND_AUTHENTICATE });
};

//User Loogged out Authenticated
export const authenticateUserWhenLogout = () => async (dispatch: any) => {
    dispatch({ type: USER_LOGGED_OUT });
};

//clear Errors
export const clearErrors = () => async (dispatch: any) => {
    dispatch({ type: CLEAR_ERRORS });
};
