import React from "react";
import {
    IonTabs,
    IonRouterOutlet,
    IonTabBar,
    IonTabButton,
    IonIcon,
    IonLabel,
} from "@ionic/react";
import { Route, Redirect } from "react-router";
import {
    calendar,
    location,
    informationCircle,
    people,
    home,
    homeSharp,
    appsSharp,
    settingsSharp,
    cashSharp,
    storefrontOutline,
} from "ionicons/icons";
import Dashboard from "./dashboard/Dashboard";
import IKAcademy from "./dashboard/IKAcademy/IKAcademy";
import Subscriptions from "./dashboard/subscriptions/Subscriptions";
import Customer from "./dashboard/Customer/Customer";
import Coupons from "./dashboard/coupons/Coupons";
import CreateCouponPage from "./dashboard/coupons/CreateCouponPage";
import Banners from "./dashboard/banners/Banners";
import Settings from "./dashboard/settings/Settings";
import BasicInfo from "./dashboard/settings/BasicInfo";
import Localization from "./dashboard/settings/Localization";
import ServiceDetails from "./dashboard/settings/ServiceDetails";
import Payments from "./dashboard/settings/Payments";
import QuickOrders from "./dashboard/settings/QuickOrders";
import Taxes from "./dashboard/settings/taxes/Taxes";
import Orders from "./dashboard/orders/Orders";
import Marketing from "./dashboard/marketing/Marketing";
import CreateStaffPage from "./dashboard/staff/CreateStaffPage";
import Staffs from "./dashboard/staff/Staff";
import CreateTaxPage from "./dashboard/settings/taxes/CreateTaxes";
import Home from "./auth/Home";
import GetBusinessDetails from "./auth/GetBusinessDetails";
import SignUpWithEmailOTP from "./auth/SignUpWithEmailOTP";
import Catalogue from "./pages/Catalogue";
import UpdateCategoryPage from "./pages/UpdateCategory";
import AddCategoryPage from "./pages/AddCategory";
import OrderDetails from "./dashboard/orders/OrderDetails";
import ProtectedRoute from "./route/ProtectedRoute";
import AddProduct from "./pages/AddProduct";
import AddSubCategoryPage from "./pages/AddSubCategory";
import UpdateSubCategoryPage from "./pages/UpdateSubCategory";
import UpdateProductMain from "./pages/UpdateProductMain";
import ProductsHomeScreen from "./components/products/ProductsHomeScreen";
import HelpAndSupport from "./dashboard/HelpAndSupport/HelpAndSupport";
import Invoice from "./pages/Invoice";
import AddBanner from "./dashboard/banners/AddBanner";
import PageListing from "./dashboard/page/PageListing";
import CreatePage from "./dashboard/page/CreatePage";
import UpdatePage from "./dashboard/page/UpdatePage";
import Stores from "./components/stores/Stores";
import BankAccountDetails from "./dashboard/settings/BankAccountDetails";
import MyStoreDetails from "./components/stores/MyStoreDetails";
import Support from "./dashboard/HelpAndSupport/Support";
import Features from "./components/dashboard/Features";
import Leads from "./dashboard/leads/Leads";
import CreateLeadsPage from "./dashboard/leads/CreateLeadsPage";
import LandingPages from "./dashboard/landingpages/LandingPages";
import Reports from "./dashboard/reports/Reports";

interface MainTabsProps {}

const MainTabs: React.FC<MainTabsProps> = () => {
    return (
        <IonTabs>
            <IonRouterOutlet>
                {/*
          Using the render method prop cuts down the number of renders your components will have due to route changes.
          Use the component prop when your component depends on the RouterComponentProps passed in automatically.
        */}
                <ProtectedRoute
                    path="/my/userdashboard"
                    component={Dashboard}
                    exact={true}
                />
                <ProtectedRoute
                    path="/my/ikacademy"
                    component={IKAcademy}
                    exact={true}
                />
                <ProtectedRoute 
                    path ="/my/features"
                    component={Features}
                    exact={true}
                />
                <ProtectedRoute
                    path="/my/subscriptions"
                    component={Subscriptions}
                    exact={true}
                />
                <ProtectedRoute
                    path="/my/customer"
                    component={Customer}
                    exact={true}
                />
                <ProtectedRoute
                    path="/my/coupons"
                    component={Coupons}
                    exact={true}
                />
                <ProtectedRoute
                    path="/my/createcoupon"
                    component={CreateCouponPage}
                    exact={true}
                />
                <ProtectedRoute
                    path="/my/createcoupon/:id"
                    component={CreateCouponPage}
                    exact={true}
                />
                <ProtectedRoute
                    path="/my/banners"
                    component={Banners}
                    exact={true}
                />
                <ProtectedRoute
                    path="/my/settings"
                    component={Settings}
                    exact={true}
                />
                <ProtectedRoute
                    path="/my/settings/basicinfo"
                    component={BasicInfo}
                    exact={true}
                />
                <ProtectedRoute
                    path="/my/settings/localization"
                    component={Localization}
                    exact={true}
                />
                <ProtectedRoute
                    path="/my/settings/servicedetails"
                    component={ServiceDetails}
                    exact={true}
                />
                <ProtectedRoute
                    path="/my/settings/payments"
                    component={Payments}
                    exact={true}
                />
                <ProtectedRoute
                    path="/my/settings/quickorders"
                    component={QuickOrders}
                    exact={true}
                />
                <ProtectedRoute
                    path="/my/settings/taxes"
                    component={Taxes}
                    exact={true}
                />
                <ProtectedRoute
                    path="/my/settings/createtax"
                    component={CreateTaxPage}
                    exact={true}
                />
                <ProtectedRoute
                    path="/my/staffs"
                    component={Staffs}
                    exact={true}
                />
                <ProtectedRoute
                    path="/my/createstaff"
                    component={CreateStaffPage}
                    exact={true}
                />
                <ProtectedRoute
                    path="/my/orders"
                    component={Orders}
                    exact={true}
                />
                <ProtectedRoute
                    path="/my/catalogue"
                    component={Catalogue}
                    exact={true}
                />
                <ProtectedRoute
                    path="/my/reports"
                    component={Reports}
                    exact={true}
                />
                <Route exact path="/my/catalogue/category/add/:fp">
                    <AddCategoryPage />
                </Route>
                <Route exact path="/my/catalogue/subcategory/add/:id/:name/:fp">
                    <AddSubCategoryPage />
                </Route>
                <Route exact path="/my/catalogue/category/update/:id">
                    {" "}
                    <UpdateCategoryPage />
                </Route>
                <Route exact path="/my/catalogue/subcategory/update/:id">
                    {" "}
                    <UpdateSubCategoryPage />
                </Route>
                <ProtectedRoute
                    path="/my/marketing"
                    component={Marketing}
                    exact={true}
                />
                <ProtectedRoute
                    path="/my/products"
                    component={ProductsHomeScreen}
                    exact={true}
                />
                <ProtectedRoute
                    path="/my/orders/orderdetails"
                    component={OrderDetails}
                    exact={true}
                />
                <ProtectedRoute
                    path="/my/orders/orderdetails/invoice/:id"
                    component={Invoice}
                    exact={true}
                />
                <ProtectedRoute
                    path="/my/products/update/:id"
                    component={UpdateProductMain}
                    exact={true}
                />
                <ProtectedRoute
                    path="/my/products/create/:fp"
                    component={AddProduct}
                    exact={true}
                />
                <ProtectedRoute
                    path="/my/help&support"
                    component={HelpAndSupport}
                    exact={true}
                />
                <ProtectedRoute
                    path="/my/support"
                    component={Support}
                    exact={true}
                />
                <ProtectedRoute
                    path="/my/addbanner"
                    component={AddBanner}
                    exact={true}
                />
                <ProtectedRoute
                    path="/my/pages"
                    component={PageListing}
                    exact={true}
                />
                <ProtectedRoute
                    path="/my/pages/create"
                    component={CreatePage}
                    exact={true}
                />
                <ProtectedRoute
                    path="/my/pages/update/:id"
                    component={UpdatePage}
                    exact={true}
                />

                {/* added */}
                <ProtectedRoute
                    path="/my/stores"
                    component={Stores}
                    exact={true}
                />
                <ProtectedRoute
                    path="/my/stores/storedetails/:id"
                    component={MyStoreDetails}
                    exact={true}
                />
                <ProtectedRoute
                    path="/my/settings/bankdetails"
                    component={BankAccountDetails}
                    exact={true}
                />
                <ProtectedRoute
                    path="/my/leads"
                    component={Leads}
                    exact={true}
                />
                <ProtectedRoute
                    path="/my/createleads"
                    component={CreateLeadsPage}
                    exact={true}
                />
                <ProtectedRoute
                    path="/my/createleads/:id"
                    component={CreateLeadsPage}
                    exact={true}
                />
                <ProtectedRoute
                    path="/my/landingpages"
                    component={LandingPages}
                    exact={true}
                />
                {/* end added */}
            </IonRouterOutlet>
            <IonTabBar slot="bottom">
                <IonTabButton tab="schedule" href="/my/userdashboard">
                    <IonIcon icon={homeSharp} />
                    <IonLabel>Home</IonLabel>
                </IonTabButton>
                <IonTabButton tab="stores" href="/my/stores">
                    <IonIcon icon={storefrontOutline} />
                    <IonLabel>Stores</IonLabel>
                </IonTabButton>
            </IonTabBar>
        </IonTabs>
    );
};

export default MainTabs;
