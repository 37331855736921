import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
    IonContent,
    IonPage,
    IonText,
    IonButton,
    IonImg,
    IonLoading,
    IonIcon,
    IonGrid,
    IonRow,
    IonCol,
    IonCard,
    IonCardHeader,
    IonCardContent,
    IonHeader,
    IonCardTitle,
    IonCardSubtitle,
} from "@ionic/react";
import LogoImage from "../components/auth/LogoImage";
import LaptopImage from "../images/eco.png";
import Step1Image from "../images/step1.png";
import Step2Image from "../images/step2.png";
import Step3Image from "../images/step3.png";
import GoogleLogo from "../images/auth/GoogleLogo.png";
import FacebookLogo from "../images/auth/FacebookLogo.png";
import OTPEmail from "../images/auth/OTPEmail.png";
import LoginFooter from "../components/auth/LoginFooter";
import { GoogleAuth } from "@codetrix-studio/capacitor-google-auth";
// import analyticsImg from "../images/dashboard_analytics.PNG"
//import { FacebookLogin } from '@rdlabo/capacitor-facebook-login';
import {
    getUserTokens,
    getCurrentUser,
    clearErrors,
    authenticateUserWhenLogin,
    authenticateUserWhenLogout,
} from "../actions/authActions";
import {
    getAccessToken,
    setTokensObject,
} from "../services/auth/userLocalStorage";
import { BrowserView, isDesktop, MobileView } from "react-device-detect";
import {
    ellipsisHorizontal,
    ellipsisVertical,
    logoGoogle,
    mail,
    arrowForward,
    caretForward,
    accessibilityOutline,
    addCircle,
    shareSocial,
    cash,
    refreshCircle,
    thumbsUp,
    duplicate
} from "ionicons/icons";

const Home: React.FC = () => {
    const [doReload, setDoReload] = useState(false);
    const history = useHistory();
    const dispatch = useDispatch();
    const { userAPITokensLoading, userAPITokens, loadUserAPITokensError } =
        useSelector((state: any) => state.userAPITokens);
    const { currentUserLoading, currentUser, currentUserError } = useSelector(
        (state: any) => state.currentUser
    );
    const { isUserAuthenticate } = useSelector(
        (state: any) => state.userAuthenticate
    );

    useEffect(() => {
        sessionStorage.setItem("reloadCount", String(1));
        GoogleAuth.init();
    }, []);

    useEffect(() => {
        if (userAPITokensLoading === false) {
            if (userAPITokens) {
                setTokensObject(userAPITokens);
                loadCurrentUser(userAPITokens.access_token);

                let token = getAccessToken();
                // redirect to getBussinessDetails if kyc is pending 
                if (token?.profile.partner_kyc === "COMPLETED") {
                    history.push("/my/userdashboard");
                } else {
                    history.push("/getbusinessdetails");
                }
            }
        }
    }, [userAPITokens]);

    useEffect(() => {
        if (currentUserLoading === false && getAccessToken()) {
            if (currentUser && currentUser.data) {
                dispatch(authenticateUserWhenLogin());
                let token = getAccessToken();
                // redirect to getBussinessDetails if kyc is pending 
                if (token?.profile.partner_kyc === "COMPLETED") {
                    history.push("/my/userdashboard");
                } else {
                    history.push("/getbusinessdetails");
                }
            }
        }
    }, [currentUser]);

    const loadCurrentUser = async (token: string) => {
        await dispatch(getCurrentUser(token));
    };

    useEffect(() => {
        if (isUserAuthenticate === false) {
            setDoReload(true);
        }
    }, [isUserAuthenticate]);

    useEffect(() => {
        if (doReload) {
            window.location.reload();
        }
    }, [doReload]);

    const signInWithGoogle = async () => {
        const userFromGoogle = await GoogleAuth.signIn();
        if (userFromGoogle) {
            const data = {
                "username": userFromGoogle.email,
                "password": userFromGoogle.authentication.accessToken,
                "scope": "partners",
                "thirdparty": "google"
            }
            await dispatch(getUserTokens(data));

        }
    };


    const signInWithFacebook = async () => {
        // const FACEBOOK_PERMISSIONS = ['public_profile', 'email'];
        // FacebookLogin.login({ permissions: FACEBOOK_PERMISSIONS }).then((user)=>{
        //     console.log(user);
        // })
    };
    let classOfLogin = "";
    let classOfLoginButton = "loginButtonMobile ion-padding";
    let classOfLogo = "iklogologin";
    if (isDesktop) {
        classOfLogin = classOfLogin + " dtLogin";
        classOfLoginButton = "loginButtonDt";
        classOfLogo = "iklogologindt"
    }


    return (
        // <IonPage className={classOfLogin}>
        //     {/* <BrowserView> */}
        //     <IonContent className=" ion-text-center ">
        //         <IonHeader className={classOfOuterContainer}>
        //             <IonGrid>
        //                 <IonRow>
        //                     <IonCol>
        //                         <div className={classOfLogo}>
        //                             <LogoImage />
        //                         </div>
        //                     </IonCol>
        //                 </IonRow>
        //                 <IonRow className="ion-padding">
        //                     <IonCol>
        //                         <IonText style={{ fontSize: "50px", fontWeight: "bold", color: "white" }}>
        //                             Intelikart Partner Portal
        //                         </IonText>
        //                     </IonCol>
        //                 </IonRow>
        //                 <IonRow>
        //                     <IonCol size="1.5" />
        //                     <IonCol>
        //                         <IonText style={{ fontSize: "20px", color: "white" }}>
        //                             Our platform offers set of tools & technologies that enable new-age entrepreneurs, small businesses, and MSMEs to operate
        //                             their eCommerce business seamlessly. With our pay-as-you-go plans, we offer solid support for marketing your business and
        //                             24x7 tech support.
        //                         </IonText>
        //                     </IonCol>
        //                     <IonCol size="1.5" />
        //                 </IonRow>
        //                 <IonRow className="ion-padding">
        //                     <IonCol>
        //                         <IonButton
        //                             className={classOfLoginButton}
        //                             shape="round"
        //                             color="danger"
        //                             onClick={() => signInWithGoogle()}
        //                             style={{ width: "250px" }}
        //                         >
        //                             <IonIcon
        //                                 className="iconlogin"
        //                                 slot="start"
        //                                 icon={logoGoogle}
        //                             />
        //                             <IonText>
        //                                 Continue with Google
        //                             </IonText>
        //                             {/* <IonImg src={GoogleLogo}/> */}
        //                             {/* <IonText color="dark">Continue with Google</IonText> */}
        //                         </IonButton>
        //                     </IonCol>
        //                 </IonRow>
        //             </IonGrid>
        //         </IonHeader>
        //         <IonGrid>
        //             <br /><br /><br /><br />
        //             <IonRow className="ion-text-center" >
        //                 <IonCol size="2.5" />
        //                 <IonCol className = {classOfLaptopScreen} >
        //                     <IonImg src={LaptopImage} />
        //                 </IonCol>
        //             </IonRow>
        //             <br /><br /><br /><br /><br />
        //             {/* <IonRow className="ion-padding">
        //                 <IonCol>
        //                     <IonText style={{ fontSize: "30px", fontWeight: "550" }}>
        //                         How to Join ?
        //                     </IonText>
        //                 </IonCol>
        //             </IonRow>
        //             <br /><br /><br />
        //             <IonRow>
        //                 <IonCol size="6">
        //                     <IonImg src={Step1Image} className="steps-img"/>
        //                 </IonCol>
        //                 <IonCol size="4" className="ion-text-left steps-text">
        //                     <IonText style={{ fontSize: "20px", color: "blue" }}>
        //                         STEP 1
        //                     </IonText>
        //                     <br /><br />
        //                     <IonText style={{ fontSize: "25px", fontWeight: "bold" }}>
        //                         Name your store
        //                     </IonText>
        //                     <br /><br />
        //                     <IonText style={{ color: "grey" }}>
        //                         It all starts with a name. Make it memorable. Make it catchy. This is the brand you'll be building.
        //                     </IonText>
        //                 </IonCol>
        //             </IonRow>
        //             <br /><br /><br /><br />
        //             <IonRow>
        //                 <IonCol size="6">
        //                     <IonImg src={Step2Image} className="steps-img"/>
        //                 </IonCol>
        //                 <IonCol size="4" className="ion-text-left steps-text">
        //                     <IonText style={{ fontSize: "20px", color: "orange" }}>
        //                         STEP 2
        //                     </IonText>
        //                     <br /><br />
        //                     <IonText style={{ fontSize: "25px", fontWeight: "bold" }}>
        //                         Add your products
        //                     </IonText>
        //                     <br /><br />
        //                     <IonText style={{ color: "grey" }}>
        //                         You have the product. You have the store. Add your products, set the price and get ready for lift-off!
        //                     </IonText>
        //                 </IonCol>
        //             </IonRow>
        //             <br /><br /><br /><br />
        //             <IonRow>
        //                 <IonCol size="6">
        //                     <IonImg src={Step3Image} className="steps-img" />
        //                 </IonCol>
        //                 <IonCol size="4" className="ion-text-left steps-text">
        //                     <IonText style={{ fontSize: "20px", color: "green" }}>
        //                         STEP 3
        //                     </IonText>
        //                     <br /><br />
        //                     <IonText style={{ fontSize: "25px", fontWeight: "bold" }}>
        //                         Start selling
        //                     </IonText>
        //                     <br /><br />
        //                     <IonText style={{ color: "grey" }}>
        //                         This is what it all comes down to. Your eCommerce business is ready to accept orders! Are you ?
        //                     </IonText>
        //                 </IonCol>
        //             </IonRow>
        //             <br /><br /><br /><br /><br /><br /> */}
        //             <IonRow>
        //                 <IonCol size="2" />
        //                 <IonCol>
        //                     <IonText style={{ fontSize: "40px", fontWeight: "600" }}>
        //                         Why you should join us?
        //                     </IonText>
        //                     <br /><br /><br />
        //                 </IonCol>
        //                 <IonCol size="2" />
        //             </IonRow>
        //             <IonRow>
        //                 <IonCol size="2" />
        //                 <IonCol>
        //                     <IonText className="ion-text-left">
        //                         <h6><IonIcon icon={caretForward} /> Individuals joining our program can grow their earnings easily and hassle-free. </h6>
        //                         <h6><IonIcon icon={caretForward} /> The entire partnership is set up with quick KYC. </h6>
        //                         <h6><IonIcon icon={caretForward} /> Once joined, partners can earn up to 30%+ commissions. </h6>
        //                         <h6><IonIcon icon={caretForward} /> Continuous upselling and cross-selling will lead to more generation of commissions. </h6>
        //                         <h6><IonIcon icon={caretForward} /> Simplified customer training and onboarding process is a quick-step solution to a growing career. </h6>
        //                     </IonText>
        //                 </IonCol>
        //                 <IonCol size="2" />
        //             </IonRow>
        //         </IonGrid>
        //         <IonLoading
        //             isOpen={
        //                 userAPITokensLoading === true ||
        //                 currentUserLoading === true
        //             }
        //             message={"Please Wait..."}
        //         />
        //     </IonContent>
        //     {/* </BrowserView> */}
        //     {/* <MobileView>
        //         <IonContent>
        //             <IonHeader className="outer-container">
        //                 <IonGrid>
        //                     <IonRow>
        //                         <IonCol>
        //                             <div>
        //                                 <LogoImage />
        //                             </div>
        //                         </IonCol>
        //                     </IonRow>
        //                 </IonGrid>
        //             </IonHeader>
        //         </IonContent>
        //     </MobileView> */}
        //     <LoginFooter />
        // </IonPage>
        <IonPage>
            <IonContent>
                <IonGrid className="ion-no-padding">
                    <IonRow className="ion-no-padding">
                        <IonCol>
                            <IonCard className="ion-no-padding">
                                <IonCardContent className="outerContainerdt">
                                    <IonGrid>
                                        <IonRow>
                                            <IonCol>
                                                <div className={classOfLogo}>
                                                    <LogoImage />
                                                </div>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow className="ion-padding ion-text-center">
                                            <IonCol>
                                                <IonText style={{ fontSize: "50px", fontWeight: "bold", color: "white" }}>
                                                    Partner. Serve. Grow.
                                                </IonText>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow className="ion-text-center">
                                            <IonCol>
                                                <IonText style={{ fontSize: "20px", color: "white" }}>
                                                    Let's join hands to grow together by serving digital economies. <br /> As a partner, sell our products to your customers and earn recurring revenues.
                                                    <br /><br />
                                                    Become a Partner & be Future Ready
                                                </IonText>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow >

                                            <IonButton
                                                className={classOfLoginButton}
                                                shape="round"
                                                color="danger"
                                                onClick={() => signInWithGoogle()}
                                                style={{ width: "250px" }}
                                            >
                                                <IonIcon
                                                    className="iconlogin"
                                                    slot="start"
                                                    icon={logoGoogle}
                                                />
                                                <IonText>
                                                    Continue with Google
                                                </IonText>
                                            </IonButton>
                                        </IonRow>

                                    </IonGrid>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-no-padding">
                        <IonCol>
                            <IonCard className="ion-no-padding">
                                <IonCardContent>
                                    <IonGrid>
                                        <IonRow className="ion-text-center" >
                                            <IonCol className="displayLaptopdt" >
                                                <IonImg className="ion-text-center" src={LaptopImage} />
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-text-center" >
                        <IonCol><IonCol>
                            <IonCard>
                                <IonCardContent className="outerContainerdt">
                                    <IonText style={{ fontSize: "30px", fontWeight: "bold", color: "white" }}>
                                        Reasons to join hands
                                    </IonText>
                                </IonCardContent>
                            </IonCard>
                        </IonCol></IonCol>
                    </IonRow>
                    <IonRow className="ion-text-center" >

                        <IonCol>
                            <IonCard >
                                <IonCardHeader>
                                    <IonIcon icon={addCircle} size='large' />
                                    <IonCardTitle>Easy Joining & Training Process</IonCardTitle>
                                    <IonCardSubtitle></IonCardSubtitle>
                                </IonCardHeader>
                            </IonCard>
                        </IonCol>

                        <IonCol>
                            <IonCard>
                                <IonCardHeader>
                                    <IonIcon icon={shareSocial} size='large' />
                                    <IonCardTitle>Leads Sharing for Outreach</IonCardTitle>
                                    <IonCardSubtitle></IonCardSubtitle>
                                </IonCardHeader>
                            </IonCard>
                        </IonCol>

                    </IonRow>
                    <IonRow className="ion-text-center" >

                        <IonCol>
                            <IonCard>
                                <IonCardHeader>
                                    <IonIcon icon={refreshCircle} size='large' />
                                    <IonCardTitle>Lifetime Recurring Revenues</IonCardTitle>
                                    <IonCardSubtitle></IonCardSubtitle>
                                </IonCardHeader>
                            </IonCard>
                        </IonCol>

                        <IonCol>
                            <IonCard>
                                <IonCardHeader>
                                    <IonIcon icon={cash} size='large' />
                                    <IonCardTitle>Share up to 30% of Revenues</IonCardTitle>
                                    <IonCardSubtitle></IonCardSubtitle>
                                </IonCardHeader>
                            </IonCard>
                        </IonCol>

                    </IonRow>

                    <IonRow className="ion-text-center" >

                        <IonCol>
                            <IonCard>
                                <IonCardHeader>
                                    <IonIcon icon={thumbsUp} size='large' />
                                    <IonCardTitle>Awesome Support & Growth</IonCardTitle>
                                    <IonCardSubtitle></IonCardSubtitle>
                                </IonCardHeader>
                            </IonCard>
                        </IonCol>

                        <IonCol>
                            <IonCard>
                                <IonCardHeader>
                                    <IonIcon icon={duplicate} size='large' />
                                    <IonCardTitle>Cross Sell your Services</IonCardTitle>
                                    <IonCardSubtitle></IonCardSubtitle>
                                </IonCardHeader>
                            </IonCard>
                        </IonCol>

                    </IonRow>

                    <IonRow >
                        <IonCol>
                            <IonCard>
                                <IonCardContent>
                                    <IonText>
                                        Copyrights reserved by Intelikart. Terms of Services applicable.
                                    </IonText>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};

export default Home;

