import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import {composeWithDevTools} from 'redux-devtools-extension';
import { loadCategoriesReducer } from './reducers/categoryReducer';

import { 
    loadUserTokensReducer, 
    loadCurrentUserReducer, 
    loadBusinessTypeListReducer, 
    userOnboardingReducer,
    userAuthenticateReducer
} from './reducers/authReducers';
import {
    loadTipBannersReducer, 
    loadHomeScreenReducer,
    loadHomeScreenWithDaysReducer,
    loadAcademyContentReducer,
    loadCustomersReducer,
    deleteCouponReducer,
    createCouponReducer,
    loadCouponsReducer,
    loadBannersReducer,
    createBannerReducer,
    deleteBannerReducer,
    setBasicInfoReducer,
    getBasicInfoReducer,
    getServiceDetailReducer,
    setServiceDetailReducer,
    getQuickOrderReducer,
    setQuickOrderReducer,
    getPaymentReducer,
    setPaymentReducer,
    uploadImageReducer,
    setLocalizationReducer,
    getLocalizationReducer,
    loadTaxesReducer,
    createTaxReducer,
    deleteTaxReducer,
    loadStaffsReducer,
    createStaffReducer,
    deleteStaffReducer,
    loadOrdersReducer,
    loadInvoiceReducer,
    changeOrderStatusReducer,
    changeOrderStaffReducer,
    setAdjustmentReducer,
    loadAdjustmentsReducer,
    editAdjustmentReducer,
    deleteAdjustmentReducer,
    loadPrintablesReducer,
    loadAnnouncementsReducer,
    createAnnouncementReducer,
    loadOrderByIdReducer,
    loadProductsBySubcategoryReducer,
    loadLandingPagesReducer,
    setLandingPagesReducer,
    loadSubdomainAvailabilityReducer,
    loadReportsClickReducer,
    createClaimStoreReducer
} from './reducers/dashboardReducers';
import { createProductReducer, deleteProductReducer, editProductReducer, loadProductByIdReducer, searchProductQueryReducer } from './reducers/productReducer';
import { checkURLAvailabilityReducer, createPageReducer, deletePageReducer, loadPageByIdReducer, loadPagesReducer, updatePageReducer } from './reducers/pageReducer';

const reducer = combineReducers({
    userAPITokens: loadUserTokensReducer,
    currentUser: loadCurrentUserReducer,
    userAuthenticate: userAuthenticateReducer,
    businessTypeList: loadBusinessTypeListReducer,
    userOnboarding: userOnboardingReducer,
    categories:loadCategoriesReducer,
    tipBanners: loadTipBannersReducer,
    homeScreenData: loadHomeScreenReducer,
    homeScreenDataWithDays: loadHomeScreenWithDaysReducer,
    academyContent: loadAcademyContentReducer,
    customers: loadCustomersReducer,
    deleteCouponData: deleteCouponReducer,
    createCouponData: createCouponReducer,
    coupons: loadCouponsReducer,
    banners: loadBannersReducer,
    addBannerData: createBannerReducer,
    deleteBannerData: deleteBannerReducer,
    setBasicInfo: setBasicInfoReducer,
    basicInfo: getBasicInfoReducer,
    serviceDetail: getServiceDetailReducer,
    setServiceDetail: setServiceDetailReducer,
    quickOrder: getQuickOrderReducer,
    setQuickOrder: setQuickOrderReducer,
    payment: getPaymentReducer,
    setPayment: setPaymentReducer,
    setLocalization: setLocalizationReducer,
    localization: getLocalizationReducer,
    taxes: loadTaxesReducer,
    createTaxData: createTaxReducer,
    deleteTaxData: deleteTaxReducer,
    uploadImageResponse: uploadImageReducer,
    staffs: loadStaffsReducer,
    createStaffData: createStaffReducer,
    deleteStaffData: deleteStaffReducer,
    orders: loadOrdersReducer,
    invoice: loadInvoiceReducer,
    changeOrderStatusData: changeOrderStatusReducer,
    changeOrderStaffData: changeOrderStaffReducer,
    adjustments: loadAdjustmentsReducer,
    setAdjustmentData: setAdjustmentReducer,
    editAdjustmentData: editAdjustmentReducer,
    deleteAdjustmentData: deleteAdjustmentReducer,
    printables: loadPrintablesReducer,
    loadAnnouncementsReducer: loadAnnouncementsReducer,
    createAnnouncementReducer: createAnnouncementReducer,
    orderById: loadOrderByIdReducer,
    productListBySubcategory: loadProductsBySubcategoryReducer,
    deleteProductData: deleteProductReducer,
    productById: loadProductByIdReducer,
    createProducData: createProductReducer,
    editProductData: editProductReducer,
    searchQueryProductData: searchProductQueryReducer,
    pages: loadPagesReducer,
    PageById: loadPageByIdReducer,
    createPageData: createPageReducer,
    checkURLAvailability: checkURLAvailabilityReducer,
    editPageData:updatePageReducer,
    deletePageData: deletePageReducer,
    landingPages: loadLandingPagesReducer,
    setLandingPages: setLandingPagesReducer,
    subdomainAvailability: loadSubdomainAvailabilityReducer,
    reportsClick : loadReportsClickReducer,
    storeClaim: createClaimStoreReducer

  
})

let initialState = {};

const middleware = [thunk];
const store = createStore(reducer, initialState, composeWithDevTools(applyMiddleware(...middleware)));

export default store;
