import { IonButton, IonCard, IonCardContent, IonCol, IonContent, IonDatetime, IonDatetimeButton, IonGrid, IonHeader, IonItem, IonLabel, IonList, IonLoading, IonModal, IonPage, IonRow, IonSegment, IonSegmentButton, IonSelect, IonSelectOption, IonText, IonToolbar } from "@ionic/react";
import React, { useEffect, useState } from "react";
import Header from "../../components/dashboard/Header";
import { Bar } from "react-chartjs-2";
import Chart from 'chart.js/auto';
import { CategoryScale } from 'chart.js';
import { getAccessToken } from '../../services/auth/userLocalStorage';
import { getReportsClick } from '../../actions/dashboardActions';
import { useDispatch, useSelector } from "react-redux";

const Reports = () => {
    const [content, setContent] = useState<string | undefined>("Clicks");
    const [startDate, setStartDate] = useState<string>();
    const [endDate, setEndDate] = useState<string>();
    const dispatch = useDispatch();
    const { reportsClickLoading, reportsClick, reportsClickError } = useSelector((state: any) => state.reportsClick);
    const [loading, setLoading] = useState<undefined | boolean>(false);
    const { currentUser } = useSelector((state: any) => state.currentUser);
    const [totalClicks, setTotalClicks] = useState();
    const [dailyAverageClicks, setDailyAverageClick] = useState();
    const [barData, setBarData] = useState<any>();
    const [isDateValid, setIsDateValid] = useState(true);
    const [isDiff90, setIsDiff90] = useState(true);
    Chart.register(CategoryScale);

    if (startDate === undefined || endDate === undefined) {
        var today = new Date();
        var sDay = String(today.getDate()).padStart(2, '0');
        var sMonth = String(today.getMonth() + 1).padStart(2, '0');
        var sYear = today.getFullYear();

        var sDate = sYear + '-' + sMonth + '-' + sDay;
        setEndDate(sDate);

        var last = new Date(today.getTime() - (30 * 24 * 60 * 60 * 1000));
        var eDay = last.getDate();
        var eMonth = last.getMonth() + 1;
        var eYear = last.getFullYear();

        var eDate = eYear + '-' + eMonth + '-' +eDay ;
        setStartDate(eDate);
    }

    useEffect(() => {
        let splitedStart = startDate.split("-");
        let splitedEnd = endDate.split("-");
        let formatedStartDate = splitedStart[1] + "-" + splitedStart[2] + "-" + splitedStart[0];
        let formatedEndDate = splitedEnd[1] + "-" + splitedEnd[2] + "-" + splitedEnd[0];
        dispatch(getReportsClick(getAccessToken().token, formatedStartDate, formatedEndDate));
    }, [currentUser]);

    useEffect(() => {
        if (reportsClickLoading === false && reportsClick.data) {
            setTotalClicks(reportsClick.data.totalClicks);
            setDailyAverageClick(reportsClick.data.dailyAvgClicks);
            setBarData({
                labels: reportsClick.data.clicksData.map(o => o.date),
                datasets: [
                    {
                        label: 'Clicks',
                        backgroundColor: 'rgba(135, 206, 235, 0.5)',
                        borderColor: 'rgb(0, 0, 0)',
                        borderWidth: 1,
                        data: reportsClick.data.clicksData.map(o => o.count)
                    }
                ]
            })
        }
    }, [reportsClick]);

    const endDateHandler = async (e: any) => {
        const today = new Date(e);
        var day = String(today.getDate()).padStart(2, '0');
        var month = String(today.getMonth() + 1).padStart(2, '0');
        var year = today.getFullYear();
        var date = year + '-' + month + '-' +day ;
        setEndDate(date);
    }

    const startDateHandler = async (e: any) => {
        const today = new Date(e);
        var day = String(today.getDate()).padStart(2, '0');
        var month = String(today.getMonth() + 1).padStart(2, '0');
        var year = today.getFullYear();
        var date = year + '-' + month + '-' + day;
        setStartDate(date);
    }

    const dateValidation = async () => {
        let result = true;
        const date1 = new Date(startDate);
        const date2 = new Date(endDate);

        if (date1 > date2) {
            result = false;
            setIsDateValid(false);
        }
        else {
            setIsDateValid(true);
            const diffTime = Math.abs(date2.valueOf() - date1.valueOf());
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
            if (diffDays > 90) {
                result = false
                setIsDiff90(false);
            }
            else {
                setIsDiff90(true);
            }
        }
        return result;
    }

    const clickHandler = async () => {
        const res = await dateValidation();
        setLoading(true);
        if (res) {
            dispatch(getReportsClick(getAccessToken().token, startDate, endDate));
        }
        setLoading(false);
    }

    const filterHandler = async (e: any) => {
        const selection = e.detail.value;
        var today = new Date();

        if (selection === "Today") {
            var sDay = String(today.getDate()).padStart(2, '0');
            var sMonth = String(today.getMonth() + 1).padStart(2, '0');
            var sYear = today.getFullYear();

            var sDate = sYear + '-' + sMonth + '-' +sDay ;
            setEndDate(sDate);

            var last = new Date(today.getTime() - (7 * 24 * 60 * 60 * 1000));
            var eDay = last.getDate();
            var eMonth = last.getMonth() + 1;
            var eYear = last.getFullYear();

            var eDate = eYear + '-' + eMonth + '-' +eDay ;
            setStartDate(sDate);
        }
        else if (selection === "Yesterday") {
            var sDay = String(today.getDate()).padStart(2, '0');
            var sMonth = String(today.getMonth() + 1).padStart(2, '0');
            var sYear = today.getFullYear();

            var sDate = sYear + '-' + sMonth + '-' +sDay ;
            setEndDate(sDate);

            var last = new Date(today.getTime() - (1 * 24 * 60 * 60 * 1000));
            var eDay = last.getDate();
            var eMonth = last.getMonth() + 1;
            var eYear = last.getFullYear();

            var eDate = eYear + '-' + eMonth + '-' +eDay ;
            setStartDate(eDate);
        }
        else if (selection === "Week") {
            var sDay = String(today.getDate()).padStart(2, '0');
            var sMonth = String(today.getMonth() + 1).padStart(2, '0');
            var sYear = today.getFullYear();

            var sDate = sYear + '-' + sMonth + '-' +sDay ;
            setEndDate(sDate);

            var last = new Date(today.getTime() - (7 * 24 * 60 * 60 * 1000));
            var eDay = last.getDate();
            var eMonth = last.getMonth() + 1;
            var eYear = last.getFullYear();

            var eDate = eYear + '-' + eMonth + '-' +eDay ;
            setStartDate(eDate);
        }
        else if (selection === "Month") {
            var sDay = String(today.getDate()).padStart(2, '0');
            var sMonth = String(today.getMonth() + 1).padStart(2, '0');
            var sYear = today.getFullYear();

            var sDate = sYear + '-' + sMonth + '-' +sDay ;
            setEndDate(sDate);

            var last = new Date(today.getTime() - (30 * 24 * 60 * 60 * 1000));
            var eDay = last.getDate();
            var eMonth = last.getMonth() + 1;
            var eYear = last.getFullYear();

            var eDate = eYear + '-' + eMonth + '-' +eDay ;
            setStartDate(eDate);
        }
        else if (selection === "Last 2 Month") {
            var sDay = String(today.getDate()).padStart(2, '0');
            var sMonth = String(today.getMonth() + 1).padStart(2, '0');
            var sYear = today.getFullYear();

            var sDate = sYear + '-' + sMonth + '-' +sDay ;
            setEndDate(sDate);

            var last = new Date(today.getTime() - (60 * 24 * 60 * 60 * 1000));
            var eDay = last.getDate();
            var eMonth = last.getMonth() + 1;
            var eYear = last.getFullYear();

            var eDate = eYear + '-' + eMonth + '-' +eDay ;
            setStartDate(eDate);
        }
        else if (selection === "Last 3 Months") {
            var sDay = String(today.getDate()).padStart(2, '0');
            var sMonth = String(today.getMonth() + 1).padStart(2, '0');
            var sYear = today.getFullYear();

            var sDate = sYear + '-' + sMonth + '-' +sDay ;
            setEndDate(sDate);

            var last = new Date(today.getTime() - (90 * 24 * 60 * 60 * 1000));
            var eDay = last.getDate();
            var eMonth = last.getMonth() + 1;
            var eYear = last.getFullYear();

            var eDate = eYear + '-' + eMonth + '-' +eDay ;
            setStartDate(eDate);
        }
    }
    return (
        <IonPage>
            <Header name="" />
            <IonContent>
                <IonSegment value={content}>
                    <IonSegmentButton value="Clicks">
                        <IonItem lines="none" style={{ marginBottom: 2 + 'px' }}>
                            <IonText className="ion-margin-horizontal">Clicks Count</IonText>
                        </IonItem>
                    </IonSegmentButton>
                </IonSegment>
                <IonGrid>
                    <IonCard>
                        <IonCardContent>
                            <IonRow class="ion-align-items-center">
                                <IonCol sizeMd="1.5" sizeSm="6">
                                    <IonLabel>
                                        <IonText style={{ fontSize: "20px" }}>
                                            Start Date
                                        </IonText>
                                    </IonLabel>
                                </IonCol>
                                <IonCol sizeMd="3" sizeSm="6">
                                <IonDatetimeButton datetime="datetime"></IonDatetimeButton>
                                <IonModal keepContentsMounted={true}>
                                    <IonItem>
                                        <IonDatetime id="datetime" presentation="date" value={startDate} onIonChange={(e) => { startDateHandler(e.detail.value) }} />
                                    </IonItem>
                                </IonModal>    
                                </IonCol>
                                <IonCol sizeMd="1.5" sizeSm="6">
                                    <IonLabel>
                                        <IonText style={{ fontSize: "20px" }}>
                                            End Date
                                        </IonText>
                                    </IonLabel>
                                </IonCol>
                                <IonCol sizeMd="3" sizeSm="6">
                                <IonDatetimeButton datetime="datetime1"></IonDatetimeButton>
                                <IonModal keepContentsMounted={true}>
                                    <IonItem>
                                        <IonDatetime id="datetime1" showDefaultButtons={true} value={endDate} presentation="date" preferWheel={false}  onIonChange={(e) => { endDateHandler(e.detail.value) }} />
                                    </IonItem>
                                    </IonModal>
                                </IonCol>

                                <IonCol sizeMd="2" sizeSm="6">
                                    <IonList>
                                        <IonItem>
                                            <IonSelect interface="popover" placeholder="Filters" onIonChange={(e) => { filterHandler(e) }}>
                                                <IonSelectOption>Today</IonSelectOption>
                                                <IonSelectOption>Yesterday</IonSelectOption>
                                                <IonSelectOption>Week</IonSelectOption>
                                                <IonSelectOption>Month</IonSelectOption>
                                                <IonSelectOption>Last 2 Month</IonSelectOption>
                                                <IonSelectOption>Last 3 Months</IonSelectOption>
                                            </IonSelect>
                                        </IonItem>
                                    </IonList>
                                </IonCol>

                                <IonCol sizeMd="1" sizeSm="6">
                                    <IonButton fill="outline" color="dark" onClick={clickHandler}>
                                        OK
                                    </IonButton>
                                </IonCol>
                                <IonText color="danger" className={`${isDateValid === true && isDiff90 === true ? "ion-hide" : ""}`}>
                            <h5 style={{ fontSize: 12 + 'px', marginTop: 0 + 'px' }} className={`ion-margin-horizontal ${isDateValid ? "ion-hide" : ""}`}>Selected Dates are Not Valid</h5>
                            <h5 style={{ fontSize: 12 + 'px', marginTop: 0 + 'px' }} className={`ion-margin-horizontal ${isDiff90 ? "ion-hide" : ""}`}>Range is Greater Than 30 Days.</h5>
                        </IonText>
                            </IonRow>
                        </IonCardContent>
                    </IonCard>

                    <IonRow className="ion-text-center">
                        <IonCol>
                            <IonCard>
                                <IonItem>
                                    <IonText>
                                        Total Clicks
                                    </IonText>
                                </IonItem>
                                <IonItem>
                                    <IonText>
                                        {totalClicks}
                                    </IonText>
                                </IonItem>
                            </IonCard>
                        </IonCol>
                        <IonCol>
                            <IonCard>
                                <IonItem>
                                    <IonText>
                                        Daily Average Clicks
                                    </IonText>
                                </IonItem>
                                <IonItem>
                                    <IonText>
                                        {dailyAverageClicks}
                                    </IonText>
                                </IonItem>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    <div className="canvas-container">
                        {barData ? <Bar
                            data={barData}
                            options={{
                                plugins: {
                                    title: {
                                        display: true
                                    }
                                },
                                responsive: true
                            }}
                            height={100}
                            width={400}
                        /> : null}
                    </div>
                </IonGrid>
                <IonLoading isOpen={reportsClickLoading === true || loading === true} message="Please Wait"></IonLoading>
            </IonContent>
        </IonPage>
    )
}

export default Reports;