import React, {
    useState,
    useEffect,
    useMemo,
    useCallback,
    useRef,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    IonContent,
    IonApp,
    IonSegment,
    IonSegmentButton,
    IonLabel,
    IonText,
    IonCard,
    IonIcon,
    IonItem,
    IonImg,
    IonInput,
    IonSlides,
    IonSlide,
    IonButton,
    IonLoading,
    IonToast,
    IonPage,
    IonRow,
    IonCardContent,
    IonCol,
    IonGrid,
    IonCardHeader,
    IonChip,
} from "@ionic/react";
import DashboardMenu from "../../components/dashboard/DashboardMenu";
import {
    getPrintables,
    getAnnouncements,
    createAnnouncements,
} from "../../actions/dashboardActions";
import { getAccessToken } from "../../services/auth/userLocalStorage";
import Header from "../../components/dashboard/Header";
import { Clipboard } from "@capacitor/clipboard";
import {
    print as printIcon,
    megaphone as megaphoneIcon,
    shareSocial as shareSocialIcon,
    cloudDownload as cloudDownloadIcon,
    copyOutline,
    logoGooglePlaystore
} from "ionicons/icons";
import { Share } from "@capacitor/share";
import { authenticateUserWhenLogout } from "../../actions/authActions";
import { useHistory } from "react-router";
import { MobileView, BrowserView } from "react-device-detect";
import { ShareButtonMobile } from "../../components/ShareButtonMobile";
import { ShareButtonWeb } from "../../components/ShareButtonWeb";
import VisitingCardImage from "../../images/visiting-card-food.jpg";
import { API_ROOT } from "../../constants/global";
import { Icon } from "ionicons/dist/types/components/icon/icon";


const Marketing = (props) => {
    const [content, setContent] = useState<string | undefined>("All");
    const [downloadingLoader, setDownloadingLoader] = useState(false);
    const [announcement, setAnnouncement] = useState<string | undefined>("");
    const dispatch = useDispatch();
    const history = useHistory();
    const [showToast, setShowToast] = useState(false);

    const { printablesLoading, printables, printablesError } = useSelector(
        (state: any) => state.printables
    );
    const { announcementsLoading, announcements, announcementsError } =
        useSelector((state: any) => state.loadAnnouncementsReducer);
    const {
        createAnnouncementLoading,
        createAnnouncement,
        createAnnouncementError,
    } = useSelector((state: any) => state.createAnnouncementReducer);
    const { currentUser } = useSelector((state: any) => state.currentUser);

    // useEffect(() => {
    //     if(props && props.location && props.location.state){
    //         setContent("ANNOUNCEMENT");
    //     }else{
    //         setContent("PRINTABLES");
    //     }
    // }, [props.location.state])

    // useEffect(() => {
    //     if (currentUser && currentUser.data) {
    //         dispatch(
    //             getPrintables(getAccessToken(), currentUser.data[0].store_id)
    //         );
    //         // dispatch(getAnnouncements(getAccessToken(), currentUser.data[0].store_id));
    //     }
    // }, [currentUser]);

    const [partnerCard, setPartnerCard] = useState<string>();
    const [partnerCardBack, setPartnerCardBack] = useState<string>();
    const [A4Banner, setA4Banner] = useState<string>();

    const message = `
    Mobile and Electronic Stores
    https://play.google.com/store/apps/details?id=com.intelikart.naganathmobile
    https://naganathmobile.intelikart.com/
    
    Grocery 
    https://play.google.com/store/apps/details?id=com.intelikart.groceryappforyou
    https://groceryappforyou.intelikart.com/
    
    Food and Restaurant
    https://play.google.com/store/apps/details?id=com.intelikart.sampleappintelikart
    https://intelikart.biz/
    
    Medical 
    https://dilipmedicalstore.com/
    https://play.google.com/store/apps/details?id=com.intelikart.dilipmedicalstore`;

    useEffect(() => {
        const getCard = async () => {
            setDownloadingLoader(true);
            let accessToken = getAccessToken()
            const res = await fetch(`${API_ROOT}partners/visiting-card-img`,
                {
                    method: "GET",
                    headers: {
                        authorization: `Bearer ${accessToken.token}`,
                    }
                }
            )

            const res2 = await fetch(`${API_ROOT}partners/visiting-card-back-img`,
                {
                    method: "GET",
                    headers: {
                        authorization: `Bearer ${accessToken.token}`,
                    }
                }
            )

            const res3 = await fetch(`${API_ROOT}partners/banner-img`,
                {
                    method: "GET",
                    headers: {
                        authorization: `Bearer ${accessToken.token}`,
                    }
                }
            )

            const src = await res.text();
            const src2 = await res2.text();
            const src3 = await res3.text();
            setPartnerCard(src);
            setPartnerCardBack(src2);
            setA4Banner(src3);
            setDownloadingLoader(false);
        }
        getCard()
    }, [])

    // const slideOpts = {
    //     initialSlide: 0,
    //     slidesPerView: 1,
    //     loop: true,
    //     speed: 20
    // };

    // const sendAnnouncement = async()=>{
    //     if(currentUser && currentUser.data){
    //         if(getAccessToken()){
    //             await dispatch(createAnnouncements(getAccessToken(), {
    //                 store_id: currentUser.data[0].store_id,
    //                 text: `${announcement}`
    //             }));
    //         }else{
    //             await dispatch(authenticateUserWhenLogout())
    //             await localStorage.clear();
    //             history.push('/')
    //         }
    //     }

    // }

    const sharePrintables = async (value: any) => {

        await Share.share({
            title: "Intelikart Printables",
            text: ``,
        });
    };

    const copyToClipboard = async (data: string) => {
        await Clipboard.write({
            string: `${data}`,
        });
    };

    // const downloadImage = async (value: any) => {
    //     console.log("run");
    //     const response = await fetch(value.image_link);
    //     const blob = await response.blob();
    //     const base64Data = await convertBlobToBase64(blob) as string;
    //     await Filesystem.writeFile({
    //       path: 'text.jpeg',
    //       data: base64Data,
    //       directory: Directory.Data
    //     });
    //   };

    //   const convertBlobToBase64 = (blob: Blob) => new Promise((resolve, reject) => {
    //     const reader = new FileReader;
    //     reader.onerror = reject;
    //     reader.onload = () => {
    //       resolve(reader.result);
    //     };
    //    reader.readAsDataURL(blob);
    //   });

    // var UrlImageDownloader = async (filename) => {
    //     let accessToken = getAccessToken()
    //     setDownloadingLoader(true);

    //     await fetch(`${API_ROOT}partners/visiting-card-pdf`, {
    //         method: "GET",
    //         headers: {
    //             "Content-Type": "application/pdf",
    //             authorization: `Bearer ${accessToken.token}`,
    //         }
    //     })
    //         .then(function (response) {
    //             response.arrayBuffer().then(function (buffer) {
    //                 let url = window.URL.createObjectURL(new Blob([buffer]));
    //                 let link = document.createElement("a");
    //                 link.href = url;
    //                 link.setAttribute(
    //                     "download",
    //                     filename
    //                 );
    //                 document.body.appendChild(link);
    //                 link.click();
    //             });
    //         })
    //         .catch(function (error) {
    //             console.error(error.message);
    //         });
    //     setDownloadingLoader(false);
    // };

    var UrlImageDownloader = async (filename) => {
        setDownloadingLoader(true);
        var a = document.createElement("a");

        if (filename === 'visiting-card-front.jpeg') {
            a.href = partnerCard;
        }
        else if (filename === 'visiting-card-back.jpeg') {
            a.href = partnerCardBack;
        }
        else {
            a.href = A4Banner;
        }


        a.download = filename;
        a.click();
        setDownloadingLoader(false);
    };

    // const mySlides = useRef<HTMLIonSlidesElement>(null);

    // function slidesDidLoad() {
    //     if(mySlides.current){
    //         mySlides.current.startAutoplay();
    //     }
    // }

    return (
        <IonPage>
            <div className="ion-page" id="main-content">
                <Header name="" />
                <IonContent className="ion-padding">
                    {/* <IonSegment onIonChange={e => setContent(e.detail.value)} value={content}>
                <IonSegmentButton value="PRINTABLES">
                  <IonItem lines="none" style={{marginBottom: 2+'px'}}>
                      <IonIcon size="large" icon={printIcon}></IonIcon>
                      <IonText className="ion-margin-horizontal">Printables</IonText>
                  </IonItem>
                </IonSegmentButton>
                <IonSegmentButton value="ANNOUNCEMENT">
                <IonItem lines="none" style={{marginBottom: 2+'px'}}>
                      <IonIcon size="large" icon={megaphoneIcon}></IonIcon>
                      <IonText className="ion-margin-horizontal">Announcement</IonText>
                  </IonItem>
                </IonSegmentButton>
              </IonSegment> */}

                    {/* {console.log(printables)} */}

                    {/* {printablesLoading === false &&
                    printables &&
                    printables.data ? ( */}


                    <IonChip color = {content === 'All' ? "dark" : "light"} onClick={() => setContent("All")}>
                        <IonLabel >All</IonLabel>
                    </IonChip>
                    <IonChip color = {content === 'VisitingCard' ? "dark" : "light"} onClick={() => setContent("VisitingCard")}>
                        <IonLabel>Visiting Card</IonLabel>
                    </IonChip>
                    <IonChip color = {content === 'Banners' ? "dark" : "light"} onClick={() => setContent("Banners")}>
                        <IonLabel>Banners</IonLabel>
                    </IonChip>
                    <IonChip color = {content === 'Text' ? "dark" : "light"} onClick={() => setContent("Text")}>
                        <IonLabel>Text</IonLabel>
                    </IonChip>

                    {content === 'All' ?
                        <IonGrid>
                            <IonRow>
                                <IonCol size="12" sizeSm="6">
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem
                                                style={{
                                                    paddingTop: 16 + "px",
                                                }}
                                            >
                                                <IonImg
                                                    style={{
                                                        left: 0,
                                                        right: 0,
                                                        margin: "auto",
                                                    }}
                                                    src={partnerCard}
                                                ></IonImg>
                                            </IonItem>
                                            <IonItem lines="none">
                                                <MobileView>
                                                    <IonIcon
                                                        size="large"
                                                        slot="start"
                                                        icon={shareSocialIcon}
                                                        onClick={(e) =>
                                                            sharePrintables(
                                                                VisitingCardImage
                                                            )
                                                        }
                                                    ></IonIcon>
                                                </MobileView>
                                                <div className="ion-text-center">
                                                    <h6>Visiting Card</h6>
                                                </div>
                                                <IonIcon
                                                    slot="end"
                                                    size="large"
                                                    icon={cloudDownloadIcon}
                                                    onClick={(e) =>
                                                        UrlImageDownloader('visiting-card-front.jpeg')
                                                    }
                                                ></IonIcon>
                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                                <IonCol size="12" sizeSm="6">
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem
                                                style={{
                                                    paddingTop: 16 + "px",
                                                }}
                                            >
                                                <IonImg
                                                    style={{
                                                        left: 0,
                                                        right: 0,
                                                        margin: "auto",
                                                    }}
                                                    src={partnerCardBack}
                                                ></IonImg>
                                            </IonItem>
                                            <IonItem lines="none">
                                                <MobileView>
                                                    <IonIcon
                                                        size="large"
                                                        slot="start"
                                                        icon={shareSocialIcon}
                                                        onClick={(e) =>
                                                            sharePrintables(
                                                                VisitingCardImage
                                                            )
                                                        }
                                                    ></IonIcon>
                                                </MobileView>
                                                <div className="ion-text-center">
                                                    <h6>Visiting Card Back</h6>
                                                </div>
                                                <IonIcon
                                                    slot="end"
                                                    size="large"
                                                    icon={cloudDownloadIcon}
                                                    onClick={(e) =>
                                                        UrlImageDownloader('visiting-card-Back.jpeg')
                                                    }
                                                ></IonIcon>
                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                                {/* <IonCol size="12" sizeSm="6">
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem
                                                style={{
                                                    paddingTop: 16 + "px",
                                                }}
                                            >
                                                <IonImg
                                                    style={{
                                                        left: 0,
                                                        right: 0,
                                                        margin: "auto",
                                                    }}
                                                    src={VisitingCardImage}
                                                ></IonImg>
                                            </IonItem>
                                            <IonItem lines="none">
                                                <MobileView>
                                                    <IonIcon
                                                        size="large"
                                                        slot="start"
                                                        icon={shareSocialIcon}
                                                        onClick={(e) =>
                                                            sharePrintables(
                                                                VisitingCardImage
                                                            )
                                                        }
                                                    ></IonIcon>
                                                </MobileView>
                                                <div className="ion-text-center">
                                                    <h6>Visiting Card</h6>
                                                </div>
                                                <IonIcon
                                                    slot="end"
                                                    size="large"
                                                    icon={cloudDownloadIcon}
                                                    onClick={(e) =>
                                                        UrlImageDownloader('visiting-card.pdf')
                                                    }
                                                ></IonIcon>
                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol> */}
                            </IonRow>
                            <IonRow>
                                <IonCol size="12" sizeSm="6">
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem
                                                style={{
                                                    paddingTop: 16 + "px",
                                                }}
                                            >
                                                <IonImg
                                                    src={A4Banner}
                                                ></IonImg>
                                            </IonItem>
                                            <IonItem lines="none">
                                                <MobileView>
                                                    <IonIcon
                                                        size="large"
                                                        slot="start"
                                                        icon={shareSocialIcon}
                                                        onClick={(e) =>
                                                            sharePrintables(
                                                                A4Banner
                                                            )
                                                        }
                                                    ></IonIcon>
                                                </MobileView>
                                                <div className="ion-text-center">
                                                    <h6>A4 Banner</h6>
                                                </div>
                                                <IonIcon
                                                    slot="end"
                                                    size="large"
                                                    icon={cloudDownloadIcon}
                                                    onClick={(e) =>
                                                        UrlImageDownloader('A4Banner.jpeg')
                                                    }
                                                ></IonIcon>
                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                                <IonCol sizeMd="6" sizeSm="12">
                                    <IonCard>
                                        <IonCardHeader>
                                            <IonRow>
                                                <IonCol>
                                                    <IonText style={{ fontSize: "25px" }}>
                                                        Sample Website & Apps
                                                    </IonText>
                                                </IonCol>
                                                <IonCol size="1.5">
                                                    <IonButton
                                                        fill="clear"
                                                        color="secondary"
                                                        onClick={(e) => {
                                                            copyToClipboard(`${message}`);
                                                            setShowToast(true);
                                                        }}
                                                    >
                                                        <IonIcon
                                                            size="large"
                                                            slot="icon-only"
                                                            icon={copyOutline}
                                                        ></IonIcon>
                                                    </IonButton>
                                                </IonCol>
                                                <IonCol size="1.5">
                                                    <MobileView>
                                                        <ShareButtonMobile
                                                            title="Intelikart Business Website"
                                                            text={`${message}`}
                                                        />
                                                    </MobileView>
                                                    <BrowserView>
                                                        <ShareButtonWeb
                                                            text={`${message}`}
                                                        />
                                                    </BrowserView>
                                                </IonCol>
                                            </IonRow>
                                        </IonCardHeader>
                                        <IonCardContent>
                                            <IonGrid>
                                                <IonRow>
                                                    <IonCol>
                                                        <IonText style={{ fontSize: "22px" }}>
                                                            Mobile & Electronic Stores
                                                        </IonText>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow>
                                                    <IonCol>
                                                        <IonText className="websitelink dashboard-fnt-sz">
                                                            <a
                                                                href={`https://play.google.com/store/apps/details?id=com.intelikart.naganathmobile`}
                                                                target="_blank"
                                                            >
                                                                {`https://play.google.com/store/apps/details?id=com.intelikart.naganathmobile`}
                                                            </a>
                                                        </IonText>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow>
                                                    <IonCol>
                                                        <IonText className="websitelink dashboard-fnt-sz">
                                                            <a
                                                                href={`https://naganathmobile.intelikart.com/`}
                                                                target="_blank"
                                                            >
                                                                {`https://naganathmobile.intelikart.com/`}
                                                            </a>
                                                        </IonText>
                                                    </IonCol>
                                                </IonRow>
                                                <br />
                                                <IonRow>
                                                    <IonCol>
                                                        <IonText style={{ fontSize: "22px" }}>
                                                            Grocery
                                                        </IonText>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow>
                                                    <IonCol>
                                                        <IonText className="websitelink dashboard-fnt-sz">
                                                            <a
                                                                href={`https://play.google.com/store/apps/details?id=com.intelikart.groceryappforyou`}
                                                                target="_blank"
                                                            >
                                                                {`https://play.google.com/store/apps/details?id=com.intelikart.groceryappforyou`}
                                                            </a>
                                                        </IonText>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow>
                                                    <IonCol>
                                                        <IonText className="websitelink dashboard-fnt-sz">
                                                            <a
                                                                href={`https://groceryappforyou.intelikart.com/`}
                                                                target="_blank"
                                                            >
                                                                {`https://groceryappforyou.intelikart.com/`}
                                                            </a>
                                                        </IonText>
                                                    </IonCol>
                                                </IonRow>
                                                <br />
                                                <IonRow>
                                                    <IonCol>
                                                        <IonText style={{ fontSize: "22px" }}>
                                                            Food & Restaurant
                                                        </IonText>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow>
                                                    <IonCol>
                                                        <IonText className="websitelink dashboard-fnt-sz">
                                                            <a
                                                                href={`https://play.google.com/store/apps/details?id=com.intelikart.sampleappintelikart`}
                                                                target="_blank"
                                                            >
                                                                {`https://play.google.com/store/apps/details?id=com.intelikart.sampleappintelikart`}
                                                            </a>
                                                        </IonText>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow>
                                                    <IonCol>
                                                        <IonText className="websitelink dashboard-fnt-sz">
                                                            <a
                                                                href={`https://intelikart.biz/`}
                                                                target="_blank"
                                                            >
                                                                {`https://intelikart.biz/`}
                                                            </a>
                                                        </IonText>
                                                    </IonCol>
                                                </IonRow>
                                                <br />
                                                <IonRow>
                                                    <IonCol>
                                                        <IonText style={{ fontSize: "22px" }}>
                                                            Medical
                                                        </IonText>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow>
                                                    <IonCol>
                                                        <IonText className="websitelink dashboard-fnt-sz">
                                                            <a
                                                                href={`https://play.google.com/store/apps/details?id=com.intelikart.dilipmedicalstore`}
                                                                target="_blank"
                                                            >
                                                                {`https://play.google.com/store/apps/details?id=com.intelikart.dilipmedicalstore`}
                                                            </a>
                                                        </IonText>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow>
                                                    <IonCol>
                                                        <IonText className="websitelink dashboard-fnt-sz">
                                                            <a
                                                                href={`https://dilipmedicalstore.com/`}
                                                                target="_blank"
                                                            >
                                                                {`https://dilipmedicalstore.com/`}
                                                            </a>
                                                        </IonText>
                                                    </IonCol>
                                                </IonRow>
                                            </IonGrid>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                                {/* <IonCol size="12" sizeSm="6">
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem
                                                style={{
                                                    paddingTop: 16 + "px",
                                                }}
                                            >
                                                <IonImg
                                                    src={A4BannerImage}
                                                ></IonImg>
                                            </IonItem>
                                            <IonItem lines="none">
                                                <MobileView>
                                                    <IonIcon
                                                        size="large"
                                                        slot="start"
                                                        icon={shareSocialIcon}
                                                        onClick={(e) =>
                                                            sharePrintables(
                                                                A4BannerImage
                                                            )
                                                        }
                                                    ></IonIcon>
                                                </MobileView>
                                                <div className="ion-text-center">
                                                    <h6>Half of A4 Banner</h6>
                                                </div>
                                                <IonIcon
                                                    slot="end"
                                                    size="large"
                                                    icon={cloudDownloadIcon}
                                                    onClick={(e) =>
                                                        UrlImageDownloader('visiting-card.pdf')
                                                    }
                                                ></IonIcon>
                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol> */}
                            </IonRow>
                            <IonRow>
                                {/* {printables.data.map((elem) => {
                                    return (
                                        <IonCol
                                            key={elem.type}
                                            size="12"
                                            sizeSm="6"
                                        >
                                            <IonCard>
                                                <IonCardContent className="ion-no-padding">
                                                    <IonItem
                                                        style={{
                                                            paddingTop:
                                                                16 + "px",
                                                        }}
                                                    >
                                                        <IonImg
                                                            src={
                                                                elem.image_link
                                                            }
                                                        ></IonImg>
                                                    </IonItem>
                                                    <IonItem lines="none">
                                                        <MobileView>
                                                            <IonIcon
                                                                size="large"
                                                                slot="start"
                                                                icon={
                                                                    shareSocialIcon
                                                                }
                                                                onClick={(e) =>
                                                                    sharePrintables(
                                                                        elem
                                                                    )
                                                                }
                                                            ></IonIcon>
                                                        </MobileView>
                                                        <div className="ion-text-center">
                                                            <h6>{elem.type}</h6>
                                                        </div>
                                                        <IonIcon
                                                            slot="end"
                                                            size="large"
                                                            icon={
                                                                cloudDownloadIcon
                                                            }
                                                            onClick={(e) =>
                                                                UrlImageDownloader(
                                                                    elem.image_link
                                                                )
                                                            }
                                                        ></IonIcon>
                                                    </IonItem>
                                                </IonCardContent>
                                            </IonCard>
                                        </IonCol>
                                    );
                                })} */}
                            </IonRow>
                        </IonGrid> : null}

                    {content === 'VisitingCard' ?
                        <IonGrid>
                            <IonRow>
                                <IonCol size="12" sizeSm="6">
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem
                                                style={{
                                                    paddingTop: 16 + "px",
                                                }}
                                            >
                                                <IonImg
                                                    style={{
                                                        left: 0,
                                                        right: 0,
                                                        margin: "auto",
                                                    }}
                                                    src={partnerCard}
                                                ></IonImg>
                                            </IonItem>
                                            <IonItem lines="none">
                                                <MobileView>
                                                    <IonIcon
                                                        size="large"
                                                        slot="start"
                                                        icon={shareSocialIcon}
                                                        onClick={(e) =>
                                                            sharePrintables(
                                                                VisitingCardImage
                                                            )
                                                        }
                                                    ></IonIcon>
                                                </MobileView>
                                                <div className="ion-text-center">
                                                    <h6>Visiting Card</h6>
                                                </div>
                                                <IonIcon
                                                    slot="end"
                                                    size="large"
                                                    icon={cloudDownloadIcon}
                                                    onClick={(e) =>
                                                        UrlImageDownloader('visiting-card-front.jpeg')
                                                    }
                                                ></IonIcon>
                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                                <IonCol size="12" sizeSm="6">
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem
                                                style={{
                                                    paddingTop: 16 + "px",
                                                }}
                                            >
                                                <IonImg
                                                    style={{
                                                        left: 0,
                                                        right: 0,
                                                        margin: "auto",
                                                    }}
                                                    src={partnerCardBack}
                                                ></IonImg>
                                            </IonItem>
                                            <IonItem lines="none">
                                                <MobileView>
                                                    <IonIcon
                                                        size="large"
                                                        slot="start"
                                                        icon={shareSocialIcon}
                                                        onClick={(e) =>
                                                            sharePrintables(
                                                                VisitingCardImage
                                                            )
                                                        }
                                                    ></IonIcon>
                                                </MobileView>
                                                <div className="ion-text-center">
                                                    <h6>Visiting Card Back</h6>
                                                </div>
                                                <IonIcon
                                                    slot="end"
                                                    size="large"
                                                    icon={cloudDownloadIcon}
                                                    onClick={(e) =>
                                                        UrlImageDownloader('visiting-card-Back.jpeg')
                                                    }
                                                ></IonIcon>
                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                        </IonGrid> : null}


                    {content === 'Banners' ? <IonRow>
                        <IonCol size="12" sizeSm="6">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem
                                        style={{
                                            paddingTop: 16 + "px",
                                        }}
                                    >
                                        <IonImg
                                            src={A4Banner}
                                        ></IonImg>
                                    </IonItem>
                                    <IonItem lines="none">
                                        <MobileView>
                                            <IonIcon
                                                size="large"
                                                slot="start"
                                                icon={shareSocialIcon}
                                                onClick={(e) =>
                                                    sharePrintables(
                                                        A4Banner
                                                    )
                                                }
                                            ></IonIcon>
                                        </MobileView>
                                        <div className="ion-text-center">
                                            <h6>A4 Banner</h6>
                                        </div>
                                        <IonIcon
                                            slot="end"
                                            size="large"
                                            icon={cloudDownloadIcon}
                                            onClick={(e) =>
                                                UrlImageDownloader('A4Banner.jpeg')
                                            }
                                        ></IonIcon>
                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow> : null}

                    {content === 'Text' ? <IonRow>
                        <IonCol sizeMd="6" sizeSm="12">
                            <IonCard>
                                <IonCardHeader>
                                    <IonRow>
                                        <IonCol>
                                            <IonText style={{ fontSize: "25px" }}>
                                                Sample Website & Apps
                                            </IonText>
                                        </IonCol>
                                        <IonCol size="1.5">
                                            <IonButton
                                                fill="clear"
                                                color="secondary"
                                                onClick={(e) => {
                                                    copyToClipboard(`${message}`);
                                                    setShowToast(true);
                                                }}
                                            >
                                                <IonIcon
                                                    size="large"
                                                    slot="icon-only"
                                                    icon={copyOutline}
                                                ></IonIcon>
                                            </IonButton>
                                        </IonCol>
                                        <IonCol size="1.5">
                                            <MobileView>
                                                <ShareButtonMobile
                                                    title="Intelikart Business Website"
                                                    text={`${message}`}
                                                />
                                            </MobileView>
                                            <BrowserView>
                                                <ShareButtonWeb
                                                    text={`${message}`}
                                                />
                                            </BrowserView>
                                        </IonCol>
                                    </IonRow>
                                </IonCardHeader>
                                <IonCardContent>
                                    <IonGrid>
                                        <IonRow>
                                            <IonCol>
                                                <IonText style={{ fontSize: "22px" }}>
                                                    Mobile & Electronic Stores
                                                </IonText>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol>
                                                <IonText className="websitelink dashboard-fnt-sz">
                                                    <a
                                                        href={`https://play.google.com/store/apps/details?id=com.intelikart.naganathmobile`}
                                                        target="_blank"
                                                    >
                                                        {`https://play.google.com/store/apps/details?id=com.intelikart.naganathmobile`}
                                                    </a>
                                                </IonText>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol>
                                                <IonText className="websitelink dashboard-fnt-sz">
                                                    <a
                                                        href={`https://naganathmobile.intelikart.com/`}
                                                        target="_blank"
                                                    >
                                                        {`https://naganathmobile.intelikart.com/`}
                                                    </a>
                                                </IonText>
                                            </IonCol>
                                        </IonRow>
                                        <br />
                                        <IonRow>
                                            <IonCol>
                                                <IonText style={{ fontSize: "22px" }}>
                                                    Grocery
                                                </IonText>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol>
                                                <IonText className="websitelink dashboard-fnt-sz">
                                                    <a
                                                        href={`https://play.google.com/store/apps/details?id=com.intelikart.groceryappforyou`}
                                                        target="_blank"
                                                    >
                                                        {`https://play.google.com/store/apps/details?id=com.intelikart.groceryappforyou`}
                                                    </a>
                                                </IonText>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol>
                                                <IonText className="websitelink dashboard-fnt-sz">
                                                    <a
                                                        href={`https://groceryappforyou.intelikart.com/`}
                                                        target="_blank"
                                                    >
                                                        {`https://groceryappforyou.intelikart.com/`}
                                                    </a>
                                                </IonText>
                                            </IonCol>
                                        </IonRow>
                                        <br />
                                        <IonRow>
                                            <IonCol>
                                                <IonText style={{ fontSize: "22px" }}>
                                                    Food & Restaurant
                                                </IonText>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol>
                                                <IonText className="websitelink dashboard-fnt-sz">
                                                    <a
                                                        href={`https://play.google.com/store/apps/details?id=com.intelikart.sampleappintelikart`}
                                                        target="_blank"
                                                    >
                                                        {`https://play.google.com/store/apps/details?id=com.intelikart.sampleappintelikart`}
                                                    </a>
                                                </IonText>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol>
                                                <IonText className="websitelink dashboard-fnt-sz">
                                                    <a
                                                        href={`https://intelikart.biz/`}
                                                        target="_blank"
                                                    >
                                                        {`https://intelikart.biz/`}
                                                    </a>
                                                </IonText>
                                            </IonCol>
                                        </IonRow>
                                        <br />
                                        <IonRow>
                                            <IonCol>
                                                <IonText style={{ fontSize: "22px" }}>
                                                    Medical
                                                </IonText>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol>
                                                <IonText className="websitelink dashboard-fnt-sz">
                                                    <a
                                                        href={`https://play.google.com/store/apps/details?id=com.intelikart.dilipmedicalstore`}
                                                        target="_blank"
                                                    >
                                                        {`https://play.google.com/store/apps/details?id=com.intelikart.dilipmedicalstore`}
                                                    </a>
                                                </IonText>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol>
                                                <IonText className="websitelink dashboard-fnt-sz">
                                                    <a
                                                        href={`https://dilipmedicalstore.com/`}
                                                        target="_blank"
                                                    >
                                                        {`https://dilipmedicalstore.com/`}
                                                    </a>
                                                </IonText>
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow> : null}

                    {/* ) : null */}
                    {/* } */}

                    {/* { announcementsLoading===false && announcements && announcements.data && content === 'ANNOUNCEMENT' ? 
    <>
        <IonText className="ion-text-center">
            <h5>Make exciting announcements</h5>
            <p>Will be sent as push notification to your customers</p>
        </IonText>
        <IonItem>
            <IonInput type="text" placeholder="Type your message here or select from the suggestions below." onIonChange={e=> setAnnouncement(e.detail.value)} value={announcement}/>
        </IonItem>
        <br/>
        <IonSlides ref={mySlides} pager={true} options={slideOpts} onIonSlidesDidLoad={slidesDidLoad}>
        {announcements.data.map((elem)=>{
            return(
                <IonSlide key={elem}><IonCard onClick={e=> setAnnouncement(elem)}><IonCardContent><IonText className="ion-margin ion-padding">{elem}</IonText></IonCardContent></IonCard></IonSlide>
            )
        })}
        </IonSlides>
        <br/>
        <div className="ion-text-center">
            <IonButton type="button" shape="round" onClick={sendAnnouncement}>Send</IonButton>
        </div>
    </>
    : null
} */}
                    {/* <IonLoading isOpen={printablesLoading === true || announcementsLoading === true || createAnnouncementLoading === true || downloadingLoader === true} message="Please Wait"></IonLoading>
        <IonToast isOpen={createAnnouncementLoading === false && Boolean(createAnnouncement)} message="Announcement will be sent to all of your app customers in next 10 minutes." duration={500} animated={true}
        /> */}
                    <IonLoading
                        isOpen={
                            printablesLoading === true ||
                            downloadingLoader === true
                        }
                        message="Please Wait"
                    ></IonLoading>
                    <IonToast
                        isOpen={showToast}
                        onDidDismiss={() => setShowToast(false)}
                        message="URL Copied"
                        duration={500}
                    />
                </IonContent>
            </div>
        </IonPage>
    );
};

export default Marketing;
