import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
    IonContent,
    IonLabel,
    IonText,
    IonLoading,
    IonBackButton,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonTitle,
    IonRadioGroup,
    IonItem,
    IonRadio,
    IonInput,
    IonButton,
    IonGrid,
    IonRow,
    IonCol,
    IonPage,
    IonList,
    IonListHeader,
    IonSelect,
    IonSelectOption,
} from "@ionic/react";
import { createCoupon, clearErrors } from "../../actions/dashboardActions";
import { getAccessToken } from "../../services/auth/userLocalStorage";
import { arrowBack as arrowBackIcon } from "ionicons/icons";
import { authenticateUserWhenLogout } from "../../actions/authActions";

const CreateCouponPage = () => {
    const [couponType, setCouponType] = useState("FLAT");
    const [amountOff, setAmountOff] = useState<
        string | number | null | undefined
    >("");
    const [validTill, setValidTill] = useState<
        string | number | null | undefined
    >("");
    const [couponCode, setCouponCode] = useState<string | null | undefined>(
        Math.random().toString(36).slice(2, 8).toUpperCase()
    );

    //validation
    const [isAmountOffEmpty, setIsAmountOffEmpty] = useState(false);
    const [isAmountOffValidate, setIsAmountOffValidate] = useState(true);
    const [isValidTillEmpty, setIsValidTillEmpty] = useState(false);
    const [isCouponCodeEmpty, setIsCouponCodeEmpty] = useState(false);
    const [isCouponCodeValidate, setIsCouponCodeValidate] = useState(true);

    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch();
    const history = useHistory();
    const {
        createCouponMessageLoading,
        createCouponMessage,
        createCouponMessageError,
    } = useSelector((state: any) => state.createCouponData);
    const { currentUser } = useSelector((state: any) => state.currentUser);

    const amountOffValidation = () => {
        let result = true;
        if (!amountOff) {
            setIsAmountOffEmpty(true);
            result = false;
        } else {
            setIsAmountOffEmpty(false);
            if (couponType !== "FLAT" && (0 > amountOff || amountOff > 100)) {
                console.log("thi df");
                setIsAmountOffValidate(false);
                result = false;
            } else {
                setIsAmountOffValidate(true);
            }
        }
        return result;
    };

    const validTillValidation = () => {
        let result = true;
        if (!validTill || validTill === "0") {
            setIsValidTillEmpty(true);
            result = false;
        } else {
            setIsValidTillEmpty(false);
        }
        return result;
    };

    const couponCodeValidation = () => {
        let result = true;
        if (!couponCode) {
            setIsCouponCodeEmpty(true);
            result = false;
        } else {
            setIsCouponCodeEmpty(false);
            if (/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(couponCode)) {
                setIsCouponCodeValidate(false);
                result = false;
            } else setIsCouponCodeValidate(true);
        }
        return result;
    };

    const submitHandler = async (e: any) => {
        e.preventDefault();
        setLoading(true);
        const res1 = await amountOffValidation();
        const res2 = await couponCodeValidation();
        const res3 = await validTillValidation();
        if (res1 && res2 && res3) {
            if (getAccessToken()) {
                await dispatch(
                    createCoupon(getAccessToken().token, {
                        code: `${couponCode}`,
                        value: `${amountOff}`,
                        method: `PERCENTAGE`,
                        valid_till: `${validTill}`,
                    })
                );
            } else {
                await setLoading(false);
                await dispatch(authenticateUserWhenLogout());
                await localStorage.clear();
                history.push("/");
            }
            setLoading(false);
            if (loading === false && createCouponMessage) {
                history.goBack();
            }
        }
        setLoading(false);
    };

    const generateRandomCode = () => {
        setCouponCode(Math.random().toString(36).slice(2, 8).toUpperCase());
    };
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/my/coupons" icon={arrowBackIcon} />
                    </IonButtons>
                    <IonTitle></IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">
                <form onSubmit={submitHandler}>
                    <IonItem>
                        <IonGrid
                            className="ion-no-padding ion-no-margin"
                            style={{ width: 100 + "%" }}
                        >
                            <IonRow className="ion-align-items-center">
                                <IonCol size="8">
                                    <IonLabel position="stacked">
                                        <h1>Enter coupon code</h1>
                                    </IonLabel>
                                </IonCol>
                                <IonCol className="ion-padding-top">
                                    <IonText
                                        color="primary"
                                        onClick={(e) => generateRandomCode()}
                                    >
                                        GENERATE
                                    </IonText>
                                </IonCol>
                            </IonRow>
                        </IonGrid>

                        <br />
                        <IonInput
                            placeholder="PICYHUKA"
                            type="text"
                            value={couponCode}
                            name="couponCode"
                            onIonChange={(e) => {
                                setCouponCode(e.detail.value);
                            }}
                        />
                    </IonItem>
                    <IonText
                        color="danger"
                        className={`${isCouponCodeEmpty === false && isCouponCodeValidate === true
                            ? "ion-hide"
                            : ""
                            }`}
                    >
                        <h5
                            style={{ fontSize: 12 + "px", marginTop: 0 + "px" }}
                            className={`ion-margin-horizontal ${isCouponCodeEmpty ? "" : "ion-hide"
                                }`}
                        >
                            Coupon code is required.
                        </h5>
                        <h5
                            style={{ fontSize: 12 + "px", marginTop: 0 + "px" }}
                            className={`ion-margin-horizontal ${isCouponCodeValidate ? "ion-hide" : ""
                                }`}
                        >
                            Coupon code can't contain special characters.
                        </h5>
                    </IonText>
                    <br />
                    <IonItem>
                        <IonLabel position="stacked">
                            <h1>Enter Percentage Off</h1>
                        </IonLabel>
                        <br />
                        {/* <IonInput
                            placeholder="Promo Value"
                            type="number"
                            value={amountOff}
                            name="amountOff"
                            onIonChange={(e) => {
                                setAmountOff(e.detail.value);
                            }}
                        /> */}
                        <IonItem>
                            <IonSelect value={amountOff} placeholder="Promo Value" onIonChange={(e) => { setAmountOff(e.detail.value); }}>
                                <IonSelectOption value="10">10%</IonSelectOption>
                                <IonSelectOption value="20">20%</IonSelectOption>
                                <IonSelectOption value="30">30%</IonSelectOption>
                                <IonSelectOption value="40">40%</IonSelectOption>
                            </IonSelect>
                        </IonItem>
                    </IonItem>
                    <br />
                    <IonItem>
                        <IonLabel position="stacked">
                            <h1>Valid Till</h1>
                        </IonLabel>
                        <br />
                        <IonInput
                            type="date"
                            value={validTill}
                            name="validTill"
                            onIonChange={(e) => {
                                setValidTill(e.detail.value);
                            }}
                        />
                    </IonItem>
                    <IonText
                        color="danger"
                        className={`${isValidTillEmpty === false ? "ion-hide" : ""}`}
                    >
                        <h5
                            className="ion-margin-horizontal"
                            style={{ fontSize: 12 + "px", marginTop: 0 + "px" }}
                        >
                            This Field is required.
                        </h5>
                    </IonText>
                    <br />
                    <IonButton type="submit">Create Coupon</IonButton>
                </form>
                <IonLoading
                    isOpen={loading === true}
                    message="Please Wait"
                ></IonLoading>
            </IonContent>
        </IonPage>
    );
};

export default CreateCouponPage;
