import { IonCol, IonGrid, IonRow, IonLoading, IonFooter, IonButton, IonIcon, IonContent, IonText, IonItem, IonToolbar, IonTitle, IonHeader, IonLabel, IonInput, IonModal, useIonToast, } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { getAccessToken } from "../../services/auth/userLocalStorage";
import { API_ROOT } from "../../constants/global";
import { useHistory, Route, Redirect } from "react-router-dom";
import {
    arrowForwardOutline as rightArrowIcon,
    arrowBackOutline as leftArrowIcon,
    link,
    informationCircle,
    closeOutline as closeOutlineIcon,
} from "ionicons/icons";
import { ContactButton } from "./ContactButton";
import { async } from "q";
import { storeClaim } from "../../actions/dashboardActions";
import { useDispatch, useSelector } from "react-redux";

const MyTable = () => {
    const [details, setDetails] = useState([]);
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const [nextPage, setNextPage] = useState();
    const [previousPage, setPreviousPage] = useState();
    const [showModal, setShowModal] = useState(false);
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [present] = useIonToast();
    const [subdomain, setSubdomain] = useState('');
    const [errors,setErrors]=useState({phone:"",email:"",subdomain:""});
   const [flag,setFlag]=useState(false);
    const dispatch=useDispatch();
    const {
        createClaimMessageLoading,
        createClaimMessage,
        createClaimMessageError,
    } = useSelector((state: any) => state.storeClaim);
    const handleOpenModal = () => {
      setShowModal(true);
      setErrors({
        phone: "",
        email: "",
        subdomain: "",
      });
      setEmail("");
      setPhone('');
      setSubdomain('');
    };
    const updatehandleCloseModal=()=>{
        setShowModal(false);    
    }
    const handleCloseModal =async() => {

        let errors={
            phone:"",
            email:"",
            subdomain:"",
        }
        if (!isValidEmail(email)) {
                    errors.email="please enter a valid email";
            
          } 
          if(phone.length !=10){       
        errors.phone="please enter a valid phone number"
          }
          if (!isValidSubdomain(subdomain)) {
            errors.subdomain = "Please enter a valid subdomain";
          }
          setErrors(errors);
          if (Object.values(errors).every((error) => !error)){
          try{
       await dispatch(
                storeClaim(getAccessToken().token, {
                   phone,email,subdomain
                })
            );
            setFlag(!flag);
        }
        catch(e){
            console.log(e)
        }
          }
    };   
    useEffect(()=>{
        if(Object.keys(createClaimMessage).length>0){
            present({
                message:createClaimMessage,
                duration: 2000,
                position: "bottom",
              });
              setShowModal(false);
        }
    },[flag])
    const isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
      };
      const isValidSubdomain = (subdomain) => {
        const subdomainRegex =  /^(?!.*\.\.)(?!^\.)[a-zA-Z0-9.]+(?<!\.)$/;
        return subdomainRegex.test(subdomain);
      };
    useEffect(() => {
        const token = getAccessToken();
        setLoading(true);
        const fetchData = async () => {
            const response = await fetch(`${API_ROOT}partners/stores`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    authorization: `Bearer ${token.token}`,
                },
            });
            const resdata = await response.json();
            if (resdata !== undefined) {
                setDetails(resdata.data);
                setNextPage(resdata?.meta.pagination.links.next);
                setPreviousPage(resdata?.meta.pagination.links.previous);
            }
        };
        setLoading(false);
        fetchData();
    }, [flag]);
     console.log(details);

    const handleNextPageClick = async () => {
        const token = getAccessToken();
        setLoading(true);
        let response = await fetch(nextPage, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                authorization: `Bearer ${token.token}`,
            },
        });

        response.json().then(async (res) => {
            if (res && res.data && res.data.length > 0) {
                setDetails(res?.data);
                setNextPage(res?.meta.pagination.links.next);
                setPreviousPage(res?.meta.pagination.links.previous);
            }
        }).catch(err => console.error(err));
        setLoading(false);
    }

    const handlePreviousPageClick = async () => {
        const token = getAccessToken();
        setLoading(true);
        let response = await fetch(previousPage, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                authorization: `Bearer ${token.token}`,
            },
        });

        response.json().then(async (res) => {
            if (res && res.data && res.data.length > 0) {
                setDetails(res?.data);
                setNextPage(res?.meta.pagination.links.next);
                setPreviousPage(res?.meta.pagination.links.previous);
            }
        }).catch(err => console.error(err));
        setLoading(false);
    }

    return (
        <>
        <IonContent>
            <IonGrid
                className="ion-no-padding ion-no-margin"
                style={{ color: "#353535" }}
            >
                <div className="ion-text-right ion-margin">
                <IonButton onClick={handleOpenModal}>
                 Claim a Store 
                </IonButton>
                <IonModal isOpen={showModal} onDidDismiss={updatehandleCloseModal}>
        <IonHeader>
          <IonToolbar>
            <IonTitle>Enter Store Information</IonTitle>

            <IonText
                            slot="end"
                            color="primary"
                            onClick={(e) => {
                                setShowModal(false)
                          
                            }}
                            style={{ cursor: "pointer" }}
                          >
                                <h1 className="ion-padding-end"><IonIcon
                                  slot="icon-only"
                                  size="large"
                                  icon={closeOutlineIcon}
                                ></IonIcon>
                                </h1>
                          </IonText>
          </IonToolbar>
        </IonHeader>

        <IonContent className="ion-padding">
          <IonItem >
            <IonLabel position="floating">Phone Number</IonLabel>
            <IonInput value={phone}  onIonChange={(e)=>setPhone(e.detail.value)}
            ></IonInput> 
          </IonItem>
          <IonText color="danger"> {errors.phone}</IonText>

          <div style={{ marginTop:"20px",marginBottom:"10px" }}></div>
          <IonItem>
            <IonLabel position="floating">Email</IonLabel>
            <IonInput value={email} onIonChange={(e)=>setEmail(e.detail.value)}></IonInput>
          </IonItem>
          <IonText color="danger">{errors.email} </IonText>

          <div style={{ marginTop:"20px",marginBottom:"10px" }}> </div>
             <IonItem>
            <IonLabel position="floating">Subdomain</IonLabel>
            <IonInput value={subdomain} onIonChange={(e)=>setSubdomain(e.detail.value)}></IonInput>
          </IonItem>
          <IonText color="danger"> {errors.subdomain}  </IonText>
          <div style={{ marginTop:"20px",marginBottom:"10px" }}></div>

<div className="ion-text-center">
          <IonButton shape="round" className="ion-margin" onClick={handleCloseModal}>
            Save
          </IonButton>
          </div>
        </IonContent>
      </IonModal>
                    </div>
                <IonRow
                    className="ionrow-style ion-align-items-center ion-text-center"
                    style={{ padding: 10 + "px" }}
                >
                    <IonCol className="ioncol-style" sizeMd="6" sizeSm="12">
                        <b>STORE DETAILS</b>
                    </IonCol>
                    <IonCol className="ioncol-style ion-hide-sm-down" sizeMd="3">
                        <b>CURRENT SUB</b>
                    </IonCol>
                    <IonCol className="ioncol-style ion-hide-sm-down" sizeMd="3">
                        <b>REVENUES</b>
                    </IonCol>
                </IonRow>
                <IonRow
                    className="ionrow-style ion-align-items-center ion-text-center"
                    style={{ backgroundColor: "white", padding: 10 + "px" }}
                >
                    <IonCol className="ioncol-style" sizeMd="1" sizeSm="2">
                        <b>ID</b>
                    </IonCol>
                    <IonCol className="ioncol-style" sizeMd="2.5" sizeSm="5">
                        <b>Name</b>
                    </IonCol>
                    <IonCol className="ioncol-style" sizeMd="2.5" sizeSm="5">
                        <b>Details</b>
                    </IonCol>
                    <IonCol className="ioncol-style ion-hide-sm-down" sizeMd="1.5">
                        <b>End Date</b>
                    </IonCol>
                    <IonCol className="ioncol-style ion-hide-sm-down" sizeMd="1.5">
                        <b>Package</b>
                    </IonCol>
                    <IonCol className="ioncol-style ion-hide-sm-down" sizeMd="1.5">
                        <b>Onboarding</b>
                    </IonCol>
                    <IonCol className="ioncol-style ion-hide-sm-down" sizeMd="1.5">
                        <b>Subscription</b>
                    </IonCol>
                </IonRow>
                
                {details?.map((row) => {
                    return (
                        <IonRow
                            key={row.id}
                            className="ionrow-style ion-align-items-center ion-text-center"
                            style={{ height: "80px" }}
                        >
                            <IonCol className="ioncol-style" sizeMd="1" sizeSm="2">
                                {row.id}
                            </IonCol>
                            <IonCol className="ioncol-style" sizeMd="2.5" sizeSm="5">
                                {row.name}<br />
                                {row.subdomain !== null && 
                                <a style={{textDecoration: 'none'}} href={`https://${row.subdomain}`} target="_blank">
                                  <IonButton size='small' fill='outline' >
                                    <IonIcon slot="icon-only" size="small"  icon={link}></IonIcon>
                                  </IonButton>
                                </a>                       
                                }
                            </IonCol>
                            <IonCol className="ioncol-style" sizeMd="2.5" sizeSm="5">
                                <IonText className="ion-padding">{row.city == "undefined" || row.city == null ? null : `${row.city}`}</IonText><br />
                                {row.phone === null ?
                                 null :  <ContactButton data={row.phone} btn='Call'/>}
                                {row.email === null ?
                                null: <ContactButton data={row.email} btn='Mail'/>} 
                                                               
                            </IonCol>
                            <IonCol className="ioncol-style ion-hide-sm-down" sizeMd="1.5">
                                {row.current_subscription.end_date != null ? new Date(row.current_subscription.end_date)
                                    .toDateString()
                                    .split(" ")
                                    .slice(1)
                                    .join(" ") : null}
                            </IonCol>
                            <IonCol className="ioncol-style ion-hide-sm-down" sizeMd="1.5">
                                {row.current_subscription.package}
                            </IonCol>
                            <IonCol className="ioncol-style ion-hide-sm-down" sizeMd="1.5">
                                {row.onboarding.date != null ? new Date(row.onboarding.date)
                                    .toDateString()
                                    .split(" ")
                                    .slice(1)
                                    .join(" ") : null}
                            </IonCol>
                            <IonCol className="ioncol-style ion-hide-sm-down" sizeMd="1.5">                                
                                  {row.onboarding.amount}
                            </IonCol>
                        </IonRow>
                    );
                })}
            </IonGrid>
            <IonLoading
                isOpen={
                    loading === true
                }
                message="Please Wait"
            ></IonLoading>

        </IonContent>
        <IonFooter>
                <IonGrid>
                    <IonRow>

                        {previousPage ? <IonCol>
                            <div className="ion-text-left">
                                <IonButton fill="outline" onClick={() => handlePreviousPageClick()}>
                                    <IonIcon
                                        slot="start"
                                        size="medium"
                                        icon={leftArrowIcon}
                                    ></IonIcon>
                                    Previous
                                </IonButton>
                            </div>
                        </IonCol> : null}


                         {nextPage ? <IonCol>
                            <div className="ion-text-right">
                                <IonButton fill="outline" onClick={() => handleNextPageClick()}>
                                    Next
                                    <IonIcon
                                        slot="end"
                                        size="medium"
                                        icon={rightArrowIcon}
                                    ></IonIcon>
                                </IonButton>
                            </div>
                        </IonCol> : null}


                    </IonRow>
                </IonGrid>
            </IonFooter>
        </>
    );
};

export default MyTable;
