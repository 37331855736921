import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { DatePicker } from "@ionic-native/date-picker";
import { useDispatch, useSelector } from 'react-redux';
import { IonContent, IonLabel, IonText, IonLoading, IonBackButton, IonHeader, IonToolbar, IonButtons, IonTitle, IonItem, IonInput, IonButton, IonPage, IonItemDivider, IonRange, IonList, IonDatetime, IonCard, IonGrid, IonRow, IonCol, IonCardContent, IonCardHeader, IonChip, IonPopover, isPlatform } from '@ionic/react';
import { getServiceDetails, setServiceDetails, clearErrors } from '../../actions/dashboardActions';
import {getAccessToken} from '../../services/auth/userLocalStorage';
import TimePicker from 'react-time-picker';

import {
    arrowBack as arrowBackIcon
} from 'ionicons/icons';
import { formatDate } from '../../services/formatters';
import dayjs from 'dayjs';

const ServiceDetails = () => {
    const [whatsappNumber, setWhatsappNumber] = useState<any>();
    const [phoneNumber, setPhoneNumber] = useState<any>();
    const [minimumOrder, setMinimumOrder] = useState<any>();
    const [deliverWithin, setDeliverWithin] = useState<any>(0);
    const [openTime, setOpenTime] = useState<any>();
    const [closeTime, setCloseTime] = useState<any>();

    //Whatsapp number validation
    const [isWhatsappNumValid, setisWhatsappNumValid] = useState(true);
    const [isPhoneNumValid, setisPhoneNumValid] = useState(true);
    const [isMinimumOrderValid, setisMinimumOrderValid] = useState(true);

    const dispatch = useDispatch();
    const { serviceDetailLoading, serviceDetail, serviceDetailError } = useSelector((state : any)=> state.serviceDetail);
    const { setServiceDetailResponseLoading, setServiceDetailResponse, setServiceDetailResponseError } = useSelector((state : any)=> state.setServiceDetail);
    const { currentUser } = useSelector((state : any)=> state.currentUser);

    useEffect(() => {
        if(currentUser && currentUser.data){
            dispatch(getServiceDetails(getAccessToken(), currentUser.data[0].store_id));
        }
    }, [currentUser]);

    useEffect(() => {
        if(serviceDetailLoading === false && serviceDetail && serviceDetail.data){
            setWhatsappNumber(serviceDetail.data?.whatsapp_phone);
            setMinimumOrder(serviceDetail.data.min_order);
            setDeliverWithin(serviceDetail.data.delivery_within_kms);
            setPhoneNumber(serviceDetail.data.phone)
            if(serviceDetail.data.open_time){
                console.log(serviceDetail.data.open_time)
                setOpenTime(new Date (new Date().toDateString() + ' ' + serviceDetail.data.open_time).toISOString());
            }
            if(serviceDetail.data.close_time){
                setCloseTime(new Date (new Date().toDateString() + ' ' + serviceDetail.data.close_time).toISOString());
            }
        }
    }, [serviceDetail]);

    const openDate=()=>{
        DatePicker.show({
            date: new Date(),
            mode: 'date'
          }).then(
            date => console.log('Got date: ', date),
            err => console.log('Error occurred while getting date: ', err)
          )
    }

    const whatsappNumberValidation = ()=>{
        let result = true;
        if(whatsappNumber){
            if(/\D/.test(whatsappNumber)){
                setisWhatsappNumValid(false);
                result = false;
            }
            else{
                setisWhatsappNumValid(true);
                if(whatsappNumber.length !== 10){
                    setisWhatsappNumValid(false);
                    result = false;
                }else
                    setisWhatsappNumValid(true);
            }
        }
        return result;
    }
    const phoneNumberValidation = ()=>{
        let result = true;
        if(phoneNumber){
            if(/\D/.test(phoneNumber)){
                setisPhoneNumValid(false);
                result = false;
            }
            else{
                setisPhoneNumValid(true);
                if(phoneNumber.length !== 10){
                    setisPhoneNumValid(false);
                    result = false;
                }else
                    setisPhoneNumValid(true);
            }
        }
        return result;
    }
    const minimumOrderValidation = ()=>{
        let result = true;
        if(minimumOrder){
            if(/\D/.test(minimumOrder)){
                setisMinimumOrderValid(false);
                result = false;
            }
            else{
                setisMinimumOrderValid(true);
            }
        }
        return result;
    }

    const submitHandler = async (e: any) => {
        e.preventDefault();
        if(whatsappNumberValidation() === true && phoneNumberValidation()===true && minimumOrderValidation() === true){
            setisWhatsappNumValid(true);
            if(currentUser && currentUser.data){
                Promise.all([await dispatch(setServiceDetails(getAccessToken(), currentUser.data[0].store_id, {
                    whatsapp_phone: `${whatsappNumber}`,
                    open_time: formatDate(openTime,'hh:mm A'),
                    close_time: formatDate(closeTime,'hh:mm A'),
                    min_order: `${minimumOrder}`,
                    delivery_within_kms: `${deliverWithin}`,
                    phone:`${phoneNumber}`
                })),await dispatch(getServiceDetails(getAccessToken(), currentUser.data[0].store_id))]);
            }
        }
    }
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/my/settings" icon={arrowBackIcon}/>
                    </IonButtons>
                    <IonTitle>Service Details</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">
                <form onSubmit={submitHandler}>
                    <IonGrid>
                        <IonRow className="ion-align-items-center">
                            <IonCol size="4">
                                <IonLabel>Whatsapp Number</IonLabel>
                            </IonCol>
                            <IonCol>
                                <IonItem>
                                    <IonInput placeholder={whatsappNumber} type="tel" value={whatsappNumber} name="whatsappNumber" onIonChange={(e)=>{ setWhatsappNumber(e.detail.value)}} onIonBlur={(e)=>whatsappNumberValidation()}/>
                                </IonItem>
                                <IonText className={`${isWhatsappNumValid ? "ion-hide": ""}`} color="danger">
                                    <h5 className="ion-margin-start" style={{fontSize: 12+'px'}}>Whatsapp Number is not valid.</h5>
                                </IonText>
                            </IonCol>
                        </IonRow>
                        <IonRow className="ion-align-items-center">
                            <IonCol size="4">
                                <IonLabel>Phone Number</IonLabel>
                            </IonCol>
                            <IonCol>
                                <IonItem>
                                    <IonInput value={phoneNumber } type="tel" onIonChange={(e)=>{ setPhoneNumber(e.detail.value)}} onIonBlur={(e)=>phoneNumberValidation()} />
                                </IonItem>
                                <IonText className={`${isPhoneNumValid ? "ion-hide": ""}`} color="danger">
                                    <h5 className="ion-margin-start" style={{fontSize: 12+'px'}}>Phone Number is not valid.</h5>
                                </IonText>
                            </IonCol>
                        </IonRow>
                        <IonRow className="ion-align-items-center">
                            <IonCol size="4">
                                <IonLabel>Minimum Order</IonLabel>
                            </IonCol>
                            <IonCol>
                                <IonItem>
                                    <IonInput placeholder={minimumOrder} type="text" value={minimumOrder} name="minimumOrder" onIonChange={(e)=>{ setMinimumOrder(e.detail.value)}} onIonBlur={()=> minimumOrderValidation()}/>
                                </IonItem>
                                <IonText className={`${isMinimumOrderValid ? "ion-hide": ""}`} color="danger">
                                    <h5 className="ion-margin-start" style={{fontSize: 12+'px'}}>Minimum order must contains numbers only.</h5>
                                </IonText>
                            </IonCol>
                        </IonRow>
                        <IonRow className="ion-align-items-center">
                            <IonCol size="4">
                                <IonLabel>Store Timings</IonLabel>
                            </IonCol>
                            <IonCol>
                                <IonItem lines="none">
                                    <IonChip  outline  color="secondary">
                                        <IonDatetime presentation="time" value={openTime} onIonChange={e => setOpenTime(e.detail.value)}></IonDatetime>
                                        </IonChip>
                                    <IonChip  outline  color="secondary">
                                    <IonDatetime presentation="time" value={closeTime} onIonChange={e => setCloseTime(e.detail.value)}></IonDatetime>
                                    </IonChip> 
                                </IonItem>
                            </IonCol>
                        </IonRow>
                        <IonRow className="ion-align-items-center">
                            <IonCol size="4">
                                <IonLabel>Deliver Within</IonLabel>
                            </IonCol>
                            <IonCol>
                                <IonItem lines="none">
                                    <IonRange pin={true} value={deliverWithin} onIonChange={e => setDeliverWithin(e.detail.value as number)} />
                                    <IonText>{`${deliverWithin == 0 || deliverWithin===null ? "unlimited" : `${deliverWithin} kms`}`}</IonText>
                                </IonItem>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                    <div className="ion-text-center">
                        <IonButton shape="round" type="submit">Save</IonButton>
                    </div>
                </form>
                <IonLoading isOpen={serviceDetailLoading === true || setServiceDetailResponseLoading === true} message="Please Wait"></IonLoading>
            </IonContent>
        </IonPage>
    )
}

export default ServiceDetails
