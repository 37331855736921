import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    IonContent,
    IonApp,
    IonText,
    IonItem,
    IonIcon,
    IonLoading,
    IonCard,
    IonButton,
    IonAlert,
    IonPage,
    IonToast,
    IonCardContent,
    IonGrid,
    IonRow,
    IonCol,
} from "@ionic/react";
import DashboardMenu from "../../components/dashboard/DashboardMenu";
import {
    getCoupons,
    deleteCoupon,
    clearErrors,
} from "../../actions/dashboardActions";
import { Clipboard } from "@capacitor/clipboard";
import { getAccessToken } from "../../services/auth/userLocalStorage";
import { BrowserView, MobileView } from "react-device-detect";
import Header from "../../components/dashboard/Header";
import {
    trash as trashIcon,
    shareSocial as shareSocialIcon,
    copyOutline,
    createOutline as createIcon
} from "ionicons/icons";
import { Share } from "@capacitor/share";
import { authenticateUserWhenLogout } from "../../actions/authActions";
import { useHistory } from "react-router";
import { ShareButtonMobile } from "../../components/ShareButtonMobile";
import { ShareButtonWeb } from "../../components/ShareButtonWeb";

const Coupons = () => {
    const [showAlert, setShowAlert] = useState(false);
    const [alertData, setAlertData] = useState<any>();
    const dispatch = useDispatch();
    const history = useHistory();
    const [showCouponToast, setShowCouponToast] = useState(false);
    const { couponsLoading, coupons, couponsError } = useSelector(
        (state: any) => state.coupons
    );
    const { deleteCouponMessageLoading, deleteCouponMessageError } = useSelector(
        (state: any) => state.deleteCouponData
    );
    const { currentUser } = useSelector((state: any) => state.currentUser);
    const {
        createCouponMessageLoading,
        createCouponMessage,
        createCouponMessageError,
    } = useSelector((state: any) => state.createCouponData);
    const {
        homeScreenWithDaysLoading,
        homeScreenWithDays,
        homeScreenWithDaysError,
    } = useSelector((state: any) => state.homeScreenDataWithDays);

    useEffect(() => {
        dispatch(getCoupons(getAccessToken().token));
    }, [currentUser]);

    useEffect(() => {
        if (createCouponMessageLoading === false) {
            dispatch(getCoupons(getAccessToken().token));
        }
    }, [createCouponMessageLoading]);

    useEffect(() => {
        if (deleteCouponMessageLoading === false) {
            dispatch(getCoupons(getAccessToken().token));
        }
    }, [deleteCouponMessageLoading]);

    const onCouponDeleteClick = async (value: any) => {
        if (getAccessToken()) {
            await dispatch(deleteCoupon(getAccessToken().token, `${value.id}`));
        } else {
            await dispatch(authenticateUserWhenLogout());
            await localStorage.clear();
            history.push("/");
        }
    };

    const copyToClipboard = async (data: string) => {
        await Clipboard.write({
            string: `${data}`,
        });
    };

    const shareCoupon = async (value: any) => {
        await Share.share({
            title: "Intelikart Coupon",
            text: ``,
        });
    };
    return (
        <IonPage>
            <div className="ion-page" id="main-content">
                <Header name="" />
                <IonContent className="ion-padding">
                    <IonText className="ion-text-center ion-margin-bottom">
                        <h5 className="ion-no-margin">
                            Manage coupons for your app and website
                        </h5>
                        <p>Using coupons you can run online sale</p>
                    </IonText>
                    {couponsLoading === false && coupons
                        ? coupons.data.map((value: any) => {
                            return (
                                <IonCard key={value.id}>
                                    <IonCardContent>
                                        <IonGrid className="ion-no-padding ion-no-margin">
                                            <IonRow className="ion-no-padding ion-no-margin">
                                                <IonCol className="ion-no-padding ion-no-margin"
                                                    size="10">
                                                    <IonText style={{ fontSize: "22px" }}>
                                                        {`${value.code} `}<br />
                                                    </IonText>
                                                    <IonText style={{ fontSize: "15px" }}>
                                                        {`${value.value}% off on all orders `}
                                                        {`valid till ${new Date(value.valid_till)
                                                            .toDateString()
                                                            .split(" ")
                                                            .slice(1)
                                                            .join(" ")}`}
                                                    </IonText>
                                                </IonCol>
                                                <IonCol size="1">
                                                    <IonButton
                                                        fill="clear"
                                                        color="secondary"
                                                        onClick={(e) => {
                                                            copyToClipboard(`${value.code}`);
                                                            setShowCouponToast(true);
                                                        }}
                                                    >
                                                        <IonIcon
                                                            slot="icon-only"
                                                            size="large"
                                                            icon={copyOutline}
                                                        ></IonIcon>
                                                    </IonButton>
                                                </IonCol>
                                                <IonCol size="1">
                                                    <IonButton
                                                        fill="clear"
                                                        color="secondary"
                                                        onClick={(e) => {
                                                            setAlertData(value);
                                                            setShowAlert(true);
                                                        }}>
                                                        <IonIcon
                                                            slot="icon-only"
                                                            size="large"
                                                            icon={trashIcon}
                                                        ></IonIcon>
                                                    </IonButton>
                                                </IonCol>
                                            </IonRow>
                                        </IonGrid>
                                    </IonCardContent>
                                </IonCard>
                            );
                        })
                        : null}
                    <div className="ion-text-center">
                        <IonButton shape="round" routerLink="/my/createcoupon">
                            ADD COUPON
                        </IonButton>
                    </div>
                    <IonAlert
                        isOpen={showAlert === true}
                        onDidDismiss={() => setShowAlert(false)}
                        header={"Delete Coupon"}
                        message={`You are about to delete coupon ${alertData && alertData.code ? alertData.code : ""
                            }`}
                        buttons={[
                            {
                                text: "CANCEL",
                                role: "cancel",
                            },
                            {
                                text: "DELETE",
                                handler: () => {
                                    onCouponDeleteClick(alertData);
                                },
                            },
                        ]}
                    />

                    <IonLoading
                        isOpen={
                            couponsLoading === true || deleteCouponMessageLoading === true
                        }
                        message="Please Wait"
                    ></IonLoading>
                    <IonToast
                        isOpen={showCouponToast}
                        onDidDismiss={() => setShowCouponToast(false)}
                        message="Your Coupon Code copied"
                        duration={500}
                    />
                </IonContent>
            </div>
        </IonPage>
    );
};

export default Coupons;

