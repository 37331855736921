import { IonCol, IonContent, IonGrid, IonPage, IonRow, IonLoading } from "@ionic/react";
import React, {useEffect, useState} from "react";
import Header from "../../components/dashboard/Header"
    
    const HubspotContactForm = () => {

        const [isloading, setIsLoading] = useState<boolean>(false);

        useEffect(() => {
            setIsLoading(true);
            const script = document.createElement('script');
            script.src='https://js.hsforms.net/forms/shell.js';
            document.body.appendChild(script);
    
            script.addEventListener('load', () => {
                // @TS-ignore
                if ((window as any).hbspt) {
                    // @TS-ignore
                    (window as any).hbspt.forms.create({
                        region: "na1",
                        portalId: "8742678",
                        formId:  "131c1d75-2532-4ea2-9b25-edd79522092c",
                        target: '#hubspotForm'
                    })
                }
            });
            setIsLoading(false);
        }, []);
    
        return (
            <IonPage>
                <Header name="" />
                <IonContent>
                    <IonGrid className="ion-padding ion-margin">
                        <IonRow>
                            <IonCol>
                             <div id="hubspotForm"></div>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </IonContent>
                <IonLoading
                        isOpen={
                            isloading === true
                        }
                        message="Please Wait"
                    ></IonLoading>
            </IonPage>
        );
    };
    
    export default HubspotContactForm;
