import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
    IonContent,
    IonLabel,
    IonText,
    IonLoading,
    IonBackButton,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonTitle,
    IonItem,
    IonInput,
    IonButton,
    IonPage,
    IonRow,
    IonGrid,
    IonCol,
    isPlatform,
    IonTextarea,
} from "@ionic/react";
import {
    getBasicInfo,
    setBasicInfo,
    clearErrors,
} from "../../actions/dashboardActions";
import { getAccessToken } from "../../services/auth/userLocalStorage";
import { arrowBack as arrowBackIcon } from "ionicons/icons";
import { Camera, CameraResultType } from "@capacitor/camera";

// async function savePicture(file) {
//     if(!file)return ""
//     try{
//         const formData = new FormData();
//         formData.append("file",file)
//         const response = await fetch(`https://api.intelikart.com/api/upload/logo-image`, {
//         method: 'POST',
//         body:formData
//         });
//         const data = await response.json();
//         return data.platform_path;
//     }
//     catch(error){
//         console.log(error)
//         return ""
//     }
//   }

const BasicInfo = () => {
    // const [businessName, setBusinessName] = useState<any>();
    // const [description, setDescription] = useState<any>();
    // const [isGSTValid, setIsGSTValid] = useState(true);
    // const [pictureUrl, setPictureUrl] = useState('https://cdn.intelikart.com/uploads/new-images/default-store.png');
    // const[imageFile,setImageFile]=useState(null)
    const [loading, setLoading] = useState(false);
    // const [GSTIN, setGSTIN] = useState<any>();
    const [name, setName] = useState<undefined | string | null>("");
    const [email, setEmail] = useState<undefined | string | null>("");
    const [phone, setPhone] = useState<undefined | string | null>("");
    const [city, setCity] = useState<any>();
    const [state, setState] = useState<any>();
    const [ZIP, setZIP] = useState<any>();

    const dispatch = useDispatch();
    const history = useHistory();
    const { basicInfoLoading, basicInfo, basicInfoError } = useSelector(
        (state: any) => state.basicInfo
    );
    const {
        setBasicInfoResponseLoading,
        setBasicInfoResponse,
        setBasicInfoResponseError,
    } = useSelector((state: any) => state.setBasicInfo);
    const { currentUser } = useSelector((state: any) => state.currentUser);
    const fileInputRef = useRef<HTMLInputElement>();
    // useEffect(() => {
    //     if(currentUser && currentUser.data){
    //         dispatch(getBasicInfo(getAccessToken(), currentUser.data[0].store_id));
    //     }
    // }, [currentUser]);

    // useEffect(() => {
    //     if(basicInfoLoading === false && basicInfo && basicInfo.data){
    //         setBusinessName(basicInfo.data.name);
    //         setDescription(basicInfo.data.business_description);
    //         setGSTIN(basicInfo.data.gst);
    //         if(basicInfo.data.logo_platform_path!==null){
    //             setPictureUrl(basicInfo.data.logo_internet_url)
    //         }
    //     }
    // }, [basicInfo])

    // const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     if (event.target.files.length > 0) {
    //       const file = event.target.files.item(0);
    //       setImageFile(file)
    //       const pictureUrl = URL.createObjectURL(file);
    //       setPictureUrl(pictureUrl);
    //     }
    //   };
    //   const handlePictureClick= async()=>{

    //     if(isPlatform('capacitor')){
    //       try{
    //         const photo=await Camera.getPhoto({
    //           resultType:CameraResultType.Uri,
    //         })
    //         setPictureUrl(photo.webPath);
    //       }
    //       catch(error){
    //         console.log(error);
    //       }
    //     }
    //     else{
    //       fileInputRef.current.click();
    //     }

    //   }
    //   const GSTValidation = ()=>{
    //     let result = true;
    //     if(GSTIN){
    //         if(GSTIN.length !== 15){
    //             setIsGSTValid(false);
    //             result = false;
    //         }else{
    //             setIsGSTValid(true);
    //         }
    //     }
    //     return result;
    // }

    // const submitHandler = async (e: any) => {
    //     e.preventDefault();

    //     if(currentUser && currentUser.data && GSTValidation()){
    //         setLoading(true)
    //         let platform_path= await savePicture(imageFile)
    //         if(!platform_path)platform_path=basicInfo.data.logo_platform_path
    //         Promise.all([await dispatch(setBasicInfo(getAccessToken(), currentUser.data[0].store_id, {
    //             name: `${businessName}`,
    //             business_description: `${description}`,
    //             gst: `${GSTIN}`,
    //             logo :platform_path
    //         })),await dispatch(getBasicInfo(getAccessToken(), currentUser.data[0].store_id))
    //         ])
    //     setLoading(false)
    //     }
    // }

    // useEffect(() => {
    //     if (currentUser !== undefined) {
    //         console.log(
    //             "name:",
    //             `${currentUser?.data[0].first_name} ${currentUser?.data[0].last_name}`
    //         );
    //         console.log(
    //             "email:",
    //             currentUser?.data[0].email,
    //             "phone:",
    //             currentUser?.data[0].phone
    //         );
    //         console.log(
    //             "city:",
    //             currentUser?.data[0].city,
    //             "zip:",
    //             currentUser?.data[0].zip
    //         );

    //         console.log("state/region:", currentUser?.data[0].region);

    //         setName(
    //             `${currentUser?.data[0].first_name} ${currentUser?.data[0].last_name}`
    //         );
    //         setEmail(currentUser?.data[0].email);
    //         setPhone(currentUser?.data[0].phone);
    //         setCity(currentUser?.data[0].city);
    //         setZIP(currentUser?.data[0].zip);
    //         setState(currentUser?.data[0].region);
    //     }
    // }, [currentUser]);

    useEffect(() => {
        const token = getAccessToken();
        if(token)
        {
            setName(token.profile.partner_name);
            setEmail(token.user.email);
            setPhone(token.profile.partner_phone);
            setCity(token.profile.partner_city);
            setState(token.profile.partner_state);
            setZIP(token.profile.partner_PIN);
        }
    }, []);

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton
                            defaultHref="/my/settings"
                            icon={arrowBackIcon}
                        />
                    </IonButtons>
                    <IonTitle></IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">
                {/* {console.log("Current User: ", currentUser)} */}
                {/* <form onSubmit={submitHandler}> */}
                <form>
                    <IonGrid>
                        <IonRow className="ion-align-items-center">
                            <IonCol size="4">
                                <IonLabel>Name</IonLabel>
                            </IonCol>
                            <IonCol>
                                <IonItem>
                                    <IonInput
                                        placeholder={name}
                                        type="text"
                                        value={name}
                                        name="name"
                                        // onIonChange={(e) => {
                                        //     setName(e.detail.value);
                                        // }}
                                        readonly
                                    />
                                </IonItem>
                            </IonCol>
                        </IonRow>
                        <IonRow className="ion-align-items-center">
                            <IonCol size="4">
                                <IonLabel>Email</IonLabel>
                            </IonCol>
                            <IonCol>
                                <IonItem>
                                    <IonInput
                                        type="email"
                                        placeholder={email}
                                        value={email}
                                        name="email"
                                        // onIonChange={(e) => {
                                        //     setEmail(e.detail.value);
                                        // }}
                                        readonly
                                    />
                                </IonItem>
                            </IonCol>
                        </IonRow>
                        <IonRow className="ion-align-items-center">
                            <IonCol size="4">
                                <IonLabel>Mobile Number</IonLabel>
                            </IonCol>
                            <IonCol>
                                <IonItem>
                                    <IonInput
                                        placeholder={phone}
                                        type="tel"
                                        inputmode="numeric"
                                        value={phone}
                                        name="phone"
                                        // onIonChange={(e) => {
                                        //     setPhone(e.detail.value);
                                        // }}
                                        readonly
                                    />
                                </IonItem>
                            </IonCol>
                        </IonRow>
                        <IonRow className="ion-align-items-center">
                            <IonCol size="4">
                                <IonLabel>City</IonLabel>
                            </IonCol>
                            <IonCol>
                                <IonItem>
                                    <IonInput
                                        type="text"
                                        placeholder={city}
                                        value={city}
                                        name="city"
                                        // onIonChange={(e) => {
                                        //     setCity(e.detail.value);
                                        // }}
                                        readonly
                                    />
                                </IonItem>
                            </IonCol>
                        </IonRow>
                        <IonRow className="ion-align-items-center">
                            <IonCol size="4">
                                <IonLabel>State</IonLabel>
                            </IonCol>
                            <IonCol>
                                <IonItem>
                                    <IonInput
                                        type="text"
                                        placeholder={state}
                                        value={state}
                                        name="state"
                                        // onIonChange={(e) => {
                                        //     setState(e.detail.value);
                                        // }}
                                        readonly
                                    />
                                </IonItem>
                            </IonCol>
                        </IonRow>
                        <IonRow className="ion-align-items-center">
                            <IonCol size="4">
                                <IonLabel>ZIP</IonLabel>
                            </IonCol>
                            <IonCol>
                                <IonItem>
                                    <IonInput
                                        placeholder={ZIP}
                                        type="text"
                                        value={ZIP}
                                        name="ZIP"
                                        // onIonChange={(e) => {
                                        //     setZIP(e.detail.value);
                                        // }}
                                        readonly
                                    />
                                </IonItem>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                    {/* <div className="ion-text-center">
                        <IonButton shape="round" type="submit">
                            Save
                        </IonButton>
                    </div> */}
                    <div className="ion-text-center">
                        <IonButton shape="round">
                            <IonBackButton 
                                text="Back" 
                                defaultHref="/my/settings"
                                icon={null}
                            />
                        </IonButton>
                    </div>
                </form>
                {/* <IonLoading
                    isOpen={
                        loading === true ||
                        basicInfoLoading === true ||
                        setBasicInfoResponseLoading === true
                    }
                    message="Please Wait"
                ></IonLoading> */}
                {/* <IonLoading
                    isOpen={currentUser === undefined}
                    message="Please Wait"
                ></IonLoading> */}
            </IonContent>
        </IonPage>
    );
};

export default BasicInfo;
