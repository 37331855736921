import { IonButton, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonItem, IonModal, IonRow, IonText, IonTitle, IonToast } from '@ionic/react';
import {
    closeOutline,
    call,
    mail,
    copyOutline,
    callOutline,
    mailOutline
} from 'ionicons/icons';
import { Clipboard } from "@capacitor/clipboard";
import React, { useState } from 'react';
import { Share } from '@capacitor/share';


export const ContactButton = (props: any) => {
    const [showModal, setShowModal] = useState(false);
    const [showCodeToast, setShowCodeToast] = useState(false)

    const copyToClipboard = async (data: string) => {
        await Clipboard.write({
            string: `${data}`,
        });
    };

    const shareOrder = async () => {
        await Share.share({
            title: 'Order Details',
            text: 'text textg'
        });
    }

    return (
        <>
         <IonButton size='small' fill='outline' onClick={e=> setShowModal(true)}>
          {props.btn === 'Call'? 
            <IonIcon slot="icon-only" size="small" icon={call}></IonIcon >
             : 
            <IonIcon slot="icon-only" size="small" icon={mail}></IonIcon>} 
         </IonButton>
            <IonModal id={"sharemodal"} isOpen={showModal} onDidDismiss={e => setShowModal(false)}>
               <IonHeader>
                 <IonItem lines="none">
                    <IonTitle>Contact</IonTitle>
                    <IonButton fill='clear'color='primary' slot='end'  onClick={e=> setShowModal(false)} >
                        <IonIcon slot="icon-only" icon={closeOutline}  ></IonIcon> 
                    </IonButton>
                  </IonItem>
               </IonHeader>
               <IonContent style={{height: 40+'vh'}} className="ion-padding">
                    <IonGrid>
                        <IonRow>
                        <IonCol>
                        {props.btn === 'Call'?
                        
                            <IonButton fill='clear' href={`tel: ${props.phone}`}>
                              <IonIcon  size="large" color="success" icon={callOutline}></IonIcon>  
                              <IonText color='dark'>&nbsp; {props.btn} </IonText>
                            </IonButton>
                    
                        :<IonButton fill='clear' onClick={(e) => shareOrder()}>
                            <IonIcon  size="large" color="success" icon={mailOutline}></IonIcon>  
                            <IonText color='dark'>&nbsp; {props.btn} </IonText>
                         </IonButton>
                        }
                        </IonCol>
                            <IonCol>
                        <IonButton fill='clear' onClick={(e) => {
                            copyToClipboard( props.data);
                            setShowCodeToast(true)
                        }}>
                            <IonIcon size="large" color="primany" icon={copyOutline}></IonIcon>
                            <IonText color='dark'>&nbsp; Copy</IonText>
                        </IonButton>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                    
                   
                </IonContent>
           </IonModal>
   
           <IonToast
           isOpen={showCodeToast}
           onDidDismiss={() => setShowCodeToast(false)}
           message="Copied"
           duration={500}
       />
       
        </>

    )
}
