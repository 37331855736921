import { IonContent, IonPage } from "@ionic/react";
import React from "react";
import Header from "../../components/dashboard/Header"

const Features = () =>{
    return (
        <IonPage>
            <IonContent>
                <Header name="Features" />
                <iframe className="ion-padding ion-margin" src="https://feedback.userreport.com/ba4ea186-91bd-404c-87bc-40277321f02e/#ideas/popular" style={{width:"95%", height:"84%"}} frameBorder="0"></iframe>
            </IonContent>
        </IonPage>
        
    );

}

export default Features;