import { IonAlert, IonButton, IonCol, IonContent, IonFooter, IonGrid, IonIcon, IonLoading, IonPage, IonRow, IonText } from "@ionic/react";
import React, { useEffect, useState } from "react";
import {
    trash as trashIcon,
    createOutline as createIcon,
    arrowForwardOutline as rightArrowIcon,
    arrowBackOutline as leftArrowIcon,
    logoWhatsapp,
    mailOutline
} from "ionicons/icons";
import Header from "../../components/dashboard/Header";
import { getAccessToken } from "../../services/auth/userLocalStorage";
import { API_ROOT } from "../../constants/global";
import { useHistory } from "react-router";
import { getHomeScreenDataWithDays } from "../../actions/dashboardActions";
import { useSelector } from "react-redux";

const Leads = (props) => {

    const [details, setDetails] = useState([]);
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertData, setAlertData] = useState<any>();
    const [nextPage, setNextPage] = useState();
    const [previousPage, setPreviousPage] = useState();
    const {
        homeScreenWithDaysLoading,
        homeScreenWithDays,
        homeScreenWithDaysError,
    } = useSelector((state: any) => state.homeScreenDataWithDays);
    const token = getAccessToken();

    const whatsappText = encodeURIComponent(`Hey there, I noticed that you are looking to launch ecommerce website with mobile app. You don't need to invest in lacks to to make your dream come true. Start with Intelikart and grow online.
    Download the app and launch your online store in 2 mins - https://play.google.com/store/apps/details?id=com.intelikart&referrer=${homeScreenWithDaysLoading === false && homeScreenWithDays && homeScreenWithDays.data ? homeScreenWithDays.data.unique_code : ""}`);

    const mailSubject = "Build your dream ecommerce website and mobile app";
    const mailBody = encodeURIComponent(`Hey there, 
I noticed that you are looking to launch an ecommerce website with a mobile app. 
You don't need to invest in lacks to to make your dream come true. Start with Intelikart and grow online.
Download the app and launch your online store in 2 mins - https://play.google.com/store/apps/details?id=com.intelikart&referrer=${homeScreenWithDaysLoading === false && homeScreenWithDays && homeScreenWithDays.data ? homeScreenWithDays.data.unique_code : ""}
  
You can start on web as well - https://web.intelikart.com?ref=${homeScreenWithDaysLoading === false && homeScreenWithDays && homeScreenWithDays.data ? homeScreenWithDays.data.unique_code : ""}
    
For any queries, please reply to this email or connect with me over whatsapp - this would be a link to partner mobile number
    
Regards,
${token?.profile?.partner_name}`);

    useEffect(() => {
        const token = getAccessToken();
        setLoading(true);
        const fetchData = async () => {
            const response = await fetch(`https://api2.intelikart.in/partners/leads`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    authorization: `Bearer ${token.token}`,
                },
            });
            const data = await response.json();
            if (data !== undefined) {
                setDetails(data?.data);
                setNextPage(data?.meta.pagination.links.next);
                setPreviousPage(data?.meta.pagination.links.previous);
            }
        };
        setLoading(false);
        fetchData();
    }, []);

    // const onCouponDeleteClick = async (value: any) => {
    //     const token = getAccessToken();
    //     const response = await fetch(`${API_ROOT}partners/leads/${value.id}`, {
    //         method: "DELETE",
    //         headers: {
    //             "content-type": "application/json",
    //             authorization: `Bearer ${token.token}`,
    //         }
    //     });
    //     window.location.reload();
    //     const data = await response.json();
    // }

    const handleNextPageClick = async () => {
        const token = getAccessToken();
        setLoading(true);
        let response = await fetch(nextPage, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                authorization: `Bearer ${token.token}`,
            },
        });

        response.json().then(async (res) => {
            if (res && res.data && res.data.length > 0) {
                setDetails(res?.data);
                setNextPage(res?.meta.pagination.links.next);
                setPreviousPage(res?.meta.pagination.links.previous);
            }
        }).catch(err => console.error(err));
        setLoading(false);
    }

    const handlePreviousPageClick = async () => {
        const token = getAccessToken();
        setLoading(true);
        let response = await fetch(previousPage, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                authorization: `Bearer ${token.token}`,
            },
        });

        response.json().then(async (res) => {
            if (res && res.data && res.data.length > 0) {
                setDetails(res?.data);
                setNextPage(res?.meta.pagination.links.next);
                setPreviousPage(res?.meta.pagination.links.previous);
            }
        }).catch(err => console.error(err));
        setLoading(false);
    }

    return (
        <IonPage>
            <Header name="" />
            <IonContent>
                <IonGrid className="ion-no-padding ion-no-margin"
                    style={{ color: "#353535" }}>
                    <IonRow className="ionrow-style ion-align-items-center ion-text-center"
                        style={{ padding: 10 + "px" }}>
                        <IonCol className="ioncol-style" size="2">
                            <b>Name</b>
                        </IonCol>
                        <IonCol className="ioncol-style" size="1">
                            <b>About</b>
                        </IonCol>
                        <IonCol className="ioncol-style" size="2.5">
                            <b>Phone</b>
                        </IonCol>
                        <IonCol className="ioncol-style" size="3">
                            <b>Email</b>
                        </IonCol>
                        <IonCol className="ioncol-style" size="2">
                            <b>Address</b>
                        </IonCol>
                        <IonCol className="ioncol-style" size="1">
                            <b>Pin</b>
                        </IonCol>
                    </IonRow>
                    {details?.map((row) => {
                        return (
                            <IonRow
                                key={row.id}
                                className="ionrow-style ion-align-items-center ion-text-center"
                            >
                                <IonCol className="ioncol-style" size="2" >
                                    {row.name}
                                </IonCol>
                                <IonCol className="ioncol-style" size="1" >
                                    {row.about}
                                </IonCol>
                                <IonCol className="ioncol-style ion-text-left" size="2.5" >

                                    {row.phone ? <IonButton
                                        fill="clear"
                                        color="secondary"
                                        href={`https://wa.me/${row.phone}?text=${whatsappText}`}
                                    >
                                        <IonIcon
                                            slot="end"
                                            size="large"
                                            icon={logoWhatsapp}
                                        ></IonIcon>
                                    </IonButton> : null}
                                    {row.phone}
                                </IonCol>
                                <IonCol className="ioncol-style ion-text-left" size="3" >

                                    {row.email ? <IonButton
                                        fill="clear"
                                        color="secondary"
                                        //href = {`https://mail.google.com/mail/?mailto:${row.email}?subject=${mailSubject} Subject&body=${mailBody} of email`}
                                        href={`https://mail.google.com/mail/?view=cm&fs=1&to=${row.email}&su=${mailSubject}&body=${mailBody}`}
                                    >
                                        <IonIcon
                                            slot="end"
                                            size="large"
                                            icon={mailOutline}
                                        ></IonIcon>
                                    </IonButton> : null}
                                    {row.email}
                                </IonCol>
                                <IonCol className="ioncol-style" size="2" >
                                    {row.address}
                                </IonCol>
                                <IonCol className="ioncol-style" size="1" >
                                    {row.zip}
                                </IonCol>

                                {/* <IonCol size="0.5">
                                    <IonButton
                                    fill="clear"
                                    color="secondary"
                                    onClick={() => { history.push(`/my/createleads/${row.id}`) }}
                                    >
                                    <IonIcon
                                        slot="icon-only"
                                        size="large"
                                        icon={logoWhatsapp}
                                    ></IonIcon>
                                    </IonButton>
                                </IonCol> */}

                                {/* <IonCol size="0.5">
                                    <IonButton
                                    fill="clear"
                                    color="secondary"
                                    onClick={() => { history.push(`/my/createleads/${row.id}`) }}
                                    >
                                    <IonIcon
                                        slot="icon-only"
                                        size="large"
                                        icon={mailOutline}
                                    ></IonIcon>
                                    </IonButton>
                                </IonCol> */}

                                <IonCol size="0.5">
                                    <IonButton
                                        fill="clear"
                                        color="secondary"
                                        onClick={() => { history.push(`/my/createleads/${row.id}`) }}>
                                        <IonIcon
                                            slot="icon-only"
                                            size="large"
                                            icon={createIcon}
                                        ></IonIcon>
                                    </IonButton>
                                </IonCol>

                                {/* <IonCol size="0.5">
                                    <IonButton
                                        fill="clear"
                                        color="secondary"
                                        onClick={(e) => {
                                            setAlertData(row);
                                            setShowAlert(true);
                                        }}>
                                        <IonIcon
                                            slot="icon-only"
                                            size="large"
                                            icon={trashIcon}
                                        ></IonIcon>
                                    </IonButton>
                                </IonCol> */}
                            </IonRow>
                        )
                    })}
                </IonGrid>
                <IonLoading
                    isOpen={
                        loading === true
                    }
                    message="Please Wait"
                ></IonLoading>

                {/* <IonAlert
                    isOpen={showAlert === true}
                    onDidDismiss={() => setShowAlert(false)}
                    header={"Delete Lead"}
                    message={`You are about to delete Lead ${alertData && alertData.name ? alertData.name : ""
                        }`}
                    buttons={[
                        {
                            text: "CANCEL",
                            role: "cancel",
                        },
                        {
                            text: "DELETE",
                            handler: () => {
                                onCouponDeleteClick(alertData);
                            },
                        },
                    ]}
                /> */}

            </IonContent>


            <IonFooter>
                <IonGrid>
                    <IonRow>

                        {previousPage ? <IonCol>
                            <div className="ion-text-left">
                                <IonButton fill="outline" onClick={() => handlePreviousPageClick()}>
                                    <IonIcon
                                        slot="start"
                                        size="medium"
                                        icon={leftArrowIcon}
                                    ></IonIcon>
                                    Previous
                                </IonButton>
                            </div>
                        </IonCol> : null}


                        <IonCol className="ion-align-items-center">
                            <div className="ion-text-center">
                                <IonButton shape="round" routerLink="/my/createleads">
                                    Add Leads
                                </IonButton>
                            </div>
                        </IonCol>


                        {nextPage ? <IonCol>
                            <div className="ion-text-right">
                                <IonButton fill="outline" onClick={() => handleNextPageClick()}>
                                    Next
                                    <IonIcon
                                        slot="end"
                                        size="medium"
                                        icon={rightArrowIcon}
                                    ></IonIcon>
                                </IonButton>
                            </div>
                        </IonCol> : null}


                    </IonRow>
                </IonGrid>
            </IonFooter>
        </IonPage>
    )

}

export default Leads;
