import { IonContent, IonPage } from "@ionic/react";

import React from "react";
import Header from "../dashboard/Header";
import MyTable from "./MyTable";

const Stores = () => {
    return (
        <IonPage>
            <Header name="" />
            <MyTable />
        </IonPage>
    );
};

export default Stores;
