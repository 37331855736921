import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from "react-router-dom";
import { 
    IonContent, 
    IonPage, 
    IonItem, 
    IonLabel, 
    IonButton, 
    IonInput,
    IonText,
    IonLoading,
    IonCol,
    IonRow,
    useIonViewDidEnter,
    IonGrid,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonButtons,
    IonBackButton
} from '@ionic/react';
import LogoImage from '../components/auth/LogoImage';
import LoginFooter from '../components/auth/LoginFooter';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentUser, getEmailOTP, getUserTokens } from '../actions/authActions';
import { setTokensObject } from '../services/auth/userLocalStorage';
import { isDesktop } from 'react-device-detect';

const SignUpWithEmailOTP = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const[email,setEmail]=useState('');
    const[loading,setLoading]=useState(false)
    const[isCredentialsValidate,setIsCredentialsValidate]=useState(true)
    const[resendButtonDisabledTime,setResendButtonDisabledTime]=useState(0);

    const [otpArray, setOtpArray] = useState(['', '', '', '']);
    const firstTextInputRef = useRef(null);
	const secondTextInputRef = useRef(null);
	const thirdTextInputRef = useRef(null);
	const fourthTextInputRef = useRef(null);

    const { userAPITokensLoading, userAPITokens, userAPITokensError } = useSelector((state : any)=> state.userAPITokens);
    const { currentUserLoading, currentUser, currentUserError } = useSelector((state : any)=> state.currentUser);
    

    useEffect(() => {
        if(userAPITokensLoading === false){ 
            if(userAPITokens){
                setTokensObject(userAPITokens);
                loadCurrentUser(userAPITokens.access_token);
            }
            if(userAPITokensError){
                setIsCredentialsValidate(false)
                console.log("some error occured in home component user api call.");
                console.log(userAPITokensError);
            }
        }
        
    }, [userAPITokens, userAPITokensError ]);

    useEffect(() => {
        if(currentUserLoading === false){
            if(currentUser && currentUser.data){
                    //if(currentUser.data[0].store.storetype_id !== 0)
                        //history.push({pathname:'/my/userdashboard'});
                    //else
                        //history.push({pathname:'/getbusinessdetails'});
            }
            if(currentUserError){
                console.log("error occured in home component current user call")
                console.log(currentUserError)
            }
        }
    }, [currentUserLoading, currentUser, currentUserError]);

    const onOtpChange = index => {
        return e => {
            //console.log(e)
            if (isNaN(Number(e.detail.value))) {
            // do nothing when a non digit is pressed
             
            return;
            }
            const otpArrayCopy = otpArray.concat();
            otpArrayCopy[index] = e.detail.value;
            setOtpArray(otpArrayCopy);
            if(otpArrayCopy[index]!=="" && index===0){
            setTimeout(() => secondTextInputRef.current.setFocus(), 50)
            }
            else if(otpArrayCopy[index]!=="" && index===1){
            setTimeout(() =>thirdTextInputRef.current.setFocus(), 50)
            }
            else if(otpArrayCopy[index]!=="" && index===2){
            setTimeout(() => fourthTextInputRef.current.setFocus(), 50)
            }
        } 
    }
    let resendOtpTimerInterval;
	const startResendOtpTimer = () => {
		if (resendOtpTimerInterval) {
		  clearInterval(resendOtpTimerInterval);
		}
		resendOtpTimerInterval = setInterval(() => {
		  if (resendButtonDisabledTime <= 0) {
			clearInterval(resendOtpTimerInterval);
		  } else {
			setResendButtonDisabledTime(resendButtonDisabledTime - 1);
		  }
		}, 1000);
	  };

	useEffect(() => {
	startResendOtpTimer();

	return () => {
		if (resendOtpTimerInterval) {
		clearInterval(resendOtpTimerInterval);
		}
	};
	}, [resendButtonDisabledTime]);


    const loadCurrentUser = async (token: string)=>{
        await dispatch(getCurrentUser(token));
    }
    const sendOTP=async(e)=>{
        e.preventDefault();
        setResendButtonDisabledTime(60)
        setTimeout(() => firstTextInputRef.current.setFocus(), 100)
        await dispatch(getEmailOTP(email));
       
    }
    const verifyOTP=async(e)=>{
        e.preventDefault();
        const formData = new FormData();
        formData.append('username',email);
        formData.append('password',otpArray.join(""));
        formData.append('grant_type',"password");
        formData.append('client_id', "6");
        formData.append('client_secret',"crm-45eurtdfykg978ilyukt897td");
        formData.append('scope','store')
        await dispatch(getUserTokens(formData,"email"));
    }

    const firstNameInputRef = useRef<HTMLIonInputElement>(null);
  useIonViewDidEnter(() => {
    firstNameInputRef.current?.setFocus();
  });

  let classOfGrid = "";
    if(isDesktop){
        classOfGrid = "center-grid";
    }

    return (
        <IonPage>
            <IonHeader>
            <IonToolbar>
            <IonButtons slot="start">
          <IonBackButton defaultHref="/" />
        </IonButtons>
            <IonTitle>Login With Email</IonTitle>
            </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding ion-text-center">
            <IonGrid  className={classOfGrid}>
                    <IonRow>
                        <IonCol>
            <div className="iklogo">
                <LogoImage/>
                </div>
                {/* <IonText>
                    <h4 style={{paddingTop: 5+'vh', paddingBottom: 5+'vh'}}>Login With Email</h4>
                </IonText> */}
                <form  className="ion-padding" onSubmit={sendOTP}>
                
               
                <IonItem>
                <IonLabel position="stacked">Email</IonLabel>
                    <IonInput ref={firstNameInputRef} required autofocus={true} value={email} type="email" onIonChange={e=>setEmail(e.detail.value)} placeholder="example@email.com" className="ion-text-center" />
                </IonItem>
                {resendButtonDisabledTime?
						<p className="ion-text-center" style={{color:"#4286f5"}}>
							Resend OTP in <b>{resendButtonDisabledTime}s</b>
						</p>
						:
						<IonButton className="ion-margin-top" type='submit'>Send OTP</IonButton>
				}
                </form>
                <form onSubmit={verifyOTP} >
                <br/>
                <IonLabel>Enter OTP sent on your email</IonLabel>
                <IonRow >
                    <IonCol>
                        <IonInput style={{borderBottom:"1px solid",fontSize:"25px",textAlign:"center"}} value={otpArray[0]} onIonChange={onOtpChange(0)}
                        ref={firstTextInputRef} required={ true } type="tel" maxlength={1} className="ion-padding-horizontal">
                        </IonInput>
                    </IonCol>
                    <IonCol>
                        <IonInput style={{borderBottom:"1px solid",fontSize:"25px",textAlign:"center"}} value={otpArray[1]} onIonChange={onOtpChange(1)}
                        ref={secondTextInputRef} required={ true } type="tel" maxlength={1} className="ion-padding-horizontal">
                        </IonInput>
                    </IonCol>
                    <IonCol>
                        <IonInput style={{borderBottom:"1px solid",fontSize:"25px",textAlign:"center"}} value={otpArray[2]} onIonChange={onOtpChange(2)}
                        ref={thirdTextInputRef} required={ true } type="tel" maxlength={1} className="ion-padding-horizontal">
                        </IonInput>
                    </IonCol>
                    <IonCol>
                        <IonInput style={{borderBottom:"1px solid",fontSize:"25px",textAlign:"center"}} value={otpArray[3]} onIonChange={onOtpChange(3)}
                        ref={fourthTextInputRef} required={ true } type="tel" maxlength={1} className="ion-padding-horizontal">
                        </IonInput>
                    </IonCol>

				</IonRow>
					
                <IonText color="danger" className={`${isCredentialsValidate === true   ? "ion-hide": "" }`}>
                    <h5 style={{fontSize:12+'px', marginTop: 5+'px'}} className={`ion-margin-horizontal `}> {currentUserError?.error}</h5> 
                </IonText>
                <IonButton  className="ion-margin-top"  type="submit">Verify Now </IonButton>
                </form>
                </IonCol>
                </IonRow>
                </IonGrid>
            </IonContent>
            <IonLoading
                isOpen={loading || userAPITokensLoading}
                message={'Please Wait...'}
            />
            <LoginFooter/>
        </IonPage>
    )
}

export default SignUpWithEmailOTP
