import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonGrid,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonLoading,
    IonPage,
    IonRow,
    IonText,
    isPlatform,
} from "@ionic/react";
import React, { useEffect, useRef, useState } from "react";
import Header from "../../components/dashboard/Header";
import { Camera, CameraResultType } from "@capacitor/camera";
import {
    getLandingPages,
    setLandingPages,
    getSubdomainAvailability,
} from "../../actions/dashboardActions";
import { getAccessToken } from "../../services/auth/userLocalStorage";
import { useDispatch, useSelector } from "react-redux";
import { checkmarkDone } from "ionicons/icons";
import { API_ROOT } from "../../constants/global";

async function savePicture(file) {
    if (!file) return ""
    try {
        const formData = new FormData();
        formData.append("file", file)
        const response = await fetch(`${API_ROOT}upload/logo-image`, {
            method: 'POST',
            body: formData
        });
        const data = await response.json();
        console.log(data);
        return data.platform_path;
    }
    catch (error) {
        console.log(error)
        return ""
    }
}

const LandingPages = () => {
    const [loading, setLoading] = useState(false);
    const [name, setName] = useState<any>();
    const [whatsapp, setWhatsapp] = useState<any>();
    const [phone, setPhone] = useState<any>();
    const [email, setEmail] = useState<any>();
    const [address, setAddress] = useState<any>();
    const [title, setTitle] = useState<any>();
    const [domain, setDomain] = useState<any>();
    const [facebook, setFacebook] = useState<any>();
    const [instagram, setInstagram] = useState<any>();
    const [twitter, setTwitter] = useState<any>();
    const [linkedin, setLinkedin] = useState<any>();

    const [isDomainEmpty, setIsDomainEmpty] = useState(false);
    const [isLogoEmpty, setIsLogoEmpty] = useState(false);
    const [isNameEmpty, setIsNameEmpty] = useState(false);
    const [isPhoneEmpty, setIsPhoneEmpty] = useState(false);
    const [isAddressEmpty, setIsAddressEmpty] = useState(false);
    const [isTitleEmpty, setIsTitleEmpty] = useState(false);

    const [isDomainValid, setIsDomainValid] = useState(true);
    const [isNameValid, setIsNameValid] = useState(true);
    const [isPhoneValid, setIsPhoneValid] = useState(true);
    const [isWhatsappValid, setIsWhatsappValid] = useState(true);
    const [isAddressValid, setIsAddressValid] = useState(true);
    const [isEmailValid, setIsEmailValid] = useState(true);
    const [isTitleValid, setIsTitleValid] = useState(true);

    const [isSubdomainAvailable, setIsSubdomainAvailable] = useState<any>();
    const [subdomainAccess, setSubdomainAccess] = useState<any>(true);

    const [pictureUrl, setPictureUrl] = useState("https://cdn.intelikart.com/uploads/new-images/default-store.png");
    const [imageFile, setImageFile] = useState(null);
    const fileInputRef = useRef<HTMLInputElement>();
    const dispatch = useDispatch();
    const { landingPagesLoading, landingPages, landingPagesError } = useSelector((state: any) => state.landingPages);
    const {
        setLandingPagesLoadingResponse,
        setLandingPagesResponse,
        setLandingPagesErrorResponse,
    } = useSelector((state: any) => state.setLandingPages);
    const {
        subDomainLoading,
        subDomain,
        subDomainError,
    } = useSelector((state: any) => state.subdomainAvailability);
    const { currentUser } = useSelector((state: any) => state.currentUser);

    useEffect(() => {
        dispatch(getLandingPages(getAccessToken().token));
    }, [currentUser]);

    useEffect(() => {
        if (landingPagesLoading === false && landingPages && landingPages.data) {
            setName(landingPages.data?.name);
            setPhone(landingPages.data?.mobile_number);
            setAddress(landingPages.data?.address);
            setTitle(landingPages.data?.page_title);
            landingPages.data.subdomain && setDomain(landingPages.data.subdomain.replace(".thesite.co.in", ""));
            setImageFile(landingPages.data?.logo);
            setWhatsapp(landingPages.data?.whatsapp_number);
            setEmail(landingPages.data?.email_contact);
            setFacebook(landingPages.data?.social_facebook);
            setTwitter(landingPages.data?.social_twitter);
            setInstagram(landingPages.data?.social_instagram);
            setLinkedin(landingPages.data?.social_linkedin);
            landingPages.data.subdomain ? setSubdomainAccess(false) : setSubdomainAccess(true);
            if(landingPages.data && landingPages.data.logo) setPictureUrl(`https://cdn.intelikart.com/uploads/${landingPages.data.logo}`)
        }
    }, [landingPages]);

    useEffect(() => {
        domainValidation();
    }, [domain]);

    const nameValidation = () => {
        let result = true;
        if (!name) {
            setIsNameEmpty(true);
            result = false;
        } else {
            setIsNameEmpty(false);
            if (/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(name)) {
                setIsNameValid(false);
                result = false;
            }
            else
                setIsNameValid(true);
        }
        return result;
    }

    const domainEmpty = async ()  => {
        let result = true;
        if (!domain) {
            setIsDomainEmpty(true);
            result = false;
        }
        return result;
    }

    const domainValidation = async ()  => {
        const accessToken = await getAccessToken();
        let result = true;
        setIsDomainValid(true);
        setIsSubdomainAvailable(true);
        if(domain) {
            setIsDomainEmpty(false);

            if ((/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(domain)) || domain.length < 3) {
                setIsDomainValid(false);
                result = false;
            }
            else {
                setIsDomainValid(true);
                const response = await fetch(`${API_ROOT}partners/subdomain-availability/${domain}.thesite.co.in`,
                    {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            authorization: `Bearer ${accessToken.token}`,
                        },
                    }
                );
                const message = await response.json();
                let isAvailable =
                    message.result === "available" ? true : false;
                if (isAvailable === false) {
                    setIsSubdomainAvailable(false);
                    result = false;
                } else setIsSubdomainAvailable(true);
            }
        }

        return result;
    }

    const phoneValidation = () => {
        let result = true;
        if (!phone) {
            setIsPhoneEmpty(true);
            result = false;
        } else {
            setIsPhoneEmpty(false);
            if (/\D/.test(phone)) {
                setIsPhoneValid(false);
                result = false;
            }
            else {
                setIsPhoneValid(true);
                if (phone.length !== 10) {
                    setIsPhoneValid(false);
                    result = false;
                } else
                    setIsPhoneValid(true);
            }
        }
        return result;
    }

    const addressValidation = () => {
        let result = true;
        if (!address) {
            setIsAddressEmpty(true);
            result = false;
        } else {
            setIsAddressEmpty(false);
            if (/[`!@#$%^*+\=\[\]{};':"\\|.<>\/?~]/.test(address)) {
                setIsAddressValid(false);
                result = false;
            }
            else
                setIsAddressValid(true);
        }
        return result;
    }

    const titleValidation = () => {
        let result = true;
        if (!title) {
            setIsTitleEmpty(true);
            result = false;
        } else {
            setIsTitleEmpty(false);
            if (/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(title)) {
                setIsTitleValid(false);
                result = false;
            }
            else
                setIsTitleValid(true);
        }
        return result;
    }

    const whatsappValidation = () => {
        let result = true;
        if (whatsapp) {
            if (/\D/.test(whatsapp)) {
                setIsWhatsappValid(false);
                result = false;
            }
            else {
                setIsWhatsappValid(true);
                if (whatsapp.length !== 10) {
                    setIsWhatsappValid(false);
                    result = false;
                } else
                    setIsWhatsappValid(true);
            }
        } else {
            setIsWhatsappValid(true);
        }
        return result;
    }

    const emailValidation = () => {
        let result = true;
        if (email) {
            if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
                setIsEmailValid(false)
                result = false;
            } else {
                setIsEmailValid(true)
            }
        } else {
            setIsEmailValid(true)
        }
        return result;
    }

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files.length > 0) {
            const file = event.target.files.item(0);
            setImageFile(file);
            const pictureUrl = URL.createObjectURL(file);
            setPictureUrl(pictureUrl);
        }
    };
    const handlePictureClick = async () => {
        if (isPlatform("capacitor")) {
            try {
                const photo = await Camera.getPhoto({
                    resultType: CameraResultType.Uri,
                });
                setPictureUrl(photo.webPath);
            } catch (error) {
                console.log(error);
            }
        } else {
            fileInputRef.current.click();
        }
    };

    const logoValidation = (path) => {
        let result = true;
        if (!path) {
            setIsLogoEmpty(true);
            result = false;
        } else {
            setIsLogoEmpty(false);
        }
        console.log('image')
        return result;
    };

    // const checkAvailability = async (e : any) => {
    //     const fullDomain = e+".thesite.co.in";
    //     Promise.all([
    //         await dispatch(getSubdomainAvailability(getAccessToken().token, fullDomain))
    //     ])

    //     if (subDomain.result === "not-available")
    //         setIsSubdomainAvailable(false);
    //     else
    //         setIsSubdomainAvailable(true);

    //     console.log(subDomain.result);
    // }

    const submitHandler = async (e: any) => {
        e.preventDefault();
        setLoading(true);

        const res1 = await nameValidation();
        const res2 = await domainValidation();
        const res3 = await phoneValidation();
        const res4 = await addressValidation();
        const res5 = await titleValidation();
        const res6 = await whatsappValidation();
        const res7 = await emailValidation();
        const res8 = await domainEmpty();

        let platform_path = await savePicture(imageFile)
        console.log(platform_path);
        if (!platform_path) platform_path = landingPages.data.logo

        // check for image
        const res9 = await logoValidation(platform_path);

        if (res1 && res2 && res3 && res4 && res5 && res6 && res7 && res8 && res9) {
            
            Promise.all([await dispatch(setLandingPages(getAccessToken().token, {
                subdomain: `${domain}.thesite.co.in`,
                logo: platform_path,
                name: `${name}`,
                mobile_number: `${phone}`,
                whatsapp_number: whatsapp ? `${whatsapp}` : undefined,
                email_contact: email ? `${email}` : undefined,
                address: `${address}`,
                social_facebook: facebook ? `${facebook}` : undefined,
                social_twitter: twitter ? `${twitter}` : undefined,
                social_instagram: instagram ? `${instagram}` : undefined,
                social_linkedin: linkedin ? `${linkedin}` : undefined,
                page_title: `${title}`
            })), dispatch(getLandingPages(getAccessToken().token))]);
        }
        setLoading(false);
    }

    return (
        <IonPage>
            <Header name="" />
            <IonContent className="ion-padding">
                <form onSubmit={submitHandler}>
                    <IonCard className="ion-no-margin ion-on-padding">
                        <IonItem lines="none">
                            {/* <IonIcon size="small" icon={person}></IonIcon> */}
                            <IonText><h5>Personal Details</h5></IonText>
                        </IonItem>
                    </IonCard>
                    <IonRow className="ion-align-items-center">
                        <IonCol size="4">
                            <IonLabel>Name</IonLabel>
                        </IonCol>
                        <IonCol>
                            <IonItem>
                                <IonInput
                                    placeholder="Enter Name"
                                    type="text"
                                    name="name"
                                    value={name}
                                    onIonChange={(e) => { setName(e.detail.value) }}
                                />
                            </IonItem>
                            <IonText color="danger" className={`${isNameEmpty === false && isNameValid === true ? "ion-hide" : ""}`}>
                                <h5 style={{ fontSize: 12 + 'px' }} className={`ion-margin-horizontal ${isNameEmpty ? "" : "ion-hide"}`}>Name is required.</h5>
                                <h5 style={{ fontSize: 12 + 'px' }} className={`ion-margin-horizontal ${isNameValid ? "ion-hide" : ""}`}>Name is not valid.</h5>
                            </IonText>
                        </IonCol>
                    </IonRow>

                    <IonRow className="ion-align-items-center">
                        <IonCol size="4">
                            <IonLabel>Contact No.</IonLabel>
                        </IonCol>
                        <IonCol>
                            <IonItem>
                                <IonInput
                                    placeholder="Enter Contact No."
                                    type="text"
                                    name="phone"
                                    value={phone}
                                    onIonChange={(e) => { setPhone(e.detail.value) }}
                                />
                            </IonItem>
                            <IonText color="danger" className={`${isPhoneEmpty === false && isPhoneValid === true ? "ion-hide" : ""}`}>
                                <h5 style={{ fontSize: 12 + 'px' }} className={`ion-margin-horizontal ${isPhoneEmpty ? "" : "ion-hide"}`}>Contact No. is required.</h5>
                                <h5 style={{ fontSize: 12 + 'px' }} className={`ion-margin-horizontal ${isPhoneValid ? "ion-hide" : ""}`}>Contact No. is not valid.</h5>
                            </IonText>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-align-items-center">
                        <IonCol size="4">
                            <IonLabel>WhatsApp No.</IonLabel>
                        </IonCol>
                        <IonCol>
                            <IonItem>
                                <IonInput
                                    placeholder="Enter WhatsApp No."
                                    type="text"
                                    name="whatsapp"
                                    value={whatsapp}
                                    onIonChange={(e) => { setWhatsapp(e.detail.value) }}
                                />
                            </IonItem>
                            <IonText color="danger" className={`${isWhatsappValid === true ? "ion-hide" : ""}`}>
                                <h5 style={{ fontSize: 12 + 'px' }} className={`ion-margin-horizontal ${isWhatsappValid ? "ion-hide" : ""}`}>WhatsApp No. is not valid.</h5>
                            </IonText>
                        </IonCol>
                    </IonRow>

                    <IonRow className="ion-align-items-center">
                        <IonCol size="4">
                            <IonLabel>Contact Email</IonLabel>
                        </IonCol>
                        <IonCol>
                            <IonItem>
                                <IonInput
                                    placeholder="Enter Contact Email"
                                    type="email"
                                    name="email"
                                    value={email}
                                    onIonChange={(e) => { setEmail(e.detail.value) }}
                                />
                            </IonItem>
                            <IonText color="danger" className={`${isEmailValid === true ? "ion-hide" : ""}`}>
                                <h5 style={{ fontSize: 12 + 'px' }} className={`ion-margin-horizontal ${isEmailValid ? "ion-hide" : ""}`}>Email is not valid.</h5>
                            </IonText>
                        </IonCol>
                    </IonRow>

                    <IonRow className="ion-align-items-center">
                        <IonCol size="4">
                            <IonLabel>Address</IonLabel>
                        </IonCol>
                        <IonCol>
                            <IonItem>
                                <IonInput
                                    placeholder="Enter Address"
                                    type="text"
                                    name="address"
                                    value={address}
                                    onIonChange={(e) => { setAddress(e.detail.value) }}
                                />
                            </IonItem>
                            <IonText color="danger" className={`${isAddressEmpty === false && isAddressValid === true ? "ion-hide" : ""}`}>
                                <h5 style={{ fontSize: 12 + 'px' }} className={`ion-margin-horizontal ${isAddressEmpty ? "" : "ion-hide"}`}>Address is required.</h5>
                                <h5 style={{ fontSize: 12 + 'px' }} className={`ion-margin-horizontal ${isAddressValid ? "ion-hide" : ""}`}>Address is not valid.</h5>
                            </IonText>
                        </IonCol>
                    </IonRow>
                    <br />

                    <IonCard className="ion-no-margin ion-on-padding">
                        <IonItem lines="none">
                            {/* <IonIcon size="small" icon={person}></IonIcon> */}
                            <IonText><h5>Page Details</h5></IonText>
                        </IonItem>
                    </IonCard>

                    <br />

                    <IonRow className="ion-align-items-center">
                        <IonCol size="4">
                            <IonLabel>Page Title</IonLabel>
                        </IonCol>
                        <IonCol>
                            <IonItem>
                                <IonInput
                                    placeholder="Enter Title"
                                    type="text"
                                    name="title"
                                    value={title}
                                    onIonChange={(e) => { setTitle(e.detail.value) }}
                                />
                            </IonItem>
                            <IonText color="danger" className={`${isTitleEmpty === false && isTitleValid === true ? "ion-hide" : ""}`}>
                                <h5 style={{ fontSize: 12 + 'px' }} className={`ion-margin-horizontal ${isTitleEmpty ? "" : "ion-hide"}`}>Title is required.</h5>
                                <h5 style={{ fontSize: 12 + 'px' }} className={`ion-margin-horizontal ${isTitleValid ? "ion-hide" : ""}`}>Title is not valid.</h5>
                            </IonText>
                        </IonCol>
                    </IonRow>

                    <IonRow className="ion-align-items-center">
                        <IonCol size="4">
                            <IonLabel>Sub Domain</IonLabel>
                        </IonCol>
                        <IonCol size="3">
                            <IonItem>
                                <IonInput
                                    placeholder="Enter a Sub Domain"
                                    type="text"
                                    name="subdomain"
                                    value={domain}
                                    onIonChange={(e) => { setDomain(e.detail.value) }}
                                    readonly={!subdomainAccess}
                                />
                            </IonItem>
                            <IonText color="danger" className={`${isDomainEmpty === false && isDomainValid === true && isSubdomainAvailable === true ? "ion-hide" : ""}`}>
                                <h5 style={{ fontSize: 12 + 'px' }} className={`ion-margin-horizontal ${isDomainEmpty ? "" : "ion-hide"}`}>Domain is required.</h5>
                                <h5 style={{ fontSize: 12 + 'px' }} className={`ion-margin-horizontal ${isDomainValid ? "ion-hide" : ""}`}>Domain can't contain special characters and length should be greater than 2.</h5>
                                <h5 style={{ fontSize: 12 + 'px' }} className={`ion-margin-horizontal ${isSubdomainAvailable ? "ion-hide" : ""}`}>This Domain isn't Available</h5>
                            </IonText>
                        </IonCol>
                        <IonCol className="ion-no-padding" size="2">
                            <IonText>.thesite.co.in</IonText>
                        </IonCol>
                        {/* <IonCol size="1">
                            <IonIcon icon={checkmarkDone} className={isSubdomainAvailable ? "" : "ion-hide"} />
                        </IonCol> */}
                    </IonRow>
                    <br />
                    <IonRow className="ion-align-items-center">
                        <IonCol size="4">
                            <IonLabel>Logo (square image)</IonLabel>
                        </IonCol>
                        <IonCol size="3">
                            <div className="image-upload">
                                <input
                                    hidden
                                    type="file"
                                    accept="image/*"
                                    ref={fileInputRef}
                                    onChange={handleFileChange}
                                />
                                <img width="35%" height="80" src={pictureUrl} alt=""></img>
                            </div>
                            <IonText color="danger" className={`${isLogoEmpty === false ? "ion-hide" : ""}`}>
                                <h5 style={{ fontSize: 12 + 'px' }} className={`ion-margin-horizontal ${isLogoEmpty ? "" : "ion-hide"}`}>Logo is required.</h5>
                            </IonText>
                        </IonCol>
                        <IonCol>
                            <IonButton color="dark" size="small" shape="round" onClick={handlePictureClick}>
                                Update Image
                            </IonButton>
                        </IonCol>
                    </IonRow>
                    <br />

                    <IonCard className="ion-no-margin ion-on-padding">
                        <IonItem lines="none">
                            {/* <IonIcon size="small" icon={person}></IonIcon> */}
                            <IonText><h5>Social Media Handles</h5></IonText>
                        </IonItem>
                    </IonCard>

                    <br />
                    <IonRow className="ion-align-items-center">
                        <IonCol size="4">
                            <IonLabel>Facebook</IonLabel>
                        </IonCol>
                        <IonCol>
                            <IonItem>
                                <IonInput
                                    placeholder="Enter Facebook URL"
                                    type="text"
                                    name="facebook"
                                    value={facebook}
                                    onIonChange={(e) => { setFacebook(e.detail.value) }}
                                />
                            </IonItem>
                        </IonCol>
                    </IonRow>

                    <IonRow className="ion-align-items-center">
                        <IonCol size="4">
                            <IonLabel>Twitter</IonLabel>
                        </IonCol>
                        <IonCol>
                            <IonItem>
                                <IonInput
                                    placeholder="Enter Twitter URL"
                                    type="text"
                                    name="twitter"
                                    value={twitter}
                                    onIonChange={(e) => { setTwitter(e.detail.value) }}
                                />
                            </IonItem>
                        </IonCol>
                    </IonRow>

                    <IonRow className="ion-align-items-center">
                        <IonCol size="4">
                            <IonLabel>Instagram</IonLabel>
                        </IonCol>
                        <IonCol>
                            <IonItem>
                                <IonInput
                                    placeholder="Enter Instagram URL"
                                    type="text"
                                    name="instagram"
                                    value={instagram}
                                    onIonChange={(e) => { setInstagram(e.detail.value) }}
                                />
                            </IonItem>
                        </IonCol>
                    </IonRow>

                    <IonRow className="ion-align-items-center">
                        <IonCol size="4">
                            <IonLabel>LinkedIn</IonLabel>
                        </IonCol>
                        <IonCol>
                            <IonItem>
                                <IonInput
                                    placeholder="Enter Linkedin URL"
                                    type="text"
                                    name="linkedin"
                                    value={linkedin}
                                    onIonChange={(e) => { setLinkedin(e.detail.value) }}
                                />
                            </IonItem>
                        </IonCol>
                    </IonRow>

                    <br />
                    <div className="ion-text-center">
                        <IonButton size="default" shape="round" type="submit">
                            Submit
                        </IonButton>
                    </div>
                </form>
                <IonLoading isOpen={loading === true || landingPagesLoading === true || setLandingPagesLoadingResponse === true} message="Please Wait"></IonLoading>
            </IonContent>
        </IonPage>
    );
};

export default LandingPages;
