import React from "react";
import ReactGoogleSlides from "react-google-slides";

const OpenSlide = ({ path }: { path: string }) => {
    console.log(path);

    return (
        path && (
            <ReactGoogleSlides
                width="100%"
                height="100%"
                slidesLink={path}
                position={1}
                showControls
                loop
            />
        )
    );
};
export default OpenSlide;
