import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    IonContent,
    IonLabel,
    IonText,
    IonLoading,
    IonBackButton,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonTitle,
    IonItem,
    IonInput,
    IonButton,
    IonPage,
    IonGrid,
    IonRow,
    IonCol,
    useIonAlert,
} from "@ionic/react";
import { arrowBack as arrowBackIcon } from "ionicons/icons";
import { API_ROOT } from "../../constants/global";
import { getAccessToken } from "../../services/auth/userLocalStorage";

const BankAccountDetails = () => {
    const [bankAccount, setBankAccount] = useState<any>();
    const [conBankAccount, setConBankAccount] = useState<any>();
    const [ifscCode, setIfscCode] = useState<any>();
    const [accountHolderName, setAccountHolderName] = useState<any>();
    const [loading, setLoading] = useState(false);
    const [bankName, setBankName] = useState<undefined | string | null>("");
    const [userID, setUserID] = useState<any>();

    //Whatsapp number validation
    const [isBankAccountValid, setIsBankAccountValid] = useState(true);
    const [isConBankAccountValid, setIsConBankAccountValid] = useState(true);
    const [isIfscCodeValid, setIsIfscCodeValid] = useState(true);
    const [isHolderNameValid, setIsHolderNameValid] = useState(true);
    const [isBankNameValidate, setIsBankNameValidate] = useState(true);

    const [isBankAccountEmpty, setIsBankAccountEmpty] = useState(false);
    const [isIfscCodeEmpty, setIsIfscCodeEmpty] = useState(false);
    const [isHolderNameEmpty, setIsHolderNameEmpty] = useState(false);
    const [isBankNameEmpty, setIsBankNameEmpty] = useState(false);

    const [present] = useIonAlert();

    // const dispatch = useDispatch();
    // const { paymentLoading, payment, paymentError } = useSelector(
    //     (state: any) => state.payment
    // );
    // const {
    //     setPaymentResponseLoading,
    //     setPaymentResponse,
    //     setPaymentResponseError,
    // } = useSelector((state: any) => state.setPayment);


    // TO FETCH THE DATA FROM partners/bank-settings API


    useEffect(() => {
        const token = getAccessToken();

        const fetchData = async () => {
            const response = await fetch(`${API_ROOT}partners/bank-settings`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    authorization: `Bearer ${token.token}`,
                },
            });
            const data = await response.json();
            if (data !== undefined) {
                setAccountHolderName(data?.data.bank_account_holder_name);
                setBankAccount(data?.data.bank_account_number);
                setConBankAccount(data?.data.bank_account_number);
                setBankName(data?.data.bank_name);
                setIfscCode(data?.data.bank_ifsc);
                setUserID(data?.data.id);
            }
        };
        fetchData();
    }, []);

    const { currentUser } = useSelector((state: any) => state.currentUser);

    const bankAccountValidation = () => {
        let result = true;
        if (!bankAccount) {
            setIsBankAccountEmpty(true);
            result = false;
        } else {
            setIsBankAccountEmpty(false);
            if (/\D/.test(bankAccount)) {
                setIsBankAccountValid(false);
                result = false;
            } else {
                setIsBankAccountValid(true);
                if (bankAccount.length < 9 || bankAccount.length > 18) {
                    setIsBankAccountValid(false);
                    result = false;
                } else setIsBankAccountValid(true);
            }
        }
        return result;
    };

    const conBankAccountValidation = () => {
        let result = true;
        if (conBankAccount !== bankAccount) {
            setIsConBankAccountValid(false);
            result = false;
        } else {
            setIsConBankAccountValid(true);
        }
        return result;
    };

    const ifscValidation = () => {
        let result = true;
        if (!ifscCode) {
            setIsIfscCodeEmpty(true);
            result = false;
        } else {
            setIsIfscCodeEmpty(false);
            if (/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(ifscCode)) {
                setIsIfscCodeValid(false);
                result = false;
            } else {
                setIsIfscCodeValid(true);
                if (ifscCode.length > 11) {
                    setIsIfscCodeValid(false);
                    result = false;
                } else setIsIfscCodeValid(true);
            }
        }
        return result;
    };

    const accountHolderNameValidation = () => {
        let result = true;
        if (!accountHolderName) {
            setIsHolderNameEmpty(true);
            result = false;
        } else {
            setIsHolderNameEmpty(false);
            if (
                /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(
                    accountHolderName
                )
            ) {
                setIsHolderNameValid(false);
                result = false;
            } else setIsHolderNameValid(true);
        }
        return result;
    };
    const bankNameValidation = () => {
        let result = true;
        if (!bankName) {
            setIsBankNameEmpty(true);
            result = false;
        } else {
            setIsBankNameEmpty(false);
            // if(/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(name)){
            if (
                /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~01234567899]/.test(
                    bankName
                )
            ) {
                setIsBankNameValidate(false);
                result = false;
            } else setIsBankNameValidate(true);
        }
        return result;
    };

    // const submitHandler = async (e: any) => {
    //     e.preventDefault();

    //     const res1 = await bankAccountValidation();
    //     const res2 = await conBankAccountValidation();
    //     const res3 = await ifscValidation();
    //     const res4 = await accountHolderNameValidation();
    //     const res5 = await bankNameValidation();
    //     if (
    //         res1 &&
    //         res2 &&
    //         res3 &&
    //         res4 &&
    //         res5 &&
    //         currentUser &&
    //         currentUser.data
    //     ) {
    //         setLoading(true);
    //         // let platform_path= await  savePicture(imageFile)
    //         // if(!platform_path)platform_path=payment.data.paytm_image
    //         // Promise.all([await dispatch(setPayment(getAccessToken(), currentUser.data[0].store_id, {
    //         //         enable_cod: `${codChecked ? 1 : 0}`,
    //         //         enable_pay_via_paytm: `${paytmChecked ? 1 : 0}`,
    //         //         paytm_phone: paytmNumber ? `${paytmNumber}` : null,
    //         //         paytm_image:platform_path,
    //         //         enable_payment_gateway: `${gatewayChecked ? 1 : 0}`,
    //         //         bank_account: bankAccount ? `${bankAccount}` : null,
    //         //         bank_ifsc_code: ifscCode ? `${ifscCode}` : null,
    //         //         bank_account_holder_name: accountHolderName ? `${accountHolderName}` : null,
    //         // })), dispatch(getPayment(getAccessToken(), currentUser.data[0].store_id))]);
    //         setLoading(false);
    //     }
    // };

    const submitHandler = async (e: any) => {
        e.preventDefault();
        const res1 = await bankAccountValidation();
        const res2 = await conBankAccountValidation();
        const res3 = await ifscValidation();
        const res4 = await accountHolderNameValidation();
        const res5 = await bankNameValidation();
        if (
            res1 &&
            res2 &&
            res3 &&
            res4 &&
            res5
        ){
            setLoading(true);
            const token = getAccessToken()
            const putData = {
                id: `${userID}`,
                bank_account_holder_name: `${accountHolderName}`,
                bank_account_number: `${bankAccount}`,
                bank_name: `${bankName}`,
                bank_ifsc: `${ifscCode}`
            };
            const resp = await fetch(`${API_ROOT}partners/bank-settings`,{
                method: 'PUT',
                headers: {
                    "Content-Type": "application/json",
                    authorization: `Bearer ${token.token}`,
                },
                body: JSON.stringify(putData)
            });
            setLoading(false);

            if(resp.status === 200)
            {
                present({
                    cssClass: 'my-css',
                    header: accountHolderName,
                    message: 'Your Details Are Saved Successfully',
                    buttons: ['OK']
                  })
            }
            else
            {
                present({
                    cssClass: 'my-css',
                    header: 'We are facing issues right now, please try in sometime',
                    message: 'Error Code ' + resp.status,
                    buttons: ['OK']
                  })
            }
        }
    };

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton
                            defaultHref="/my/settings"
                            icon={arrowBackIcon}
                        />
                    </IonButtons>
                    <IonTitle></IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">
                <form onSubmit={submitHandler}>
                    <IonGrid>
                        <IonRow className="ion-align-items-center">
                            <IonCol size="4">
                                <IonLabel>Account Holder Name</IonLabel>
                            </IonCol>
                            <IonCol>
                                <IonItem>
                                    <IonInput
                                        placeholder="Enter Account Holder Name"
                                        type="text"
                                        value={accountHolderName}
                                        name="accountHolderName"
                                        onIonChange={(e) => {
                                            setAccountHolderName(
                                                e.detail.value
                                            );
                                        }}
                                    />
                                </IonItem>
                                <IonText
                                    color="danger"
                                    className={`${
                                        isHolderNameEmpty === false &&
                                        isHolderNameValid === true
                                            ? "ion-hide"
                                            : ""
                                    }`}
                                >
                                    <h5
                                        style={{
                                            fontSize: 12 + "px",
                                            marginTop: 0 + "px",
                                        }}
                                        className={`ion-margin-horizontal ${
                                            isHolderNameEmpty ? "" : "ion-hide"
                                        }`}
                                    >
                                        Account Holder Name is required.
                                    </h5>
                                    <h5
                                        style={{
                                            fontSize: 12 + "px",
                                            marginTop: 0 + "px",
                                        }}
                                        className={`ion-margin-horizontal ${
                                            isHolderNameValid ? "ion-hide" : ""
                                        }`}
                                    >
                                        Account Holder Name can't contain
                                        special characters.
                                    </h5>
                                </IonText>
                            </IonCol>
                        </IonRow>
                        <IonRow className="ion-align-items-center">
                            <IonCol size="4">
                                <IonLabel>Bank Account</IonLabel>
                            </IonCol>
                            <IonCol>
                                <IonItem>
                                    <IonInput
                                        placeholder="Enter Bank Account Number"
                                        type="tel"
                                        value={bankAccount}
                                        name="bankAccount"
                                        onIonChange={(e) => {
                                            setBankAccount(e.detail.value);
                                        }}
                                    />
                                </IonItem>
                                <IonText
                                    color="danger"
                                    className={`${
                                        isBankAccountEmpty === false &&
                                        isBankAccountValid === true
                                            ? "ion-hide"
                                            : ""
                                    }`}
                                >
                                    <h5
                                        style={{ fontSize: 12 + "px" }}
                                        className={`ion-margin-horizontal ${
                                            isBankAccountEmpty ? "" : "ion-hide"
                                        }`}
                                    >
                                        Bank Account is required.
                                    </h5>
                                    <h5
                                        style={{ fontSize: 12 + "px" }}
                                        className={`ion-margin-horizontal ${
                                            isBankAccountValid ? "ion-hide" : ""
                                        }`}
                                    >
                                        Bank Account is not valid.
                                    </h5>
                                </IonText>
                            </IonCol>
                        </IonRow>
                        <IonRow className="ion-align-items-center">
                            <IonCol size="4">
                                <IonLabel>Confirm Bank Account</IonLabel>
                            </IonCol>
                            <IonCol>
                                <IonItem>
                                    <IonInput
                                        placeholder="Confirm Bank Account Number"
                                        type="tel"
                                        value={conBankAccount}
                                        name="confirmBankAccount"
                                        onIonChange={(e) => {
                                            setConBankAccount(e.detail.value);
                                        }}
                                    />
                                </IonItem>
                                <IonText
                                    color="danger"
                                    className={`${
                                        isConBankAccountValid === true
                                            ? "ion-hide"
                                            : ""
                                    }`}
                                >
                                    <h5
                                        style={{
                                            fontSize: 12 + "px",
                                            marginTop: 0 + "px",
                                        }}
                                        className="ion-margin-horizontal"
                                    >
                                        Confirm Bank Account is not same.
                                    </h5>
                                </IonText>
                            </IonCol>
                        </IonRow>
                        <IonRow className="ion-align-items-center">
                            <IonCol size="4">
                                <IonLabel>Bank Name</IonLabel>
                            </IonCol>
                            <IonCol>
                                <IonItem>
                                    <IonInput
                                        placeholder="Enter Bank Name"
                                        type="text"
                                        value={bankName}
                                        name="name"
                                        onIonChange={(e) => {
                                            setBankName(e.detail.value);
                                        }}
                                    />
                                </IonItem>
                                <IonText
                                    color="danger"
                                    className={`${
                                        isBankNameEmpty === false &&
                                        isBankNameValidate === true
                                            ? "ion-hide"
                                            : ""
                                    }`}
                                >
                                    <h5
                                        style={{
                                            fontSize: 12 + "px",
                                            marginTop: 0 + "px",
                                        }}
                                        className={`ion-margin-horizontal ${
                                            isBankNameEmpty ? "" : "ion-hide"
                                        }`}
                                    >
                                        Bank Name is required.
                                    </h5>
                                    <h5
                                        style={{
                                            fontSize: 12 + "px",
                                            marginTop: 0 + "px",
                                        }}
                                        className={`ion-margin-horizontal ${
                                            isBankNameValidate ? "ion-hide" : ""
                                        }`}
                                    >
                                        Bank Name can't contain special
                                        characters or numbers.
                                    </h5>
                                </IonText>
                            </IonCol>
                        </IonRow>
                        <IonRow className="ion-align-items-center">
                            <IonCol size="4">
                                <IonLabel>IFSC Code</IonLabel>
                            </IonCol>
                            <IonCol>
                                <IonItem>
                                    <IonInput
                                        placeholder="Enter IFSC Code"
                                        type="text"
                                        value={ifscCode}
                                        name="ifscCode"
                                        onIonChange={(e) => {
                                            setIfscCode(e.detail.value);
                                        }}
                                    />
                                </IonItem>
                                <IonText
                                    color="danger"
                                    className={`${
                                        isIfscCodeEmpty === false &&
                                        isIfscCodeValid === true
                                            ? "ion-hide"
                                            : ""
                                    }`}
                                >
                                    <h5
                                        style={{
                                            fontSize: 12 + "px",
                                            marginTop: 0 + "px",
                                        }}
                                        className={`ion-margin-horizontal ${
                                            isIfscCodeEmpty ? "" : "ion-hide"
                                        }`}
                                    >
                                        IFSC Code is required.
                                    </h5>
                                    <h5
                                        style={{
                                            fontSize: 12 + "px",
                                            marginTop: 0 + "px",
                                        }}
                                        className={`ion-margin-horizontal ${
                                            isIfscCodeValid ? "ion-hide" : ""
                                        }`}
                                    >
                                        IFSC Code is not correct.
                                    </h5>
                                </IonText>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                    <div className="ion-text-center">
                        <IonButton shape="round" type="submit">
                            Save
                        </IonButton>
                    </div>
                </form>
                <IonLoading
                    isOpen={
                        loading === true
                        // ||
                        // paymentLoading === true ||
                        // setPaymentResponseLoading === true
                    }
                    message="Please Wait"
                ></IonLoading>
            </IonContent>
        </IonPage>
    );
};

export default BankAccountDetails;
