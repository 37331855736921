import { IonBackButton, IonButton, IonButtons, IonCard, IonCheckbox, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonInput, IonItem, IonItemOptions, IonLabel, IonList, IonLoading, IonModal, IonPage, IonRadio, IonRadioGroup, IonRow, IonSelect, IonSelectOption, IonText, IonTextarea, IonTitle, IonToolbar, isPlatform } from '@ionic/react'
import React, { useEffect, useRef, useState } from 'react';
import { Camera,CameraResultType } from '@capacitor/camera';
import {
    arrowBack as arrowBackIcon,
    informationCircle as informationCircleIcon, 
    closeOutline,
    removeCircle as removeCircleIcon,
    caretDown as caretDownIcon,
    addCircle as addCircleIcon,
    addOutline as addOutlineIcon
} from 'ionicons/icons';
import { useDispatch, useSelector } from 'react-redux';
import { getAccessToken } from '../services/auth/userLocalStorage';
import { getStoresCategory } from '../actions/categoryAction';
import { editProduct, getProductById } from '../actions/productActions';
import { useHistory, useParams } from 'react-router';
import { Link } from 'react-router-dom';

interface RouteParams{
  id:string;
}
async function savePicture(file) {
    if(!file)return "";
    const formData = new FormData();
    formData.append("file",file)
    try{
      const response = await fetch(`https://api.intelikart.com/api/upload/product-image`, {
        method: 'POST',
        body:formData
      });
      const data = await response.json();
      return data.platform_path;
    }
    catch(error){
      return "";
    }
  }

  const placeholderlist = {
    Color: 'eg. Red, Blue, Green',
    Size: 'eg. Small, Medium, Large',
    Material: 'eg. Cotton, Wool, Polyester',
    Weight: 'eg. 500gm, 1Kg',
    'Add yours': 'Add yours'
  };

  // const KeyCodes = {
  //   comma: 188,
  //   enter: [10, 13],
  // };
  
  // const delimiters = [...KeyCodes.enter, KeyCodes.comma];

const UpdateProductMain = (props: any) => {
    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();
    const {id} = useParams<RouteParams>();
    const { currentUser } = useSelector((state : any)=> state.currentUser);
    const { fetchCategoriesLoading, categories, fetchCategoriesError } = useSelector((state : any)=> state.categories);
    const { productByIdLoading, product, productByIdError } = useSelector((state : any)=> state.productById);
    const { editProductLoading, editProductMessage, editProductError } = useSelector((state : any)=> state.editProductData);

    //states to store form data
    const [category, setCategory] = useState<any>();
    const [categoryId, setCategoryId] = useState<any>();
    const [productName, setProductName] = useState<any>('');
    const [productDes, setProductDes] = useState<any>('');
    const [sellingPrice, setSellingPrice] = useState<any>();
    const [MRP, setMRP] = useState<any>();
    const [sku, setSku] = useState<any>();
    const [minQty, setMinQty] = useState<any>();
    const [maxQty, setMaxQty] = useState<any>(); 
    const [quantity, setQuantity] = useState<any>();
    const [unitName, setUnitName] = useState<any>();
    const [productStatus, setproductStatus] = useState<any>('');

    //states to check empty or not
    const [isProductNameEmpty, setIsProductNameEmpty] = useState<any>(false);
    const [isSellingPriceEmpty, setIsSellingPriceEmpty] = useState<any>(false);
    //const [isMRPEmpty, setIsMRPEmpty] = useState<any>(false);
    const [isQuantityEmpty, setIsQuantityEmpty] = useState<any>(false);
    const [isUnitNameEmpty, setIsUnitNameEmpty] = useState<any>(false);
    const [isDescriptionEmpty, setIsDescriptionEmpty] = useState<any>(false);
    const [isImageEmpty, setIsImageEmpty] = useState<any>(false);

    //states to validate
    const [isSellingPriceValidate, setIsSellingPriceValidate] = useState<any>(true);
    const [isQtyValidate, setIsQtyValidate] = useState<any>(true);
    const [isMinQtyValidate, setIsMinQtyValidate] = useState<any>(true);
    const [isMaxQtyValidate, setIsMaxQtyValidate] = useState<any>(true);
    const [isMRPValidate, setIsMRPValidate] = useState<any>(true);
    const [isMRPGreater, setIsMRPGreater] = useState<any>(true);
    const [isImageValidate, setIsImageValidate] = useState([true, true, true, true]);

    //To save image data(index is start from 0)
    const [platformPaths,setPlatformPaths]=useState<any>([]);
    const [numberOfImage, setNumberOfImage] = useState(0);
    const [toShowSecond, setToShowSecond] = useState(false);
    const [toShowThird, setToShowThird] = useState(false);
    const [toShowForth, setToShowForth] = useState(false);
    const [imageClicked, setImageClicked] = useState<any>();
    const fileInputRef = useRef<HTMLInputElement>();
    const [pictureUrl, setPictureUrl] = useState(['/assets/placeholder.svg','/assets/placeholder.svg','/assets/placeholder.svg','/assets/placeholder.svg']);

    //For variants
    //check box implementation and variant handlers
  const [isVariantChecked, setIsVariantChecked] = useState<any>();
  const [numberOfOptions, setNumberOfOptions] = useState<any>(1);
  //changeCount use to run useEffect when value
  const [changesCount, setChangesCount] = useState(1);
  const [isRemoveOptionClicked, setIsRemoveOptionClicked] = useState(1);

  //use to track values in option name and tags
  const [optionName, setOptionName] = useState<any>(['']);
  const [optionValue, setOptionValue] = useState<any>([[]]);
  const [optionList, setOptionList] = useState<any>([]);
  const [removedOptionsList, setRemovedOptionsList] = useState<any>([]);
  const [sellingPriceList, setSellingPriceList] = useState<any>({});
  const [mrpList, setMrpList] = useState<any>({});
  const [numberOfOptionValue, setNumberOfOptionValue] = useState([[1]]);
  //const [newOptionValue, setNewOptionValue] = useState([""]);
  //const [stockList, setStockList] = useState<any>({});
  //validation
  const [isVariantNameEmpty, setIsVariantNameEmpty] = useState<any>(false);
  const [isVariantValueEmpty, setIsVariantValueEmpty] = useState<any>(null);
  //const [isMrpInTableEmpty, setIsMrpInTableEmpty] = useState<any>(false);
  const [isSellingPriceInTableEmpty, setIsSellingPriceInTableEmpty] = useState<any>(false);
  //const [isStockInTableEmpty, setIsStockInTableEmpty] = useState<any>(false);
  const [isSellingPriceInTableValidate, setIsSellingPriceInTableValidate] = useState<any>(true);
  const [isMrpInTableValidate, setIsMrpInTableValidate] = useState<any>(true);
  //const [isStockInTableValidate, setIsStockInTableValidate] = useState<any>(true);
  const [isMRPGreaterInTable, setIsMRPGreaterInTable] = useState<any>(true);
  //validation for duplicate values
  const [isDuplicateValue, setIsDuplicateValue] = useState([false, false, false]);
  const [isValuesEmpty, setIsValuesEmpty] = useState([false, false, false]);

  //use to open ion-select when icon click
  const [selectRef, setSelectRef] = useState([useRef<HTMLIonSelectElement>(), useRef<HTMLIonSelectElement>(), useRef<HTMLIonSelectElement>(), useRef<HTMLIonSelectElement>()])

  const [firstTimeInputClick, setFirstTimeInputClick] = useState(true);

  const showSelectFirstTime = (index)=>{
    if(firstTimeInputClick){
      selectRef[index].current.click();
      setFirstTimeInputClick(false);
    }
  }

  // const changeNewOptionValue = (value, index)=>{
  //   let temp = newOptionValue;
  //   temp[index] = value;
  //   setNewOptionValue(temp);
  // }

  const optionNameChangeHandler = async (e, index)=>{
    let temp = optionName;
    temp[index] = e.target.value;
    await setOptionName(temp);
    await setChangesCount(changesCount+1)
  }

  const deleteValueHandler = async (optionIndex, valueIndex)=> {
    let valueName = optionValue[optionIndex][valueIndex];
    let temp = optionValue;
    temp[optionIndex] = temp[optionIndex].filter((tag, index) => index !== valueIndex);
    await setOptionValue(temp);
    let temp1 = numberOfOptionValue;
    temp1[optionIndex].pop();
    await setNumberOfOptionValue(temp1);
    await setChangesCount(changesCount+1);
    await tableContentHandler();

    let data1 = removedOptionsList;
    let dataCount1 = removedOptionsList.length;
    for(let i=0;i<dataCount1;i++){
      if(removedOptionsList[i].indexOf(valueName) !== -1){
        data1.splice(i,1);
      }
    }
    setRemovedOptionsList(data1);
    let data2 = mrpList;
    let data3 = sellingPriceList;
    //let data4 = stockList;
    for(let key in mrpList) {
      if(key.indexOf(valueName) !== -1) {
        delete data2[key];
        delete data3[key];
        //delete data4[key];
      }
    }
    await setMrpList(data2);
    await setSellingPriceList(data3);
    //setStockList(data4);

    // let temp2 = numberOfOptionValue;
    // temp[number].push(1);
    // await setNumberOfOptionValue(temp);
  }

  const addValueHandler = async(e, optionIndex, valueIndex)=> {
    if(e.detail.value !== null && e.detail.value !== undefined){
      let isDuplicatePresent = false;
      let temp = numberOfOptionValue;
      temp[optionIndex][valueIndex + 1] = 1;
      await setNumberOfOptionValue(temp);
      let temp1 = optionValue;
      temp1[optionIndex][valueIndex] = e.detail.value;
      await setOptionValue(temp1);
      await setChangesCount(changesCount+1);
      await tableContentHandler();
      //if((optionIndex == 1 || optionIndex == 2) && (optionValue[1] === [] || optionValue[2] === [])){
      //  setRemovedOptionsList([]);
      //}
    }
  }
  
  const tableContentHandler = ()=>{
    let temp1 = [];
    if(numberOfOptions == 1){
      temp1 = tableContentForOneVariant();
    }
    if(numberOfOptions == 2){
      temp1 = tableContentForTwoVariant();
      if(optionValue[1].length === 0){
        temp1 = tableContentForOneVariant();
      }
    }
    if(numberOfOptions == 3){
      temp1 = tableContentForThreeVariant();
      if(optionValue[2].length === 0){
        if(optionValue[1].length === 0){
          temp1 = tableContentForOneVariant();
        }else{
          temp1 = tableContentForTwoVariant();
        }
      }
    }
    setOptionList(temp1);
  }

  const tableContentForOneVariant = ()=>{
    let temp1 = [];
    for(let i=0;i<optionValue[0].length;i++){
      if(removedOptionsList.indexOf(`${optionValue[0][i]}`) === -1){
      temp1.push(`${optionValue[0][i]}`);
      if(!(`${optionValue[0][i]}` in sellingPriceList)){
        let data = sellingPriceList;
        data[`${optionValue[0][i]}`] = sellingPrice;
        setSellingPriceList(data);
      }
      if(!(`${optionValue[0][i]}` in mrpList)){
        let data = mrpList;
        data[`${optionValue[0][i]}`] = MRP;
        setMrpList(data);
      }
      // if(!(`${optionValue[0][i]}` in stockList)){
      //   let data = stockList;
      //   data[`${optionValue[0][i]}`] = null;
      //   setStockList(data);
      // }
    }
    }
    return temp1;
  }

  const tableContentForTwoVariant = ()=>{
    let temp1 = [];
    for(let i=0;i<optionValue[0].length;i++){
      for(let j=0;j<optionValue[1].length;j++){
        if(removedOptionsList.indexOf(`${optionValue[0][i]} / ${optionValue[1][j]}`) === -1){
        temp1.push(`${optionValue[0][i]} / ${optionValue[1][j]}`);
        if(!(`${optionValue[0][i]} / ${optionValue[1][j]}` in sellingPriceList)){
          let data = sellingPriceList;
          data[`${optionValue[0][i]} / ${optionValue[1][j]}`] = sellingPrice;
          setSellingPriceList(data);
        }
        if(!(`${optionValue[0][i]} / ${optionValue[1][j]}` in mrpList)){
          let data = mrpList;
          data[`${optionValue[0][i]} / ${optionValue[1][j]}`] = MRP;
          setMrpList(data);
        }
        // if(!(`${optionValue[0][i]} / ${optionValue[1][j]}` in stockList)){
        //   let data = stockList;
        //   data[`${optionValue[0][i]} / ${optionValue[1][j]}`] = null;
        //   setStockList(data);
        // }
      }
      }
    }
    return temp1;
  }

  const tableContentForThreeVariant = ()=>{
    let temp1 = [];
    for(let i=0;i<optionValue[0].length;i++){
      for(let j=0;j<optionValue[1].length;j++){
        for(let k=0;k<optionValue[2].length;k++){
          if(removedOptionsList.indexOf(`${optionValue[0][i]} / ${optionValue[1][j]} / ${optionValue[2][k]}`) === -1){
            temp1.push(`${optionValue[0][i]} / ${optionValue[1][j]} / ${optionValue[2][k]}`);
            if(!(`${optionValue[0][i]} / ${optionValue[1][j]} / ${optionValue[2][k]}` in sellingPriceList)){
              let data = sellingPriceList;
              data[`${optionValue[0][i]} / ${optionValue[1][j]} / ${optionValue[2][k]}`] = sellingPrice;
              setSellingPriceList(data);
            }
            if(!(`${optionValue[0][i]} / ${optionValue[1][j]} / ${optionValue[2][k]}` in mrpList)){
              let data = mrpList;
              data[`${optionValue[0][i]} / ${optionValue[1][j]} / ${optionValue[2][k]}`] = MRP;
              setMrpList(data);
            }
            // if(!(`${optionValue[0][i]} / ${optionValue[1][j]} / ${optionValue[2][k]}` in stockList)){
            //   let data = stockList;
            //   data[`${optionValue[0][i]} / ${optionValue[1][j]} / ${optionValue[2][k]}`] = null;
            //   setStockList(data);
            // }
          }
        }
      }
    }
    return temp1;
  }

  const addOptionHandler = async()=> {
    if(numberOfOptions >=3){
      window.alert("Variant can not be more than 3.")
    }else{
      let res = true;
      for(let i=0;i<numberOfOptions;i++){
        if(optionValue[i].length === 0 || optionName[i] === '' || optionName[i] === undefined || optionName[i] === null){
          if(optionValue[i].length === 0){
            setIsVariantValueEmpty(optionName[i]);
            res = false;
          }else{
            setIsVariantValueEmpty(null);
          }
          if(optionName[i] === '' || optionName[i] === undefined || optionName[i] === null){
            setIsVariantNameEmpty(true);
            res = false;
          }else{
            setIsVariantNameEmpty(false);
          }
          break;
        }
      }
      if(res === true){
        setIsVariantValueEmpty(null);
        setIsVariantNameEmpty(false);
        await setNumberOfOptions(numberOfOptions+1);
        await setOptionValue([...optionValue,[]]);
        await setOptionName([...optionName, '']);
        //await setNewOptionValue([...newOptionValue, ""]);
        await setNumberOfOptionValue([...numberOfOptionValue, [1]]);
      }
    }
  }

  const removeOptionHandler = async (number)=> {
    if(numberOfOptions<=1){
      setIsVariantChecked(false);
      setNumberOfOptions(1);
      setOptionName([]);
      setOptionValue([[]]);
      setOptionList([]);
      setRemovedOptionsList([]);
      setSellingPriceList({});
      setMrpList({});
      //setStockList({});
    }else{
      await setNumberOfOptions(numberOfOptions-1);
      await setOptionValue(await optionValue.filter((val, index) => index !== number));
      await setOptionName(await optionName.filter((val, index) => index !== number));
      await setNumberOfOptionValue(await numberOfOptionValue.filter((val, index) => index !== number));
      setIsRemoveOptionClicked(isRemoveOptionClicked + 1);
      setIsVariantValueEmpty(null);
      setIsVariantNameEmpty(false);
      let temp = isValuesEmpty;
      temp[number] = false;
      setIsValuesEmpty(temp);
      let temp1 = isDuplicateValue;
      temp[number] = false;
      setIsValuesEmpty(temp1);
    }
  }

  const removeFromList = (index)=>{
    let temp = optionList;
    temp.splice(index,1);
    setOptionList(temp);
  }

  useEffect(() => {tableContentHandler()}, [isRemoveOptionClicked])
  useEffect(() => {}, [changesCount, isVariantChecked]);

    useEffect(()=>{
      const accessToken = getAccessToken();
      if(accessToken && currentUser && currentUser.data && !categories){
        dispatch(getStoresCategory(accessToken,currentUser.data[0].store_id));
      }
      if(accessToken && currentUser && currentUser.data && id){
        dispatch(getProductById(id));
      }
    },[currentUser, id]);

    useEffect(() => {
      if(productByIdLoading === true){
        setProductName('');
        setProductDes('');
        setSellingPrice(0);
        setQuantity(0);
        setMRP(0);
        setUnitName('');
        setCategory('');
        setproductStatus('');
        setNumberOfImage(0);
        setIsVariantValueEmpty(null);
        setIsDuplicateValue([false, false, false]);
        setIsValuesEmpty([false, false, false]);
        setPlatformPaths([]);
        setPictureUrl(['/assets/placeholder.svg','/assets/placeholder.svg','/assets/placeholder.svg','/assets/placeholder.svg']);
        setIsVariantChecked(false);
        setNumberOfOptions(1);
        setOptionValue([[]]);
        setOptionName(['']);
        setOptionList([]);
        setRemovedOptionsList([]);
        setChangesCount(1);
        setIsRemoveOptionClicked(1);
        setSellingPriceList({});
        setMrpList({});
        //setStockList({});
      }
      if(productByIdLoading === false && product){
        setProductName(product.name);
        setProductDes(product.desc_html);
        setCategory({id: product.category_id, name: product.category_name });
        setproductStatus(product.status);
        setCategoryId(product.category_id);
        setSellingPrice(product.variants[0].mrp);
        setQuantity(product.variants[0].quantity);
        setSku(product.sku);
        setMinQty(product.variants[0].min_order_quantity);
        setMaxQty(product.variants[0].max_order_quantity);
        setMRP(product.variants[0].display_mrp);
        setUnitName(product.variants[0].quantity_unit_name);
        setNumberOfImage(product.variants[0].images.length);
        let imageArray = pictureUrl;
        let imagePathArray = platformPaths;
        for (let i = 0; i < product.variants[0].images.length; i++) {
          imageArray[i] = product.variants[0].images[i].image_url_original;
          imagePathArray[i] = (product.variants[0].images[i].image_url_original);
        }
        setPlatformPaths(imagePathArray);
        setPictureUrl(imageArray);
        if(product.attributes !== null || product.variants.length > 1 ){
          setIsVariantChecked(true);
          setNumberOfOptions(product.attributes.length);
          let name = [], value = [], numberOfValue = [];
          product.attributes.forEach((currValue)=>{
            name.push(currValue.name);
            let t1 = [];
            let t2 = [1];
            currValue.values.forEach((val)=>{
              t1.push(`${val.value}`);
              t2.push(1);
            });
            value.push(t1);
            numberOfValue.push(t2);
          });
          setOptionName(name);
          setOptionValue(value);
          setNumberOfOptionValue(numberOfValue);
          product.variants.forEach((currentValue)=>{
            let optionListName;
            if(currentValue.attribute_value_mappings.length === 1){
              optionListName = `${currentValue.attribute_value_mappings[0].attribute_value}`;
            }else if(currentValue.attribute_value_mappings.length === 2){
              optionListName = `${currentValue.attribute_value_mappings[0].attribute_value} / ${currentValue.attribute_value_mappings[1].attribute_value}`;
            }else if(currentValue.attribute_value_mappings.length === 3){
              optionListName = `${currentValue.attribute_value_mappings[0].attribute_value} / ${currentValue.attribute_value_mappings[1].attribute_value} / ${currentValue.attribute_value_mappings[2].attribute_value}`;
            }else{}
            if(optionListName){
              let temp = optionList;
              temp.push(optionListName);
              setOptionList(temp);
              let sellingPriceForList = sellingPriceList;
              let mrpForList = mrpList;
              // let stockForList = stockList;
              // stockForList[`${optionListName}`] = currentValue.available_stock;
              sellingPriceForList[`${optionListName}`] = currentValue.mrp;
              mrpForList[`${optionListName}`] = currentValue.display_mrp;
              setSellingPriceList(sellingPriceForList);
              setMrpList(mrpForList);
              //setStockList(stockForList);
            }
          })
          if(numberOfOptions === 1){
            for(let i=0;i<optionValue[0].length;i++){
              if(optionList.indexOf(`${optionValue[0][i]}`) === -1){
                setRemovedOptionsList([...removedOptionsList, `${optionValue[0][i]}`])
              }
            }
          }
          if(numberOfOptions === 2){
            for(let i=0;i<optionValue[0].length;i++){
              for(let j=0;j<optionValue[1].length;j++){
                if(optionList.indexOf(`${optionValue[0][i]} / ${optionValue[1][j]}`) === -1){
                  setRemovedOptionsList([...removedOptionsList, `${optionValue[0][i]} / ${optionValue[1][j]}`]);
                }
              }
            }
          }
          if(numberOfOptions === 3){
            for(let i=0;i<optionValue[0].length;i++){
              for(let j=0;j<optionValue[1].length;j++){
                for(let k=0;k<optionValue[2].length;k++){
                  if(optionList.indexOf(`${optionValue[0][i]} / ${optionValue[1][j]} / ${optionValue[2][k]}`) === -1){
                    setRemovedOptionsList([...removedOptionsList, `${optionValue[0][i]} / ${optionValue[1][j]} / ${optionValue[2][k]}`]);
                  }
                }
              }
            }
          }
        }
      }
    }, [productByIdLoading]);

    useEffect(() => {    }, [numberOfImage]);

    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.files.length > 0) {
        const file = event.target.files.item(0);
        if(file.type.startsWith("image")){
          let temp2 = isImageValidate;
          temp2[imageClicked] = true;
          setIsImageValidate(temp2);
          if(pictureUrl[imageClicked] === '/assets/placeholder.svg'){
            setNumberOfImage(numberOfImage + 1);
          }
          const myPictureUrl = URL.createObjectURL(file);
          let temp = pictureUrl;
          temp[imageClicked] = myPictureUrl;
          await setPictureUrl(temp);
          setLoading(true);
          const myPlatformPath = await savePicture(file);
          setLoading(false);
          if(myPlatformPath){
            let temps = platformPaths;
            temps[imageClicked] = myPlatformPath; 
            setPlatformPaths(temps);
          }
        }else{
          let temp2 = isImageValidate;
          temp2[imageClicked] = false; 
          setIsImageValidate(temp2);
        }
      }
    };

    const handlePictureClick= async()=>{
      if(isPlatform('capacitor')){
        try{
          const photo=await Camera.getPhoto({
            resultType:CameraResultType.Uri,
          })
          let temp = pictureUrl;
          temp[imageClicked] = photo.webPath;
          setPictureUrl(temp);
        }
        catch(error){
          console.log(error);
        }
      }
      else{
        fileInputRef.current.click();
      }
    }

    //validate functions
    const productNameValidation = ()=>{
      let result = true;
      if(!productName){
          setIsProductNameEmpty(true);
          result = false;
      }else{
          setIsProductNameEmpty(false);
      }
      return result;
    }

    const descriptionValidation = ()=>{
      let result = true;
      if(!productDes){
          setIsDescriptionEmpty(true);
          result = false;
      }else{
          setIsDescriptionEmpty(false);
      }
      return result;
    }
    function removeEmojis(string) {
      var regex = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;
      return string.replace(regex, "");
    }

    const SellingPriceValidation = ()=>{
      let result = true;
      if(!sellingPrice){
          setIsSellingPriceEmpty(true);
          result = false;
      }else{
          setIsSellingPriceEmpty(false);
          if(!(/^\d+(\.\d{1,2})?$/.test(sellingPrice))){
              setIsSellingPriceValidate(false);
              result = false;
          }
          else{
              setIsSellingPriceValidate(true);
          }
      }
      return result;
    }

    const MRPValidation = ()=>{
      let result = true;
      if(MRP !== null && MRP !== undefined && MRP !== "" && MRP!=0){
          if(!(/^\d+(\.\d{1,2})?$/.test(MRP))){
              setIsMRPValidate(false);
              result = false;
          }
          else{
              setIsMRPValidate(true);
          }
          if(Number(MRP) <= Number(sellingPrice)){
            setIsMRPGreater(false);
            result = false;
          }else{
            setIsMRPGreater(true);
          }
      }
      return result;
    }
    const  maxQtyValidation = ()=>{
      let result = true;
      if(maxQty !== null && maxQty !== undefined && maxQty !== ""){
          if(isNaN(maxQty)){
              setIsMaxQtyValidate(false);
              result = false;
          }
          else{
            setIsMaxQtyValidate(true)
          }
          
      }
      return result;
    }
    const minQtyValidation = ()=>{
      let result = true;
      if(minQty !== null && minQty !== undefined && minQty !== ""){
          if(isNaN(minQty)){
              setIsMinQtyValidate(false);
              result = false;
          }
          else{
            setIsMinQtyValidate(true);
          }
           
      }
      return result;
    }


    const quantityValidation = ()=>{
      let result = true;
      if(!quantity){
          setIsQuantityEmpty(true);
          result = false;
      }else{
          setIsQuantityEmpty(false);
          if(isNaN(quantity)){
            setIsQtyValidate(false);
            result = false;
          }
          else{
            setIsQtyValidate(true);
          }
      }
      return result;
    }

    const unitNameValidation = ()=>{
      let result = true;
      if(!unitName){
          setIsUnitNameEmpty(true);
          result = false;
      }else{
          setIsUnitNameEmpty(false);
      }
      return result;
    }

    const imageValidation = ()=>{
      let result = true;
      if(numberOfImage === 0){
          setIsImageEmpty(true);
          result = false;
      }else{
          setIsImageEmpty(false);
      }
      return result;
    }

    //Handling both duplicate and empty value on blur.
    const duplicateValidation = (optionIndex?, valueIndex?)=>{
      optionValue.forEach((innerArray, innerArrayIndex) => {
        let emptyValuePresent = false;
        innerArray.forEach((element, index)=>{
          if(!element){
            emptyValuePresent = true;
          }
        });
        if(emptyValuePresent){
          let temp = isValuesEmpty;
          temp[innerArrayIndex] = true;
          setIsValuesEmpty(temp);
        }else{
          let temp = isValuesEmpty;
          temp[innerArrayIndex] = false;
          setIsValuesEmpty(temp);
        }
      });
      if(optionIndex !== null && optionIndex !== undefined ){
        let duplicates = 0;
        optionValue[optionIndex].forEach((element, index)=>{
          if(valueIndex !== index){
            if(optionValue[optionIndex].lastIndexOf(element.trim()) !== index){
              duplicates++;
            }
            if(optionValue[optionIndex].indexOf(element.trim()) !== index){
              duplicates++;
            }
          }
        });
        if(duplicates !== 0){
          let temp = isDuplicateValue;
          temp[optionIndex] = true;
          setIsDuplicateValue(temp);
        }else{
          let temp = isDuplicateValue;
          temp[optionIndex] = false;
          setIsDuplicateValue(temp);
        }
        setChangesCount(changesCount+1);
      }else{
        let result = true;
        let duplicates = [0,0,0];
        optionValue.forEach((innerArray, innerArrayIndex) => {
          innerArray.forEach((element, index)=>{
            if(innerArray.lastIndexOf(element) !== index){
              duplicates[innerArrayIndex]++;
              result = false;
            }
            if(innerArray.indexOf(element) !== index){
              duplicates[innerArrayIndex]++;
              result = false;
            }
          });
        });
        duplicates.forEach((element, index) => {
          if(element !== 0){
            let temp = isDuplicateValue;
            temp[index] = true;
            setIsDuplicateValue(temp);
          }else{
            let temp = isDuplicateValue;
            temp[index] = false;
            setIsDuplicateValue(temp);
          }
        });
        setChangesCount(changesCount+1);
        return result;
      }
    }

    const variantsValidation = ()=>{
      let result = true;
      for(let i=0;i<numberOfOptions;i++){
        if(optionValue[i].length === 0 || optionName[i] === '' || optionName[i] === undefined || optionName[i] === null){
          if(optionValue[i].length === 0){
            setIsVariantValueEmpty(optionName[i]);
            result = false;
          }else{
            setIsVariantValueEmpty(null);
          }
          if(optionName[i] === '' || optionName[i] === undefined || optionName[i] === null){
            setIsVariantNameEmpty(true);
            result = false;
          }else{
            setIsVariantNameEmpty(false);
          }
          break;
        }
      }

      optionValue.forEach((innerArray, innerArrayIndex) => {
        let emptyValuePresent = false;
        innerArray.forEach((element, index)=>{
          if(!element){
            result = false;
            emptyValuePresent = true;
          }
        });
        if(emptyValuePresent){
          let temp = isValuesEmpty;
          temp[innerArrayIndex] = true;
          setIsValuesEmpty(temp);
        }else{
          let temp = isValuesEmpty;
          temp[innerArrayIndex] = false;
          setIsValuesEmpty(temp);
        }
      });

      let stockEmpty, mrpEmpty, sellingPriceEmpty, stockValid, mrpValid, sellingPriceValid, mrpGreater = false;
      optionList.forEach(element => {
        // if(!mrpEmpty){
        //   if(!mrpList[`${element}`]){
        //     setIsMrpInTableEmpty(true);
        //     result = false;
        //     mrpEmpty = true;
        //   }else{
        //     setIsMrpInTableEmpty(false);
        //   }
        // }
        if(!sellingPriceEmpty){
          if(!sellingPriceList[`${element}`]){
            setIsSellingPriceInTableEmpty(true);
            sellingPriceEmpty = true;
            result = false;
          }else{
            setIsSellingPriceInTableEmpty(false);
          }
        }
        // if(!stockEmpty){
        //   if(!stockList[`${element}`]){
        //     setIsStockInTableEmpty(true);
        //     stockEmpty = true;
        //     result = false;
        //   }else{
        //     setIsStockInTableEmpty(false);
        //   }
        // }
        if(!mrpValid){
          if(mrpList[`${element}`]){
            if(!(/^\d+(\.\d{1,2})?$/.test(mrpList[`${element}`]))){
            setIsMrpInTableValidate(false);
            mrpValid = true;
            result = false;
          }else{
            setIsMrpInTableValidate(true);
          }}
        }
        if(!sellingPriceValid){
          if(sellingPriceList[`${element}`]){if(!(/^\d+(\.\d{1,2})?$/.test(sellingPriceList[`${element}`]))){
            setIsSellingPriceInTableValidate(false);
            sellingPriceValid = true;
            result = false;
          }else{
            setIsSellingPriceInTableValidate(true);
          }}
        }
        // if(!stockValid){
        //   if(stockList[`${element}`]){if(!(/\D/.test(stockList[`${element}`]))){
        //     setIsStockInTableValidate(false);
        //     stockValid = true;
        //     result = false;
        //   }else{
        //     setIsStockInTableValidate(true);
        //   }}
        // }
        if(!mrpGreater){
          if((mrpList[`${element}`] !== null && mrpList[`${element}`] !== undefined && mrpList[`${element}`] !== "") && Number(mrpList[`${element}`]) <= Number(sellingPriceList[`${element}`])){
            setIsMRPGreaterInTable(false);
            mrpGreater = true;
            result = false;
          }else{
            setIsMRPGreaterInTable(true);
          }
        }
      });
      return result;
    }

    const UpdateProductFunction = async ()=>{
      setLoading(true);
      const accessToken = await getAccessToken();
      const res1 = productNameValidation();
      const res2 = SellingPriceValidation();
      const res3 = MRPValidation();
      const res4 = quantityValidation();
      const res5 = unitNameValidation();
      //const res6 = descriptionValidation();
      const res7 = imageValidation();
      const res8 = variantsValidation();
      const res10= minQtyValidation();
      const res11=maxQtyValidation();
      const res12 = duplicateValidation(undefined);
      if( res1 && res2 && res3 && res4 && res5 && res7 && res10 && res11 && accessToken && currentUser.data[0].store_id && product ){
        if(isVariantChecked === true){
          if(res8 && res12){
          await dispatch(editProduct({
            "id": product.id,
            "name": `${productName}`,
            "desc_html": `${removeEmojis(productDes)}`,
            //"status": `${productStatus}`,
            "category_id":  category.id,
            "store_id" : currentUser.data[0].store_id,
            "sku" :sku,
            "variants": [
              ...(optionList.map((val,index)=>{
                return({
                  "id": product.variants[index] ? product.variants[index].id : null,
                  "product_id": product.id,
                  "mrp": `${sellingPriceList[`${val}`]}`,
                  "quantity": `${quantity}`,
                  "min_order_quantity":  `${minQty}`,
                  "max_order_quantity":  `${maxQty}`,
                  "display_mrp": `${mrpList[`${val}`]}`,
                  "quantity_unit_name": `${unitName}`,
                  // "available_quantity": `${stockList[val]}`,
                  "images": [
                    ...(platformPaths.map((value)=>{
                      return({
                        "image_url_original": value
                      })
                    }))
                  ],
                  "attribute_value_mappings":[
                    ...(val.split(" / ").map((value,ind)=>{
                      return({
                        "attribute_name": optionName[ind],
                        "attribute_value": value
                      })
                    }))
                  ]
                })
              }))
            ]
          }, accessToken));
          setLoading(false);
        if(loading === false && editProductMessage){
          history.goBack();
        }
        }
        }else{
          await dispatch(editProduct({
            "id": product.id,
            "name": `${productName}`,
            "desc_html": `${removeEmojis(productDes)}`,
            //"status": `${productStatus}`,
            "category_id":  category.id,
            "store_id" : currentUser.data[0].store_id,
            "sku" : sku,
            "variants": [
              {
                "id": product.variants[0].id,
                "product_id": product.id,
                "mrp": `${sellingPrice}`,
                "quantity": `${quantity}`,
                "min_order_quantity":  `${minQty}`,
                "max_order_quantity":  `${maxQty}`,
                "display_mrp": `${MRP}`,
                "quantity_unit_name": `${unitName}`,
                "images": [
                  ...(platformPaths.map((value)=>{
                    return({
                      "image_url_original": value
                    })
                  }))
                ],
                "attribute_value_mappings": null
              }
            ]
          }, accessToken));

          setLoading(false);
        if(loading === false && editProductMessage){
          history.goBack();
        }
        }
      }
      setLoading(false);
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                  <IonButtons slot="start">
                        <IonIcon size="large" onClick={e=> history.push({
                          pathname: '/my/products',
                          state: {id: props.location.state.id, name: props.location.state.name}
                        })} icon={arrowBackIcon}/>
                    </IonButtons>
                    <IonTitle>Update Product</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">
              <IonItem className="ion-margin-bottom" onClick={e=> setShowModal(true)}>
                    <IonLabel>Select category</IonLabel>
                    {category ? <IonText slot="end">{category.name}</IonText>: ""}
                    <IonIcon slot="end" icon={caretDownIcon}/>
                </IonItem>
                <IonModal isOpen={showModal} onDidDismiss={e=>setShowModal(false)} >
                        <IonHeader>
                            <IonItem lines="none">
                                <IonTitle>Select category</IonTitle>
                                <IonButton fill='clear' size='large'  slot='end' onClick={(e) => setShowModal(false)} >
                                  <IonIcon slot="icon-only" icon={closeOutline}  ></IonIcon> 
                                </IonButton>
                                {/* <IonText color="primary" slot="end" onClick={e=> setShowModal(false)}>
                                    <h5>CLOSE</h5>
                                </IonText> */}
                            </IonItem>
                        </IonHeader>
                        <IonContent className="ion-padding">
                        <IonRadioGroup value={categoryId} onIonChange={e => { setCategoryId(e.detail.value); setShowModal(false)}}>
                        {fetchCategoriesLoading === false && categories ? categories.map((parent)=>{
                        return(<>
                          <IonItem key={parent.id}><IonText color="medium"><h5>{parent.name}</h5></IonText></IonItem>
                          { parent.subCategory.map((child)=>{
                                return (<IonItem key={child.id} onClick={e=> setCategory(child)} lines="none" className="ion-padding-start">{child.name}
                                  <IonRadio slot="start" value={child.id} />
                                </IonItem>
                                )
                            })}
                            <IonItem lines='none'>
                              <Link onClick={e=> {setShowModal(false);}} slot='end' to={`/my/catalogue/subcategory/add/${parent.id}/${parent.name}/${true}`}>
                                <IonButton color='medium' size="small">
                                    <IonIcon icon={addOutlineIcon}/>
                                    Add SubCategory
                                </IonButton>
                              </Link>
                            </IonItem>
                          </>
                        )})
                : "" }
                </IonRadioGroup>
                <div className="ion-text-center">
                  <Link onClick={e=> setShowModal(false)} to="/my/catalogue/category/add">
                    <IonButton>
                      <IonIcon icon={addOutlineIcon}/>
                        Add New Category
                    </IonButton>
                  </Link>
                </div>
                        </IonContent>
                    </IonModal>
                <br/>
                <IonGrid>
                  <IonRow>
                    <IonCol key="001">
                      <IonCard style={{cursor:'pointer', width: 150+'px', height: 120+'px'}}>
                        <input hidden type='file' accept="image/*" ref={fileInputRef}
                        onChange={handleFileChange}/>
                        <img width="100%" height="100%" src={pictureUrl[0]} onClick={e=>{handlePictureClick(); setImageClicked(0);}}></img>
                      </IonCard>
                    </IonCol>
                    <IonCol key="002" className={`${numberOfImage > 0 ? "" : 'ion-hide'}`}>
                      <IonCard style={{cursor:'pointer', width: 150+'px', height: 120+'px'}}>
                        <input hidden type='file' accept="image/*" ref={fileInputRef}
                        onChange={handleFileChange}/>
                        <img width="100%" height="100%" src={pictureUrl[1]} onClick={e=>{handlePictureClick(); setImageClicked(1);}}></img>
                      </IonCard>
                    </IonCol>
                    <IonCol key="003" className={`${numberOfImage > 1 ? '' : 'ion-hide'}`}>
                      <IonCard style={{cursor:'pointer', width: 150+'px', height: 120+'px'}}>
                        <input hidden type='file' accept="image/*" ref={fileInputRef}
                        onChange={handleFileChange}/>
                        <img width="100%" height="100%" src={pictureUrl[2]} onClick={e=>{handlePictureClick(); setImageClicked(2);}}></img>
                      </IonCard>
                    </IonCol>
                    <IonCol key="004" className={`${numberOfImage > 2 ? "" : 'ion-hide'}`}>
                      <IonCard style={{cursor:'pointer', width: 150+'px', height: 120+'px'}}>
                        <input hidden type='file' accept="image/*" ref={fileInputRef}
                        onChange={handleFileChange}/>
                        <img width="100%" height="100%" src={pictureUrl[3]} onClick={e=>{handlePictureClick(); setImageClicked(3);}}></img>
                      </IonCard>
                    </IonCol>
                  </IonRow>
                </IonGrid>
                <IonText color="danger" className={`${isImageValidate[0] === true && isImageValidate[1] === true && isImageValidate[2] === true && isImageValidate[3] === true ? "ion-hide": "" }`}>
                    <h5 style={{fontSize:12+'px', marginTop: 0+'px'}} className="ion-margin-horizontal">Only image files are allowed.</h5>
                </IonText>
                <IonText color="danger" className={`${isImageEmpty === false? "ion-hide": "" }`}>
                    <h5 style={{fontSize:12+'px', marginTop: 0+'px'}} className="ion-margin-horizontal">Minimum one image is required.</h5>
                </IonText>

                <IonItem className="ion-margin-bottom">
                    <IonLabel position="stacked">Product Name</IonLabel>
                    <IonInput placeholder="Enter product name" type="text" value={productName} onIonChange={e=> setProductName(e.detail.value)}/>
                </IonItem>
                <IonText color="danger" className={`${isProductNameEmpty === false? "ion-hide": "" }`}>
                    <h5 style={{fontSize:12+'px', marginTop: 0+'px'}} className="ion-margin-horizontal">Product name is required.</h5>
                </IonText>

                <IonItem className="ion-margin-bottom">
                    <IonLabel position="stacked">Description</IonLabel>
                    <IonTextarea placeholder="Add Description" value={productDes} onIonChange={e=> setProductDes(e.detail.value)}></IonTextarea>
                </IonItem>
                <IonText color="danger" className={`${isDescriptionEmpty === false? "ion-hide": "" }`}>
                    <h5 style={{fontSize:12+'px', marginTop: 0+'px'}} className="ion-margin-horizontal">Product description is required.</h5>
                </IonText>
                <IonText color="medium" className="ion-margin-start"><IonIcon icon={informationCircleIcon}/> Emojis are not allowed.</IonText>

                <IonGrid className="ion-no-padding ion-padding-top">
                  <IonRow>
                    <IonCol size='6' sizeSm='6' className="ion-no-padding" style={{paddingRight: 5+'px'}}>
                      <IonItem className="ion-margin-bottom">
                        <IonLabel position="stacked">Selling Price</IonLabel>
                        <IonInput placeholder="Enter selling price" type="text" value={sellingPrice} onIonChange={e=> setSellingPrice(e.detail.value)} onIonBlur={e=> {MRP ? (Number(MRP) < Number(sellingPrice) ? setIsMRPGreater(false) : setIsMRPGreater(true)) : setIsMRPGreater(true) }}/>
                      </IonItem>
                      <IonText color="danger" className={`${isSellingPriceEmpty === false && isSellingPriceValidate === true ? "ion-hide": "" }`}>
                          <h5 style={{fontSize:12+'px', marginTop: 0+'px'}} className={`ion-margin-horizontal ${isSellingPriceEmpty ? "" : "ion-hide"}`}>Selling price is required.</h5>
                          <h5 style={{fontSize:12+'px', marginTop: 0+'px'}} className={`ion-margin-horizontal ${isSellingPriceValidate ? "ion-hide" : ""}`}>Selling price must contain only numbers and 2 digits after decimal.</h5>
                      </IonText>
                    </IonCol>
                    <IonCol size='6' className="ion-no-padding" style={{paddingLeft: 5+'px'}}>
                      <IonItem className="ion-margin-bottom">
                        <IonLabel position="stacked">MRP</IonLabel>
                        <IonInput placeholder="Enter MRP" type="text" value={MRP} onIonChange={e=> setMRP(e.detail.value)} onIonBlur={e=> {sellingPrice ? (Number(MRP) < Number(sellingPrice) ? setIsMRPGreater(false) : setIsMRPGreater(true)) : setIsMRPGreater(true)}}/>
                      </IonItem>
                      <IonText color="danger" className={`${isMRPValidate === true ? "ion-hide": "" }`}>
                          {/* <h5 style={{fontSize:12+'px', marginTop: 0+'px'}} className={`ion-margin-horizontal ${isMRPEmpty ? "" : "ion-hide"}`}>MRP is required.</h5> */}
                          <h5 style={{fontSize:12+'px', marginTop: 0+'px'}} className={`ion-margin-horizontal ${isMRPValidate ? "ion-hide" : ""}`}>MRP must contain only numbers and 2 digits after decimal.</h5>
                      </IonText>
                    </IonCol>
                  </IonRow>
                </IonGrid>
                <IonText color="danger" className={`${isMRPGreater === true  || MRP==0 ? "ion-hide": "" }`}>
                  <h5 style={{fontSize:12+'px', marginTop: 0+'px'}} className="ion-margin-horizontal">MRP must be greater than selling price.</h5>
                </IonText>

                <IonItem className="ion-margin-bottom">
                  <IonLabel position="stacked">Quantity</IonLabel>
                  <IonInput placeholder="E.g.- 1, 100, 200, 500, etc." type="text" value={quantity} onIonChange={e=> setQuantity(e.detail.value)}/>
                </IonItem>
                <IonText color="danger" className={`${isQuantityEmpty === false && isQtyValidate==true ? "ion-hide" : "" }`}>
                    <h5 style={{fontSize:12+'px', marginTop: 0 + 'px'}} className={`ion-margin-horizontal ${isQuantityEmpty ? "" : "ion-hide"}`}>Quantity is required.</h5>
                    <h5 style={{fontSize:12+'px', marginTop: 0+'px'}} className={`ion-margin-horizontal ${isQtyValidate ? "ion-hide" : ""}`}>Quantity must contain only numbers.</h5>
                </IonText>

                <IonItem className="ion-margin-bottom">
                  <IonLabel position="stacked">Quantity unit Name</IonLabel>
                  <IonInput placeholder="E.g.- Kg, Gm, XL, XXL, Item, Piece etc." type="text" value={unitName} onIonChange={e=> setUnitName(e.detail.value)}/>
                </IonItem>
                <IonText color="danger" className={`${isUnitNameEmpty === false ? "ion-hide": "" }`}>
                    <h5 style={{fontSize:12+'px', marginTop: 0+'px'}} className="ion-margin-horizontal">Quantity unit is required.</h5>
                </IonText>
                <IonItem className="ion-margin-vertical">
                  <IonLabel position="stacked">Product SKU</IonLabel>
                  <IonInput placeholder="Enter product sku id" type="text" value={sku} onIonChange={e=> setSku(e.detail.value)}/>
                </IonItem>
                <IonItem className="ion-margin-vertical">
                  <IonLabel position="stacked">Minimum Order Quantity</IonLabel>
                  <IonInput placeholder="Enter minimum order quantity" type="text" value={minQty} onIonChange={e=> setMinQty(e.detail.value)}/>
                </IonItem>
                <IonText color="danger" className={`${ isMinQtyValidate === true ? "ion-hide": "" }`}> 
                    <h5 style={{fontSize:12+'px', marginTop: 0+'px'}} className={`ion-margin-horizontal ${isMinQtyValidate ? "ion-hide" : ""}`}>Minimum order quantity must contain only numbers.</h5>
                </IonText>
                <IonItem className="ion-margin-vertical">
                  <IonLabel position="stacked">Maximum Order Quantity</IonLabel>
                  <IonInput placeholder="Enter maximum order quantity" type="text" value={maxQty} onIonChange={e=> setMaxQty(e.detail.value)}/>
                </IonItem>
                <IonText color="danger" className={`${ isMaxQtyValidate === true ? "ion-hide": "" }`}> 
                    <h5 style={{fontSize:12+'px', marginTop: 0+'px'}} className={`ion-margin-horizontal ${isMaxQtyValidate ? "ion-hide" : ""}`}>Maximum order quantity must contain only numbers.</h5>
                </IonText>
<br/>
                <IonItem>
                  <IonLabel>Product Status</IonLabel>
                  <IonSelect slot="end" value={productStatus}>
                    <IonSelectOption value="active">Active</IonSelectOption>
                    <IonSelectOption value="inactive">Inactive</IonSelectOption>
                  </IonSelect>
                </IonItem>
                <IonItem lines="none">
                  <IonLabel>(+ Add Variants)</IonLabel>
                  <IonCheckbox slot="start" checked={isVariantChecked} onIonChange={e => {setIsVariantChecked(e.detail.checked);}} />
                </IonItem>
                <IonText color="medium"><h6 className="ion-no-margin ion-margin-start">The product has multiple options like different sizes and colors.</h6></IonText>
                <br/>
                {isVariantChecked === true && productByIdLoading === false ? 
                    <>
                      {optionValue.map((value, index)=>{
                        return(
                          <IonCard>
                        <IonItem lines="none" style={{height:38+'px'}}>
                          <IonButton shape="round" onClick={e=> removeOptionHandler(index)} slot="end" color="danger">Remove</IonButton>
                        </IonItem>
                        <IonGrid className=" ion-no-margin" style={{width:100+'%'}}>
                          <IonRow>
                            <IonCol size="11" sizeSm="12" sizeMd="5" className="ion-margin-start">
                                <IonLabel position="stacked">Variant Name:</IonLabel>
                                  <IonItem>
                                  <IonSelect ref={selectRef[index]} style={{width: 0.1+'px'}} interface="popover" value={optionName[index]} onIonChange={e=>{optionNameChangeHandler(e, index); }}>
                                      {placeholderlist ? Object.keys(placeholderlist).map((val)=><IonSelectOption value={val}>{val}</IonSelectOption>): ""}
                                    </IonSelect>
                                    <IonInput type="text" placeholder="Enter option name" value={optionName[index]} onIonChange={e=>optionNameChangeHandler(e, index)} onFocus={e=>showSelectFirstTime(index)} />
                                  <IonIcon slot="end" icon={caretDownIcon} onClick={e=>selectRef[index].current.click()}></IonIcon>
                                </IonItem>
                            </IonCol>
                            <IonCol className="ion-margin-start">
                            <div className="top-margin-on-mobile">
                                <IonLabel position="stacked">{`${optionName[index] ? optionName[index] : "Variant"}`} value:</IonLabel>
                                {numberOfOptionValue[index] &&numberOfOptionValue[index].length !== 0 ? numberOfOptionValue[index].map((val, ind)=>{
                                    return <IonItem>
                                          <IonInput type='text' placeholder={ind !== 0 && ind === numberOfOptionValue[index].length-1 ? "Add Another Value" : placeholderlist[optionName[index]] ? placeholderlist[optionName[index]]: `Enter ${optionName[index] ? optionName[index] : 'option'} value`} value={optionValue[index][ind] ? optionValue[index][ind] : null} onIonChange={e=> addValueHandler(e,index,ind)} onIonBlur={e=> duplicateValidation(index,ind)}/>
                                          {ind!== numberOfOptionValue[index].length-1 ?
                                          <IonButton slot='end' color='danger' shape='round' size='small' onClick={e=>{deleteValueHandler(index, ind); duplicateValidation(index)}}><IonIcon icon={removeCircleIcon}></IonIcon></IonButton>: null}
                                      </IonItem>
                                  }) : null }

                                    <IonText color="danger" className={`${ isDuplicateValue[index] === false && isValuesEmpty[index] === false ? "ion-hide": "" }`}>
                                      <h5 style={{fontSize:12+'px', marginTop: 0+'px'}} className={`ion-margin-horizontal ${isDuplicateValue[index] ? "" : "ion-hide"}`}>Duplicate Values are not allowed.</h5>
                                      <h5 style={{fontSize:12+'px', marginTop: 0+'px'}} className={`ion-margin-horizontal ${isValuesEmpty[index] ? "" : "ion-hide"}`}>Variant values can't be empty.</h5>
                                    </IonText>
                                    </div>
                            </IonCol>
                          </IonRow>
                        </IonGrid>
                      </IonCard>
                        )
                      })}
                      <IonText color="danger" className={`${isVariantNameEmpty === false && isVariantValueEmpty === null ? "ion-hide": "" }`}>
                          <h5 style={{fontSize:12+'px', marginTop: 0+'px'}} className={`ion-margin-horizontal ${isVariantNameEmpty ? "" : "ion-hide"}`}>Variant name is required.</h5>
                          <h5 style={{fontSize:12+'px', marginTop: 0+'px'}} className={`ion-margin-horizontal ${isVariantValueEmpty ? "" : "ion-hide"}`}>Minimum one value is required of {optionName.length != 0 && optionName[optionName.length - 1] ? optionName[optionName.length - 1] : 'variant'}.</h5>
                      </IonText>
                      {numberOfOptions === 3 ? <IonButton disabled className="ion-margin-top" shape
                      ="round">Add another variant</IonButton> : <IonButton onClick={e=> addOptionHandler()} className="ion-margin-top" shape
                      ="round">Add another variant</IonButton>}
                      <br/><br/>
                      {optionList.length !== 0 ? <>
                      <table style={{width:100+'%'}}>
                        <thead>
                          <tr>
                            <th>Variant</th>
                            <th>Selling Price</th>
                            <th>MRP</th>
                            {/* <th>Available Stock</th> */}
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {optionList.map((val, index)=>{
                            return(<tr>
                              <td><IonText className="ion-margin-horizontal">{val}</IonText></td>
                              <td><IonItem className="ion-margin-horizontal"><IonInput value={sellingPriceList[val]} type='text' min="0"  onIonChange={e=>{sellingPriceList[val] = e.detail.value}} /></IonItem></td>
                              <td><IonItem className="ion-margin-horizontal"><IonInput min="0" value={mrpList[val]} type='text' onIonChange={e=>{mrpList[val] = e.detail.value}} /></IonItem></td>
                              {/* <td><IonItem className="ion-margin-horizontal"><IonInput value={stockList[val]} type='number' min="0"  onIonChange={e=>{stockList[val] = e.detail.value}} /></IonItem></td> */}
                              <td style={{color:'red'}} onClick={e=>{removeFromList(index);setRemovedOptionsList([...removedOptionsList, val])}}><IonButton shape="round" size='small' slot="end" color="danger">Remove</IonButton></td>
                            </tr>)
                          })}
                        </tbody>
                      </table>
                      <IonText color="danger" className={`${isMrpInTableValidate === true && isSellingPriceInTableEmpty === false && isSellingPriceInTableValidate === true && isMRPGreaterInTable === true ? "ion-hide": "" }`}>
                      {/* <h5 style={{fontSize:12+'px'}} className={`ion-margin-horizontal ${isMrpInTableEmpty ? "" : "ion-hide"}`}>MRP is required in each variant.</h5> */}
                      <h5 style={{fontSize:12+'px', marginTop: 0+'px'}} className={`ion-margin-horizontal ${isMrpInTableValidate ? "ion-hide" : ""}`}>MRP must contain only numbers and 2 digits after decimal in each variant.</h5>
                      <h5 style={{fontSize:12+'px', marginTop: 0+'px'}} className={`ion-margin-horizontal ${isSellingPriceInTableEmpty ? "" : "ion-hide"}`}>Selling price is required in each variant.</h5>
                      <h5 style={{fontSize:12+'px', marginTop: 0+'px'}} className={`ion-margin-horizontal ${isSellingPriceInTableValidate ? "ion-hide" : ""}`}>Selling price must contain only numbers and 2 digits after decimal in each variant.</h5>
                      {/* <h5 style={{fontSize:12+'px', marginTop: 0+'px'}} className={`ion-margin-horizontal ${isStockInTableEmpty ? "" : "ion-hide"}`}>Available stock is required in each variant.</h5>
                      <h5 style={{fontSize:12+'px', marginTop: 0+'px'}} className={`ion-margin-horizontal ${isStockInTableValidate ? "ion-hide" : ""}`}>Available stock must contain only numbers in each variant.</h5> */}
                      <h5 style={{fontSize:12+'px', marginTop: 0+'px'}} className={`ion-margin-horizontal ${isMRPGreaterInTable ? "ion-hide" : ""}`}>MRP must be greater than selling price in each variant.</h5>
                  </IonText>
                  </>
                      : null}
                    </> : "" }
                    <br/>
                <div className="ion-text-center">
                  <IonButton shape="round" onClick={e=> UpdateProductFunction()}>Save Changes</IonButton>
                </div>

                <IonLoading isOpen={loading === true || fetchCategoriesLoading === true || productByIdLoading === true || editProductLoading === true} message="Please Wait"/>
            </IonContent>
        </IonPage>
    )
}

export default UpdateProductMain
